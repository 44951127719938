import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useIsAdminAllow } from "./hooks";

const RolesRestrictions = ({
  isAllowResource,
  children,
  defaultComponent,
  redirectTo,
}) => {
  const checkAllow = useIsAdminAllow();
  const isAllow = checkAllow(isAllowResource);
  const location = useLocation();
  if (!isAllow && redirectTo) {
    return (
      <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
    );
  }
  if (!isAllow && defaultComponent) {
    return defaultComponent;
  }
  if (!isAllow) {
    return null;
  }
  return children;
};

export default RolesRestrictions;
