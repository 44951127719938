// --------------------------------------
// auto generator: 2024-06-15 15:50:35
// --------------------------------------

import {
  generateUrlQuery,
  ResponseServerGet,
  ResponseServerPost,
  ResponseServerPut,
  ResponseServerPatch,
  ResponseServerDelete,
  ResponseServerError,
  useServerGet,
  useServerPost,
  useServerPut,
  useServerPatch,
  useServerDelete,
} from "./serverHooks";


// urls ===========================================


/**
 * Api Admin ===========================================
 */

export const API_ADMIN_INDEX_URL = () => `/api/admin`;
export const API_ADMIN_SHOW_URL = (admin) => `/api/admin/${admin}`;
export const API_ADMIN_STORE_URL = () => `/api/admin`;
export const API_ADMIN_UPDATE_URL = (admin) => `/api/admin/${admin}`;
export const API_ADMIN_DESTROY_URL = (admin) => `/api/admin/${admin}`;
export const API_AUTH_STORE_URL = () => `/api/auth`;
export const API_AUTH_DESTROY_URL = (auth) => `/api/auth/${auth}`;
export const API_PROFILE_INDEX_URL = () => `/api/profile`;

/**
 * Api Content ===========================================
 */

export const API_CONTENT_BANNER_INDEX_URL = () => `/api/content_banner`;
export const API_CONTENT_BANNER_SHOW_URL = (content_banner) => `/api/content_banner/${content_banner}`;
export const API_CONTENT_BANNER_STORE_URL = () => `/api/content_banner`;
export const API_CONTENT_BANNER_UPDATE_URL = (content_banner) => `/api/content_banner/${content_banner}`;
export const API_CONTENT_BANNER_DESTROY_URL = (content_banner) => `/api/content_banner/${content_banner}`;
export const API_CONTENT_FILE_INDEX_URL = () => `/api/content_file`;
export const API_CONTENT_FILE_SHOW_URL = (content_file) => `/api/content_file/${content_file}`;
export const API_CONTENT_FILE_STORE_URL = () => `/api/content_file`;
export const API_CONTENT_FILE_UPDATE_URL = (content_file) => `/api/content_file/${content_file}`;
export const API_CONTENT_FILE_DESTROY_URL = (content_file) => `/api/content_file/${content_file}`;
export const API_CONTENT_IMAGE_INDEX_URL = () => `/api/content_image`;
export const API_CONTENT_IMAGE_SHOW_URL = (content_image) => `/api/content_image/${content_image}`;
export const API_CONTENT_IMAGE_STORE_URL = () => `/api/content_image`;
export const API_CONTENT_IMAGE_UPDATE_URL = (content_image) => `/api/content_image/${content_image}`;
export const API_CONTENT_IMAGE_DESTROY_URL = (content_image) => `/api/content_image/${content_image}`;
export const API_CONTENT_NEWS_INDEX_URL = () => `/api/content_news`;
export const API_CONTENT_NEWS_SHOW_URL = (content_news) => `/api/content_news/${content_news}`;
export const API_CONTENT_NEWS_STORE_URL = () => `/api/content_news`;
export const API_CONTENT_NEWS_UPDATE_URL = (content_news) => `/api/content_news/${content_news}`;
export const API_CONTENT_NEWS_DESTROY_URL = (content_news) => `/api/content_news/${content_news}`;
export const API_CONTENT_PAGE_INDEX_URL = () => `/api/content_page`;
export const API_CONTENT_PAGE_SHOW_URL = (content_page) => `/api/content_page/${content_page}`;
export const API_CONTENT_PAGE_STORE_URL = () => `/api/content_page`;
export const API_CONTENT_PAGE_UPDATE_URL = (content_page) => `/api/content_page/${content_page}`;
export const API_CONTENT_PAGE_DESTROY_URL = (content_page) => `/api/content_page/${content_page}`;

/**
 * Api Core ===========================================
 */

export const API_SECRET_URL = () => `/api/access-token/secret`;
export const API_BOOTSTRAP_INDEX_URL = () => `/api/bootstrap`;
export const API_CONSTANT_INDEX_URL = () => `/api/constant`;

/**
 * Api Nota ===========================================
 */

export const API_AUTHOR_INDEX_URL = () => `/api/nota/author`;
export const API_AUTHOR_SHOW_URL = (author) => `/api/nota/author/${author}`;
export const API_AUTHOR_STORE_URL = () => `/api/nota/author`;
export const API_AUTHOR_UPDATE_URL = (author) => `/api/nota/author/${author}`;
export const API_AUTHOR_DESTROY_URL = (author) => `/api/nota/author/${author}`;
export const API_CERTIFICATE_INDEX_URL = () => `/api/nota/certificate`;
export const API_CERTIFICATE_SHOW_URL = (certificate) => `/api/nota/certificate/${certificate}`;
export const API_CERTIFICATE_STORE_URL = () => `/api/nota/certificate`;
export const API_CERTIFICATE_UPDATE_URL = (certificate) => `/api/nota/certificate/${certificate}`;
export const API_CERTIFICATE_DESTROY_URL = (certificate) => `/api/nota/certificate/${certificate}`;
export const API_FEEDBACK_INDEX_URL = () => `/api/nota/feedback`;
export const API_FEEDBACK_SHOW_URL = (feedback) => `/api/nota/feedback/${feedback}`;
export const API_FEEDBACK_STORE_URL = () => `/api/nota/feedback`;
export const API_FEEDBACK_UPDATE_URL = (feedback) => `/api/nota/feedback/${feedback}`;
export const API_FEEDBACK_DESTROY_URL = (feedback) => `/api/nota/feedback/${feedback}`;
export const API_GAMEFILE_INDEX_URL = () => `/api/nota/gamefile`;
export const API_GAMEFILE_SHOW_URL = (gamefile) => `/api/nota/gamefile/${gamefile}`;
export const API_GAMEFILE_STORE_URL = () => `/api/nota/gamefile`;
export const API_GAMEFILE_UPDATE_URL = (gamefile) => `/api/nota/gamefile/${gamefile}`;
export const API_GAMEFILE_DESTROY_URL = (gamefile) => `/api/nota/gamefile/${gamefile}`;
export const API_ISSUE_INDEX_URL = () => `/api/nota/issue`;
export const API_ISSUE_SHOW_URL = (issue) => `/api/nota/issue/${issue}`;
export const API_ISSUE_STORE_URL = () => `/api/nota/issue`;
export const API_ISSUE_UPDATE_URL = (issue) => `/api/nota/issue/${issue}`;
export const API_ISSUE_DESTROY_URL = (issue) => `/api/nota/issue/${issue}`;
export const API_ROUND_INDEX_URL = () => `/api/nota/round`;
export const API_ROUND_SHOW_URL = (round) => `/api/nota/round/${round}`;
export const API_ROUND_STORE_URL = () => `/api/nota/round`;
export const API_ROUND_UPDATE_URL = (round) => `/api/nota/round/${round}`;
export const API_ROUND_DESTROY_URL = (round) => `/api/nota/round/${round}`;

/**
 * Api Report ===========================================
 */

export const API_REPORT_USER_INDEX_URL = () => `/api/report/user`;

/**
 * Api Role ===========================================
 */

export const API_ROLE_INDEX_URL = () => `/api/role`;
export const API_ROLE_SHOW_URL = (role) => `/api/role/${role}`;
export const API_ROLE_STORE_URL = () => `/api/role`;
export const API_ROLE_UPDATE_URL = (role) => `/api/role/${role}`;
export const API_ROLE_DESTROY_URL = (role) => `/api/role/${role}`;
export const API_ROLE_RESOURCE_INDEX_URL = () => `/api/role_resource`;

/**
 * Api Translate ===========================================
 */

export const API_DICTIONARY_SHOW_URL = (dictionary) => `/api/dictionary/${dictionary}`;
export const API_TRANSLATE_INDEX_URL = () => `/api/translate`;
export const API_TRANSLATE_SHOW_URL = (translate) => `/api/translate/${translate}`;
export const API_TRANSLATE_STORE_URL = () => `/api/translate`;
export const API_TRANSLATE_UPDATE_URL = (translate) => `/api/translate/${translate}`;
export const API_TRANSLATE_DESTROY_URL = (translate) => `/api/translate/${translate}`;
export const API_TRANSLATE_DOWNLOAD_URL = () => `/api/translate/download`;
export const API_TRANSLATE_UPLOAD_URL = () => `/api/translate/upload`;
export const API_TRANSLATE_GLOBAL_INDEX_URL = () => `/api/translate_global`;
export const API_TRANSLATE_GLOBAL_SHOW_URL = (translate_global) => `/api/translate_global/${translate_global}`;
export const API_TRANSLATE_GLOBAL_STORE_URL = () => `/api/translate_global`;
export const API_TRANSLATE_GLOBAL_UPDATE_URL = (translate_global) => `/api/translate_global/${translate_global}`;
export const API_TRANSLATE_GLOBAL_DESTROY_URL = (translate_global) => `/api/translate_global/${translate_global}`;
export const API_TRANSLATE_GLOBAL_DOWNLOAD_URL = () => `/api/translate_global/download`;
export const API_TRANSLATE_GLOBAL_UPLOAD_URL = () => `/api/translate_global/upload`;

/**
 * Api User ===========================================
 */

export const API_USER_INDEX_URL = () => `/api/user`;
export const API_USER_SHOW_URL = (user) => `/api/user/${user}`;
export const API_USER_UPDATE_URL = (user) => `/api/user/${user}`;
export const API_USER_SEARCH_URL = () => `/api/user/search`;

// types ===========================================


/**
 * Api Admin ===========================================
 */

export type ResponseApiAdminIndex = {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  settings?: { [key: string]: any /* use custom in generator */ };
  active?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}[]

export type ResponseApiAdminShow = {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  settings?: { [key: string]: any /* use custom in generator */ };
  active?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}

export type ResponseApiAdminStore = {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  settings?: { [key: string]: any /* use custom in generator */ };
  active?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}

export type ResponseApiAdminUpdate = {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  settings?: { [key: string]: any /* use custom in generator */ };
  active?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}

export type ResponseApiAdminDestroy = {}

export type ResponseApiAuthStore = {
  access_token?: {
    token?: string;
  };
  profile?: {
    id?: number;
    name?: string;
    email?: string;
    role_id?: number;
    settings?: { [key: string]: any /* use custom in generator */ };
    role?: {
      id?: number;
      name?: string;
      resources?: string[];
      created_at?: string;
      updated_at?: string;
    };
  };
}

export type ResponseApiAuthDestroy = {}

export type ResponseApiProfileIndex = {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  settings?: { [key: string]: any /* use custom in generator */ };
  role?: {
    id?: number;
    name?: string;
    resources?: string[];
    created_at?: string;
    updated_at?: string;
  };
}


/**
 * Api Content ===========================================
 */

export type ResponseApiContentBannerIndex = {
  id?: number;
  name?: string;
  btn_title?: { [key: string]: string };
  btn_target?: { [key: string]: string };
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  show_from?: undefined;
  show_to?: undefined;
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiContentBannerShow = {
  id?: number;
  name?: string;
  btn_title?: { [key: string]: string };
  btn_target?: { [key: string]: string };
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  show_from?: undefined;
  show_to?: undefined;
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentBannerStore = {
  id?: number;
  name?: string;
  btn_title?: { [key: string]: string };
  btn_target?: { [key: string]: string };
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  show_from?: undefined;
  show_to?: undefined;
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentBannerUpdate = {
  id?: number;
  name?: string;
  btn_title?: { [key: string]: string };
  btn_target?: { [key: string]: string };
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  show_from?: undefined;
  show_to?: undefined;
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentBannerDestroy = {}

export type ResponseApiContentFileIndex = {
  id?: number;
  name?: string;
  path?: string;
  title?: { [key: string]: string };
  namespace?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiContentFileShow = {
  id?: number;
  name?: string;
  path?: string;
  title?: { [key: string]: string };
  namespace?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentFileStore = {
  id?: number;
  name?: string;
  path?: string;
  title?: { [key: string]: string };
  namespace?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentFileUpdate = {
  id?: number;
  name?: string;
  path?: string;
  title?: { [key: string]: string };
  namespace?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentFileDestroy = {}

export type ResponseApiContentImageIndex = {
  id?: number;
  name?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  target?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiContentImageShow = {
  id?: number;
  name?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  target?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentImageStore = {
  id?: number;
  name?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  target?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentImageUpdate = {
  id?: number;
  name?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  target?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentImageDestroy = {}

export type ResponseApiContentNewsIndex = {
  id?: number;
  name?: string;
  slug?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  text_short?: { [key: string]: string };
  tags?: string[];
  show_from?: string;
  show_to?: undefined;
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiContentNewsShow = {
  id?: number;
  name?: string;
  slug?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  text_short?: { [key: string]: string };
  tags?: string[];
  show_from?: string;
  show_to?: undefined;
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentNewsStore = {
  id?: number;
  name?: string;
  slug?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  text_short?: { [key: string]: string };
  tags?: string[];
  show_from?: string;
  show_to?: undefined;
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentNewsUpdate = {
  id?: number;
  name?: string;
  slug?: string;
  image?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  text_short?: { [key: string]: string };
  tags?: string[];
  show_from?: string;
  show_to?: undefined;
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentNewsDestroy = {}

export type ResponseApiContentPageIndex = {
  id?: number;
  name?: string;
  slug?: string;
  menu_image?: { [key: string]: string };
  menu_title?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiContentPageShow = {
  id?: number;
  name?: string;
  slug?: string;
  menu_image?: { [key: string]: string };
  menu_title?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentPageStore = {
  id?: number;
  name?: string;
  slug?: string;
  menu_image?: { [key: string]: string };
  menu_title?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentPageUpdate = {
  id?: number;
  name?: string;
  slug?: string;
  menu_image?: { [key: string]: string };
  menu_title?: { [key: string]: string };
  title?: { [key: string]: string };
  text?: { [key: string]: string };
  seo_title?: { [key: string]: string };
  seo_description?: { [key: string]: string };
  seo_keywords?: { [key: string]: string };
  category_position?: { [key: string]: number /* use custom in generator */ };
  show_condition?: any /* use custom in generator */;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiContentPageDestroy = {}


/**
 * Api Core ===========================================
 */

export type ResponseApiSecret = {
  secret?: string;
}

export type ResponseApiBootstrapIndex = {
  api?: {
    languages?: string[];
    cdn?: string;
  };
  connect?: {
    languages?: string[];
    cdn?: string;
  };
}

export type ResponseApiConstantIndex = {
  country?: { [key: string]: string };
  currency?: { [key: string]: string };
  language?: { [key: string]: string };
}


/**
 * Api Nota ===========================================
 */

export type ResponseApiAuthorIndex = {
  id?: number;
  full_name?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiAuthorShow = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiAuthorStore = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiAuthorUpdate = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiAuthorDestroy = {}

export type ResponseApiCertificateIndex = {
  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  data?: {
    id?: number;
    code?: string;
    name?: string;
    time?: number;
    text?: string;
    active?: boolean;
    created_at?: string;
    updated_at?: string;
  }[];
}

export type ResponseApiCertificateShow = {
  id?: number;
  code?: string;
  name?: string;
  time?: number;
  text?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiCertificateStore = {
  id?: number;
  code?: string;
  name?: string;
  time?: number;
  text?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiCertificateUpdate = {
  id?: number;
  code?: string;
  name?: string;
  time?: number;
  text?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiCertificateDestroy = {}

export type ResponseApiFeedbackIndex = {
  id?: number;
  full_name?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiFeedbackShow = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  text?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiFeedbackStore = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  text?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiFeedbackUpdate = {
  id?: number;
  full_name?: string;
  appointment?: string;
  avatar?: string;
  text?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiFeedbackDestroy = {}

export type ResponseApiGamefileIndex = {
  id?: number;
  title?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiGamefileShow = {
  id?: number;
  title?: string;
  file?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiGamefileStore = {
  id?: number;
  title?: string;
  file?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiGamefileUpdate = {
  id?: number;
  title?: string;
  file?: string;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiGamefileDestroy = {}

export type ResponseApiIssueIndex = {
  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  data?: {
    id?: number;
    slug?: string;
    round_id?: number;
    title?: string;
    answer_title?: string;
    time?: number;
    position?: number;
    level?: number;
    flags?: string[];
    active?: boolean;
    created_at?: string;
    updated_at?: string;
  }[];
}

export type ResponseApiIssueShow = {
  id?: number;
  slug?: string;
  round_id?: number;
  title?: string;
  text?: string;
  images?: string[];
  videos?: string[];
  answer_title?: string;
  answer_text?: string;
  answer_images?: string[];
  answer_videos?: string[];
  time?: number;
  position?: number;
  level?: number;
  flags?: string[];
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiIssueStore = {
  id?: number;
  slug?: string;
  round_id?: number;
  title?: string;
  text?: string;
  images?: string[];
  videos?: string[];
  answer_title?: string;
  answer_text?: string;
  answer_images?: string[];
  answer_videos?: string[];
  time?: number;
  position?: number;
  level?: number;
  flags?: string[];
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiIssueUpdate = {
  id?: number;
  slug?: string;
  round_id?: number;
  title?: string;
  text?: string;
  images?: string[];
  videos?: string[];
  answer_title?: string;
  answer_text?: string;
  answer_images?: string[];
  answer_videos?: string[];
  time?: number;
  position?: number;
  level?: number;
  flags?: string[];
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiIssueDestroy = {}

export type ResponseApiRoundIndex = {
  id?: number;
  slug?: string;
  title?: string;
  count?: number;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiRoundShow = {
  id?: number;
  slug?: string;
  title?: string;
  text?: string;
  image?: string;
  count?: number;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoundStore = {
  id?: number;
  slug?: string;
  title?: string;
  text?: string;
  image?: string;
  count?: number;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoundUpdate = {
  id?: number;
  slug?: string;
  title?: string;
  text?: string;
  image?: string;
  count?: number;
  position?: number;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoundDestroy = {}


/**
 * Api Report ===========================================
 */

export type ResponseApiReportUserIndex = {
  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  data?: {
    id?: number;
    email?: string;
    password?: string;
    phone?: string;
    full_name?: string;
    country?: string;
    city?: string;
    organization?: string;
    appointment?: string;
    settings?: { [key: string]: any };
    created_at?: string;
    updated_at?: string;
    deleted_at?: undefined;
  }[];
}


/**
 * Api Role ===========================================
 */

export type ResponseApiRoleIndex = {
  id?: number;
  name?: string;
  resources?: string[];
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiRoleShow = {
  id?: number;
  name?: string;
  resources?: string[];
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoleStore = {
  id?: number;
  name?: string;
  resources?: string[];
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoleUpdate = {
  id?: number;
  name?: string;
  resources?: string[];
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiRoleDestroy = {}

export type ResponseApiRoleResourceIndex = string[]


/**
 * Api Translate ===========================================
 */

export type ResponseApiDictionaryShow = {
  languages?: string[];
  current?: string;
  dictionary?: { [key: string]: any /* use custom in generator */ };
}

export type ResponseApiTranslateIndex = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiTranslateShow = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateStore = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateUpdate = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateDestroy = {}

export type ResponseApiTranslateDownload = any /* ignore in generator: file [download] */

export type ResponseApiTranslateUpload = {
  create?: string[];
  update?: string[];
  delete?: string[];
}

export type ResponseApiTranslateGlobalIndex = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}[]

export type ResponseApiTranslateGlobalShow = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateGlobalStore = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateGlobalUpdate = {
  id?: number;
  key?: string;
  translate?: { [key: string]: string };
  created_at?: string;
  updated_at?: string;
}

export type ResponseApiTranslateGlobalDestroy = {}

export type ResponseApiTranslateGlobalDownload = any /* ignore in generator: file [download] */

export type ResponseApiTranslateGlobalUpload = {
  create?: string[];
  update?: string[];
  delete?: string[];
}


/**
 * Api User ===========================================
 */

export type ResponseApiUserIndex = {
  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  data?: {
    id?: number;
    email?: string;
    phone?: string;
    full_name?: string;
    country?: string;
    city?: string;
    organization?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: undefined;
  }[];
}

export type ResponseApiUserShow = {
  id?: number;
  email?: string;
  phone?: string;
  full_name?: string;
  country?: string;
  city?: string;
  organization?: string;
  appointment?: string;
  settings?: { [key: string]: any };
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}

export type ResponseApiUserUpdate = {
  id?: number;
  email?: string;
  phone?: string;
  full_name?: string;
  country?: string;
  city?: string;
  organization?: string;
  appointment?: string;
  settings?: { [key: string]: any };
  created_at?: string;
  updated_at?: string;
  deleted_at?: undefined;
}

export type ResponseApiUserSearch = {
  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  data?: {
    id?: number;
    email?: string;
    phone?: string;
    full_name?: string;
    country?: string;
    city?: string;
    organization?: string;
    created_at?: undefined;
    updated_at?: undefined;
    deleted_at?: undefined;
  }[];
}


// hooks ===========================================


/**
 * Api Admin ===========================================
 */

export const useApiAdminIndex = ( options: any = {}) : ResponseServerGet<ResponseApiAdminIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ADMIN_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAdminShow = (admin, options: any = {}) : ResponseServerGet<ResponseApiAdminShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ADMIN_SHOW_URL(admin) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAdminStore = ( options: any = {}) : ResponseServerPost<ResponseApiAdminStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_ADMIN_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAdminUpdate = (admin, options: any = {}) : ResponseServerPut<ResponseApiAdminUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_ADMIN_UPDATE_URL(admin) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAdminDestroy = (admin, options: any = {}) : ResponseServerDelete<ResponseApiAdminDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_ADMIN_DESTROY_URL(admin) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthStore = ( options: any = {}) : ResponseServerPost<ResponseApiAuthStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_AUTH_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthDestroy = (auth, options: any = {}) : ResponseServerDelete<ResponseApiAuthDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_AUTH_DESTROY_URL(auth) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiProfileIndex = ( options: any = {}) : ResponseServerGet<ResponseApiProfileIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_PROFILE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Content ===========================================
 */

export const useApiContentBannerIndex = ( options: any = {}) : ResponseServerGet<ResponseApiContentBannerIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_BANNER_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentBannerShow = (content_banner, options: any = {}) : ResponseServerGet<ResponseApiContentBannerShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_BANNER_SHOW_URL(content_banner) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentBannerStore = ( options: any = {}) : ResponseServerPost<ResponseApiContentBannerStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CONTENT_BANNER_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentBannerUpdate = (content_banner, options: any = {}) : ResponseServerPut<ResponseApiContentBannerUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CONTENT_BANNER_UPDATE_URL(content_banner) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentBannerDestroy = (content_banner, options: any = {}) : ResponseServerDelete<ResponseApiContentBannerDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CONTENT_BANNER_DESTROY_URL(content_banner) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentFileIndex = ( options: any = {}) : ResponseServerGet<ResponseApiContentFileIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_FILE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentFileShow = (content_file, options: any = {}) : ResponseServerGet<ResponseApiContentFileShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_FILE_SHOW_URL(content_file) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentFileStore = ( options: any = {}) : ResponseServerPost<ResponseApiContentFileStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CONTENT_FILE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentFileUpdate = (content_file, options: any = {}) : ResponseServerPut<ResponseApiContentFileUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CONTENT_FILE_UPDATE_URL(content_file) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentFileDestroy = (content_file, options: any = {}) : ResponseServerDelete<ResponseApiContentFileDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CONTENT_FILE_DESTROY_URL(content_file) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentImageIndex = ( options: any = {}) : ResponseServerGet<ResponseApiContentImageIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_IMAGE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentImageShow = (content_image, options: any = {}) : ResponseServerGet<ResponseApiContentImageShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_IMAGE_SHOW_URL(content_image) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentImageStore = ( options: any = {}) : ResponseServerPost<ResponseApiContentImageStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CONTENT_IMAGE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentImageUpdate = (content_image, options: any = {}) : ResponseServerPut<ResponseApiContentImageUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CONTENT_IMAGE_UPDATE_URL(content_image) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentImageDestroy = (content_image, options: any = {}) : ResponseServerDelete<ResponseApiContentImageDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CONTENT_IMAGE_DESTROY_URL(content_image) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentNewsIndex = ( options: any = {}) : ResponseServerGet<ResponseApiContentNewsIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_NEWS_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentNewsShow = (content_news, options: any = {}) : ResponseServerGet<ResponseApiContentNewsShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_NEWS_SHOW_URL(content_news) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentNewsStore = ( options: any = {}) : ResponseServerPost<ResponseApiContentNewsStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CONTENT_NEWS_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentNewsUpdate = (content_news, options: any = {}) : ResponseServerPut<ResponseApiContentNewsUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CONTENT_NEWS_UPDATE_URL(content_news) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentNewsDestroy = (content_news, options: any = {}) : ResponseServerDelete<ResponseApiContentNewsDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CONTENT_NEWS_DESTROY_URL(content_news) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentPageIndex = ( options: any = {}) : ResponseServerGet<ResponseApiContentPageIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_PAGE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentPageShow = (content_page, options: any = {}) : ResponseServerGet<ResponseApiContentPageShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONTENT_PAGE_SHOW_URL(content_page) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentPageStore = ( options: any = {}) : ResponseServerPost<ResponseApiContentPageStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CONTENT_PAGE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentPageUpdate = (content_page, options: any = {}) : ResponseServerPut<ResponseApiContentPageUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CONTENT_PAGE_UPDATE_URL(content_page) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiContentPageDestroy = (content_page, options: any = {}) : ResponseServerDelete<ResponseApiContentPageDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CONTENT_PAGE_DESTROY_URL(content_page) + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Core ===========================================
 */

export const useApiSecret = ( options: any = {}) : ResponseServerPost<ResponseApiSecret, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_SECRET_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiBootstrapIndex = ( options: any = {}) : ResponseServerGet<ResponseApiBootstrapIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_BOOTSTRAP_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiConstantIndex = ( options: any = {}) : ResponseServerGet<ResponseApiConstantIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CONSTANT_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Nota ===========================================
 */

export const useApiAuthorIndex = ( options: any = {}) : ResponseServerGet<ResponseApiAuthorIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_AUTHOR_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthorShow = (author, options: any = {}) : ResponseServerGet<ResponseApiAuthorShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_AUTHOR_SHOW_URL(author) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthorStore = ( options: any = {}) : ResponseServerPost<ResponseApiAuthorStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_AUTHOR_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthorUpdate = (author, options: any = {}) : ResponseServerPut<ResponseApiAuthorUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_AUTHOR_UPDATE_URL(author) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiAuthorDestroy = (author, options: any = {}) : ResponseServerDelete<ResponseApiAuthorDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_AUTHOR_DESTROY_URL(author) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiCertificateIndex = ( options: any = {}) : ResponseServerGet<ResponseApiCertificateIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CERTIFICATE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiCertificateShow = (certificate, options: any = {}) : ResponseServerGet<ResponseApiCertificateShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_CERTIFICATE_SHOW_URL(certificate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiCertificateStore = ( options: any = {}) : ResponseServerPost<ResponseApiCertificateStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_CERTIFICATE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiCertificateUpdate = (certificate, options: any = {}) : ResponseServerPut<ResponseApiCertificateUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_CERTIFICATE_UPDATE_URL(certificate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiCertificateDestroy = (certificate, options: any = {}) : ResponseServerDelete<ResponseApiCertificateDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_CERTIFICATE_DESTROY_URL(certificate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiFeedbackIndex = ( options: any = {}) : ResponseServerGet<ResponseApiFeedbackIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_FEEDBACK_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiFeedbackShow = (feedback, options: any = {}) : ResponseServerGet<ResponseApiFeedbackShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_FEEDBACK_SHOW_URL(feedback) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiFeedbackStore = ( options: any = {}) : ResponseServerPost<ResponseApiFeedbackStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_FEEDBACK_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiFeedbackUpdate = (feedback, options: any = {}) : ResponseServerPut<ResponseApiFeedbackUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_FEEDBACK_UPDATE_URL(feedback) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiFeedbackDestroy = (feedback, options: any = {}) : ResponseServerDelete<ResponseApiFeedbackDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_FEEDBACK_DESTROY_URL(feedback) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiGamefileIndex = ( options: any = {}) : ResponseServerGet<ResponseApiGamefileIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_GAMEFILE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiGamefileShow = (gamefile, options: any = {}) : ResponseServerGet<ResponseApiGamefileShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_GAMEFILE_SHOW_URL(gamefile) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiGamefileStore = ( options: any = {}) : ResponseServerPost<ResponseApiGamefileStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_GAMEFILE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiGamefileUpdate = (gamefile, options: any = {}) : ResponseServerPut<ResponseApiGamefileUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_GAMEFILE_UPDATE_URL(gamefile) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiGamefileDestroy = (gamefile, options: any = {}) : ResponseServerDelete<ResponseApiGamefileDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_GAMEFILE_DESTROY_URL(gamefile) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiIssueIndex = ( options: any = {}) : ResponseServerGet<ResponseApiIssueIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ISSUE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiIssueShow = (issue, options: any = {}) : ResponseServerGet<ResponseApiIssueShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ISSUE_SHOW_URL(issue) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiIssueStore = ( options: any = {}) : ResponseServerPost<ResponseApiIssueStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_ISSUE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiIssueUpdate = (issue, options: any = {}) : ResponseServerPut<ResponseApiIssueUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_ISSUE_UPDATE_URL(issue) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiIssueDestroy = (issue, options: any = {}) : ResponseServerDelete<ResponseApiIssueDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_ISSUE_DESTROY_URL(issue) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoundIndex = ( options: any = {}) : ResponseServerGet<ResponseApiRoundIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ROUND_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoundShow = (round, options: any = {}) : ResponseServerGet<ResponseApiRoundShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ROUND_SHOW_URL(round) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoundStore = ( options: any = {}) : ResponseServerPost<ResponseApiRoundStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_ROUND_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoundUpdate = (round, options: any = {}) : ResponseServerPut<ResponseApiRoundUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_ROUND_UPDATE_URL(round) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoundDestroy = (round, options: any = {}) : ResponseServerDelete<ResponseApiRoundDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_ROUND_DESTROY_URL(round) + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Report ===========================================
 */

export const useApiReportUserIndex = ( options: any = {}) : ResponseServerGet<ResponseApiReportUserIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_REPORT_USER_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Role ===========================================
 */

export const useApiRoleIndex = ( options: any = {}) : ResponseServerGet<ResponseApiRoleIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ROLE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoleShow = (role, options: any = {}) : ResponseServerGet<ResponseApiRoleShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ROLE_SHOW_URL(role) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoleStore = ( options: any = {}) : ResponseServerPost<ResponseApiRoleStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_ROLE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoleUpdate = (role, options: any = {}) : ResponseServerPut<ResponseApiRoleUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_ROLE_UPDATE_URL(role) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoleDestroy = (role, options: any = {}) : ResponseServerDelete<ResponseApiRoleDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_ROLE_DESTROY_URL(role) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiRoleResourceIndex = ( options: any = {}) : ResponseServerGet<ResponseApiRoleResourceIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_ROLE_RESOURCE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api Translate ===========================================
 */

export const useApiDictionaryShow = (dictionary, options: any = {}) : ResponseServerGet<ResponseApiDictionaryShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_DICTIONARY_SHOW_URL(dictionary) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateIndex = ( options: any = {}) : ResponseServerGet<ResponseApiTranslateIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateShow = (translate, options: any = {}) : ResponseServerGet<ResponseApiTranslateShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_SHOW_URL(translate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateStore = ( options: any = {}) : ResponseServerPost<ResponseApiTranslateStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_TRANSLATE_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateUpdate = (translate, options: any = {}) : ResponseServerPut<ResponseApiTranslateUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_TRANSLATE_UPDATE_URL(translate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateDestroy = (translate, options: any = {}) : ResponseServerDelete<ResponseApiTranslateDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_TRANSLATE_DESTROY_URL(translate) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateDownload = ( options: any = {}) : ResponseServerGet<ResponseApiTranslateDownload, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_DOWNLOAD_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateUpload = ( options: any = {}) : ResponseServerPost<ResponseApiTranslateUpload, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_TRANSLATE_UPLOAD_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalIndex = ( options: any = {}) : ResponseServerGet<ResponseApiTranslateGlobalIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_GLOBAL_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalShow = (translate_global, options: any = {}) : ResponseServerGet<ResponseApiTranslateGlobalShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_GLOBAL_SHOW_URL(translate_global) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalStore = ( options: any = {}) : ResponseServerPost<ResponseApiTranslateGlobalStore, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_TRANSLATE_GLOBAL_STORE_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalUpdate = (translate_global, options: any = {}) : ResponseServerPut<ResponseApiTranslateGlobalUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_TRANSLATE_GLOBAL_UPDATE_URL(translate_global) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalDestroy = (translate_global, options: any = {}) : ResponseServerDelete<ResponseApiTranslateGlobalDestroy, ResponseServerError> => {
  return useServerDelete({
    options: options,
    method: "DELETE",
    url: API_TRANSLATE_GLOBAL_DESTROY_URL(translate_global) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalDownload = ( options: any = {}) : ResponseServerGet<ResponseApiTranslateGlobalDownload, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_TRANSLATE_GLOBAL_DOWNLOAD_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiTranslateGlobalUpload = ( options: any = {}) : ResponseServerPost<ResponseApiTranslateGlobalUpload, ResponseServerError> => {
  return useServerPost({
    options: options,
    method: "POST",
    url: API_TRANSLATE_GLOBAL_UPLOAD_URL() + generateUrlQuery(options["query"] || {}),
  });
};


/**
 * Api User ===========================================
 */

export const useApiUserIndex = ( options: any = {}) : ResponseServerGet<ResponseApiUserIndex, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_USER_INDEX_URL() + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiUserShow = (user, options: any = {}) : ResponseServerGet<ResponseApiUserShow, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_USER_SHOW_URL(user) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiUserUpdate = (user, options: any = {}) : ResponseServerPut<ResponseApiUserUpdate, ResponseServerError> => {
  return useServerPut({
    options: options,
    method: "PUT",
    url: API_USER_UPDATE_URL(user) + generateUrlQuery(options["query"] || {}),
  });
};

export const useApiUserSearch = ( options: any = {}) : ResponseServerGet<ResponseApiUserSearch, ResponseServerError> => {
  return useServerGet({
    options: options,
    method: "GET",
    url: API_USER_SEARCH_URL() + generateUrlQuery(options["query"] || {}),
  });
};
