import React from "react";
import TextEditorContent from "./TextEditorContent";
import { defaultConfig, extendedConfig } from "./constants";

interface TextEditorProps {
  value: any;
  onChange: (val) => void;
  label?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ value, onChange, label }) => {
  const handleEditorChange = (val) => onChange && onChange(val);

  /*  const infoEditModal = (): modalType => ({
    wrapClassName: "info-modal text-editor-modal",
    containerClass: "info-modal__container",
    customRender: InfoModal,
    width: 900,
    componentProps: {
      modalHeader: label,
      onCancel: handleOnClose,
      onExpand: handleOnClose,
    },
  });*/

  /*  const infoModalHandler = useShowModal(infoEditModal());*/

  /*
  const openInfoModal = (preparedText) => {
    infoModalHandler({
      customRender: (
        <TextEditorContent
          value={preparedText}
          onBlur={handleEditorChange}
          config={defaultConfig}
        />
      ),
    });
  };
*/

  /*  extendedConfig.extraButtons = [
    {
      name: "expand",
      tooltip: "Expand",
      exec: (editor) => {},
      template: () => "",
    },
  ];*/

  return (
    <div className="text-editor-container">
      <TextEditorContent
        value={value}
        config={extendedConfig}
        onChange={handleEditorChange}
      />
    </div>
  );
};

export default TextEditor;
