import React, { memo, useEffect, useState } from "react";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import MainApp from "./MainApp";
import SignIn from "containers/SignIn";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "constants/ThemeSetting";
import { useCurrentAdmin } from "fragmentedStore";
import {
  useApiBootstrapIndex,
  useApiDictionaryShow,
  useApiTagIndex,
} from "server/apiGeneratorHooks";
import { useAuth } from "containers/Sidebar/UserProfile";
import { TransProvider } from "utils/translation/TransProvider";
import { DrawerContextProvider } from "services/drawer/DrawerContext";
import { ModalContextProvider } from "services/modal/ModalContext";
import {
  useThemeColor,
  useThemeType,
  useNavStyle,
  useLayoutType,
  useIsDirectionRTL,
  useLocale,
} from "fragmentedStore";
import RestrictedRoute from "utils/helpers/RestrictedRoute";

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

let styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

const App = () => {
  const [themeColor] = useThemeColor();
  const [themeType, setThemeType] = useThemeType();
  const [navStyle, setNavStyle] = useNavStyle();
  const [layoutType, onLayoutTypeChange] = useLayoutType();
  const [isDirectionRTL] = useIsDirectionRTL();
  const [locale] = useLocale();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    // if (initURL === '') {
    //   // dispatch(setInitUrl(location.pathname));
    // }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      setNavStyle(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      onLayoutTypeChange(params.get("layout-type"));
    }
  }, [location.search, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = locale.locale;

  // touch ====================

  const routeMain = "/dashboard";
  const routeLogin = "/signin";
  const [routeCurrent, setRouteCurrent] = useState(location.pathname || "/");
  const [currentAdmin, setCurrentAdmin] = useCurrentAdmin();

  useEffect(() => {
    let goto = routeCurrent;
    if (currentAdmin === null) {
      /*     goto = routeLogin;*/
    } else if (routeCurrent === "/" || routeCurrent === routeLogin) {
      goto = routeMain;
    }

    console.log(
      "redirection switch",
      !!currentAdmin,
      goto,
      "expect",
      routeCurrent
    );

    history.push(goto);
  }, [currentAdmin]);

  const { profile } = useAuth();
  useEffect(() => {
    profile(location.pathname);
  }, [currentAdmin]);

  const { data, isLoading } = useApiDictionaryShow(currentAppLocale, {
    staleTime: 60 * 60 * 24,
  });

  return (
    <ConfigProvider
      locale={currentAppLocale}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <TransProvider
        locale={currentAppLocale}
        languages={data?.languages}
        dictionary={data?.dictionary}
      >
        <DrawerContextProvider>
          <ModalContextProvider>
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              <RestrictedRoute
                path={`${match.url}`}
                currentAdmin={currentAdmin}
                location={location}
                component={MainApp}
              />
            </Switch>
          </ModalContextProvider>
        </DrawerContextProvider>
      </TransProvider>
    </ConfigProvider>
  );
};

export default memo(App);
