import React from "react";
import { Route, Switch } from "react-router-dom";
import RolesRestrictions from "services/rolesRestrictions";
import { routesConfig } from "routes/config";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {Object.values(routesConfig).map((route, i) => {
          const { component: Component, ...rest } = route;
          return (
            <Route
              key={i}
              {...rest}
              render={(props) => (
                <RolesRestrictions isAllowResource={route.isAllowResource}>
                  <Component {...props} />
                </RolesRestrictions>
              )}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default App;
