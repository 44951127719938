import React from "react";
import { Col } from "antd";

const ColWrap = ({ isCol, children, ...rest }: any) => {
  if (!isCol) {
    return children;
  }
  return <Col {...rest}>{children}</Col>;
};

export default ColWrap;
