import React, { createContext } from "react";
import { TransContextProps } from "utils/translation/types";

export const TransContext = createContext<TransContextProps | null>(null);

export const TransProvider: React.FC<TransContextProps> = ({
  locale,
  dictionary,
  languages,
  children,
}) => {
  return (
    <TransContext.Provider value={{ locale, languages, dictionary }}>
      {children}
    </TransContext.Provider>
  );
};
