import React from "react";
import { DatePicker } from "antd";

const DateRange = (props) => {
  const { value, onChange, ...rest } = props;
  const onChangeDatePicker = (dates, dateStrings, info) => {
    props.onChange(dateStrings);
  };
  return <DatePicker {...rest} onChange={onChangeDatePicker} />;
};

export default DateRange;
