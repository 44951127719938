import React from "react";
import JoditEditor from "jodit-react";

interface TextEditorContentProps {
  value: any;
  onChange?: (val) => void;
  onBlur?: (val) => void;
  config?: any;
}

const TextEditorContent: React.FC<TextEditorContentProps> = ({
  value,
  onChange,
  onBlur,
  config,
}) => {
  return (
    <JoditEditor
      value={value}
      config={config}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default TextEditorContent;
