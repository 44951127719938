import React, { useRef } from "react";
import Drawer from "./index";

const Context: any = React.createContext(null);

const DrawerContextProvider = ({ children }: any) => {
  const childRef: any = useRef(null);
  const showDrawer = (params = {}, name: string = "default") => {
    childRef.current.drawerOpen(name, params);
  };
  const updateDrawer = (params = {}, name: string) => {
    childRef.current.drawerUpdate(name, params);
  };
  const hideDrawer = (name: string) => {
    childRef.current.drawerClose(name);
  };

  return (
    <Context.Provider value={{ showDrawer, hideDrawer, updateDrawer }}>
      {children}
      <Drawer ref={childRef} hideDrawer={hideDrawer} />
    </Context.Provider>
  );
};

export default Context;
export { DrawerContextProvider };
