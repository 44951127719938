import React from "react";
import { Space } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const Notification: React.FC<{ text: string; type?: string }> = ({
  text,
  type = "info",
}) => {
  return (
    <Space align={"center"}>
      <InfoCircleOutlined style={{ fontSize: "24px", color: "#fa8c16" }} />
      <span>{text}</span>
    </Space>
  );
};

export default Notification;
