import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "assets/vendors/style";
import App from "./containers/App/index";
import { FragmentedStoreProvider } from "./fragmentedStore";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 0,
    },
    mutations: {
      // mutation options
    },
  },
});

const NextApp = () => (
  <FragmentedStoreProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </FragmentedStoreProvider>
);

export default NextApp;
