export const deviceTypes = ["desktop", "mobile"];
export const defaultPage = 1;
export const defaultPageSize = 50;
export const pageSizeOptions = ["20", "50", "100", "200", "500", "1000"];
export const localeData = {
  en: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  ua: {
    languageId: "ukrainian",
    locale: "ua",
    name: "Ukrainian",
    icon: "ua",
  },
};
