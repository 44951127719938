import React from "react";
import { Table as AntTable } from "antd";
import { TableComponentProps } from "components/organisms/table/types";
import { LoadingOutlined } from "@ant-design/icons";

const Table: React.FC<TableComponentProps> = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

  return (
    <AntTable
      {...props}
      loading={{ spinning: props.loading, indicator: antIcon }}
    />
  );
};

export default Table;
