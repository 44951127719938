import React from "react";
import {
  useApiTranslateIndex,
  useApiTranslateShow,
  useApiTranslateStore,
  useApiTranslateUpdate,
  useApiTranslateDestroy,
  useApiTranslateUpload,
  API_TRANSLATE_DOWNLOAD_URL,
  API_TRANSLATE_INDEX_URL,
} from "server/apiGeneratorHooks";
import {
  useBootstrap,
  useClientTableFilter,
  useCommonComponentCrud,
  useTableQuery,
} from "utils/helpers/hooks";
import {useGlobalTranslate} from "utils/translation/hooks";
import {
  CommonStructureDestroy,
  CommonStructureIndex, CommonStructureModalUpset, CommonStructureUpset,
} from "components/atoms/common";
import StructureTable from "components/atoms/structure/StructureTable";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import StructureForm from "components/atoms/structure/StructureForm";
import {useIsAdminAllow} from "services/rolesRestrictions/hooks";


/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({onShow, onEdit, onDelete}) => {
  const {_t} = useGlobalTranslate();
  const structureTableFilter = new StructureTableFilter({_t: _t});
  const structureTable = new StructureTable({_t: _t});

  structureTableFilter
    .key()
    .translate(['en', 'ua'])
    .search_by_data()
    .btns_submit_reset()
  ;

  structureTable
    .id()
    .key()
    .translate(['en', 'ua'])
    .created_at()
    .action({onShow, onEdit, onDelete})
  ;

  return {structureTableFilter, structureTable};
}

/**
 *
 * @param item
 */
const useForm = ({item = null}) => {
  const {_t} = useGlobalTranslate();
  const structureForm = new StructureForm({_t: _t});

  structureForm
    .key()
    .translate(['en', 'ua'])
    .btns_submit_reset()
  ;

  return structureForm;
}


/**
 *
 * @param drawer
 * @constructor
 */
const Store = ({drawer}) => {
  const structureForm = useForm({});
  const apiStore = useApiTranslateStore();

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiStore}
      structureForm={structureForm}
      data={{}}
      loading={apiStore.isLoading}
    />
  );
};

/**
 *
 * @param drawer
 * @param item
 * @constructor
 */
const Update = ({drawer, item}) => {
  const structureForm = useForm({item});
  const apiUpdate = useApiTranslateUpdate(item.id);
  const apiShow = useApiTranslateShow(item.id, {enabled: apiUpdate.isIdle});

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiUpdate}
      structureForm={structureForm}
      data={apiShow.data}
      loading={apiShow.isFetching || apiUpdate.isLoading}
    />
  );
};

/**
 *
 * @param modal
 * @param item
 * @constructor
 */
const Destroy = ({modal, item}) => {
  const apiDestroy = useApiTranslateDestroy(item.id);

  return (
    <CommonStructureDestroy
      modal={modal}
      mutate={apiDestroy}
      structure={{}}
      data={item}
      loading={apiDestroy.isLoading}
    />
  );
};

/**
 *
 * @param modal
 * @constructor
 */
const Upload = ({modal}) => {
  const {_t} = useGlobalTranslate();
  const structureForm = new StructureForm({_t: _t});

  structureForm
    .transfer_on_import({create: true, update: true, delete: true})
    .file()
    .btns_submit_reset();

  const apiUpload = useApiTranslateUpload({
    invalidateSet: [[API_TRANSLATE_INDEX_URL()]],
  });

  return (
    <CommonStructureModalUpset
      modal={modal}
      mutate={apiUpload}
      structureForm={structureForm}
      data={{}}
      loading={apiUpload.isLoading}
    />
  );
};


/**
 *
 * @constructor
 */
const CommonTranslate = () => {
  const {_t} = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = 'translate:translate_global';

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    componentStore: isAllow(prefix + '@store') && Store,
    componentUpdate: isAllow(prefix + '@update') && Update,
    componentDestroy: isAllow(prefix + '@destroy') && Destroy,
    componentUpload: isAllow(prefix + '@upload') && Upload,
    componentDownload: isAllow(prefix + '@download') && {
      target: API_TRANSLATE_DOWNLOAD_URL(),
      query: tableQuery.query
    },
  });

  const apiIndex = useApiTranslateIndex();
  const filteredData = useClientTableFilter(apiIndex.data, tableQuery.query);
  const {structureTableFilter, structureTable} = useTable(
    {onShow: null, onEdit: crud.onEdit, onDelete: crud.onDelete},
  );

  return (
    <CommonStructureIndex
      title={_t("menu.translate")}
      source={{data: filteredData || []}}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={crud.onUpload}
      onDownload={crud.onDownload}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default CommonTranslate;
