import React, { useMemo } from "react";
import { LocaleSwitchProps } from "components/molecules/localeSwitch/types";
import LocaleSwitch from "components/molecules/localeSwitch";
import { useApiDictionaryShow } from "server/apiGeneratorHooks";
import { localeData } from "utils/helpers/constants";
import { useLocale } from "fragmentedStore";

const LocaleSwitchContainer: React.FC<LocaleSwitchProps> = () => {
  const [selectedLocale, setSwitchLocale] = useLocale();
  const { data } = useApiDictionaryShow(selectedLocale.locale, {
    staleTime: 60 * 60 * 24,
  });
  const onSwitchLocale = (locale) => {
    localStorage.setItem("locale", locale?.locale);
    setSwitchLocale(locale);
  };

  const switchLocaleData = useMemo(() => {
    return data?.languages?.map((item) => {
      return {
        locale: item,
        icon: item,
        ...localeData?.[item],
      };
    });
  }, [data, selectedLocale]);

  return (
    <LocaleSwitch
      selectedLocale={selectedLocale}
      localeData={switchLocaleData}
      switchLocale={onSwitchLocale}
    />
  );
};

export default LocaleSwitchContainer;
