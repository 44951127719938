import { useGlobalTranslate } from "utils/translation/hooks";
import React, { useEffect, useMemo, useRef } from "react";
import Notification from "components/molecules/notification";
import {
  defaultPage,
  defaultPageSize,
  deviceTypes,
  pageSizeOptions,
} from "utils/helpers/constants";
import {
  useGenerateColumns,
  useGenerateSchema,
} from "services/customForm/hooks";
import CustomForm from "services/customForm";
import { Button, Card } from "antd";
import ContainerHeader from "components/ContainerHeader";
import { useApiSecret } from "server/apiGeneratorHooks";
import { generateUrlQuery } from "server/serverHooks";
import backendUrl from "server/apiUrl";
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Spinner from "components/atoms/spinner";
import Table from "components/organisms/table";
import CustomStructureForm from "services/customForm/customStructureForm";
import StructureTable from "../structure/StructureTable";

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  fieldCol: { span: 24 },
};

/**
 *
 * @param apiIndex
 * @param onCreate
 * @param tableQuery
 * @param configTable
 * @param configTableFilter
 * @param title
 * @constructor
 */
export const CommonIndex = ({
  source,
  onCreate = null,
  onUpload = null,
  onDownload = null,
  options = null,
  tableQuery = null,
  configTable = null,
  configTableFilter = null,
  title = null,
}) => {
  const { _t } = useGlobalTranslate();
  const locale = options?.locale || [];
  const schemaTableFilter = useGenerateSchema(configTableFilter, { locale });
  const schemaTable = useGenerateColumns(configTable, { locale });

  const onSearch = (data) => {
    tableQuery &&
      tableQuery.change.setPagination({
        pageSize: defaultPageSize,
        current: defaultPage,
      });
    tableQuery && tableQuery.filter.setFilters(data);
  };

  const hasPagination = !!source.data?.per_page;
  // const showPagination = !! (hasPagination && source.data?.total_pages && source.data?.total_pages > 1);

  return (
    <Card
      size={"small"}
      title={title && <ContainerHeader title={title} />}
      extra={
        <div style={{ padding: 0 }}>
          {onUpload && (
            <Button
              onClick={onUpload}
              icon={<CloudUploadOutlined />}
              style={{ marginBottom: 0 }}
            />
          )}
          {onDownload && (
            <Button
              onClick={onDownload}
              icon={<CloudDownloadOutlined />}
              style={{ marginBottom: 0 }}
            />
          )}
          {onCreate && (
            <Button onClick={onCreate} style={{ marginBottom: 0 }}>
              {_t("create")}
            </Button>
          )}
        </div>
      }
    >
      {tableQuery.filter && (
        <CustomForm
          buttonsProps={{
            submitTitle: _t("search"),
            resetTitle: _t("reset"),
            reset: true,
            submit: true,
          }}
          onSubmit={onSearch}
          schema={schemaTableFilter}
        />
      )}

      <Table
        className="gx-table-responsive"
        columns={schemaTable}
        rowKey="id"
        dataSource={hasPagination ? source.data?.data : source.data}
        size="small"
        loading={source.isLoading}
        pagination={
          /*showPagination*/ hasPagination && {
            defaultPageSize: tableQuery.change.pagination.pageSize,
            pageSizeOptions: pageSizeOptions,
            total: source.data?.total,
            pageSize: source.data?.per_page,
            showSizeChanger: true,
            current: tableQuery.change.pagination.current,
            position: ["topRight", "bottomRight"],
          }
        }
        onChange={tableQuery.change.onTableChange}
      />
    </Card>
  );
};

/**
 *
 * @param modal
 * @param mutate
 * @param config
 * @param data
 * @param loading
 * @param options
 * @constructor
 */
export const CommonDestroy = ({
  modal,
  mutate,
  config = {},
  data = {},
  loading = false,
  options = null,
}) => {
  const { _t } = useGlobalTranslate();

  const onSubmit = async () => {
    modal.hideModal();
    mutate && (await mutate.mutateAsync({}));
  };

  useEffect(() => {
    modal.updateModal({ onOk: onSubmit });
  }, []);

  return <Notification text={_t("are_you_sure")} />;
};

/**
 *
 * @param modal
 * @param mutate
 * @param data
 * @param structure
 * @param loading
 * @constructor
 */
export const CommonStructureDestroy = ({
  modal,
  mutate,
  data = {},
  structure = null,
  loading = false,
}) => {
  const { _t } = useGlobalTranslate();

  const onSubmit = async () => {
    modal.hideModal();
    mutate && (await mutate.mutateAsync({}));
  };

  useEffect(() => {
    modal.updateModal({ onOk: onSubmit });
  }, []);

  return <Notification text={_t("are_you_sure")} />;
};

export const CommonStructureUpset = ({
  drawer,
  mutate,
  data = {},
  structureForm = null,
  loading = false,
}) => {
  const formRef = useRef(null);

  const error = mutate.error;
  const onSubmit = async (data) => {
    mutate && (await mutate.mutateAsync(data));
    drawer.hideDrawer();
  };
  if (loading) {
    return <Spinner spinning={loading} />;
  }

  return (
    <CustomStructureForm
      formRef={formRef}
      {...formLayout}
      onSubmit={onSubmit}
      error={error}
      data={data}
      structureForm={structureForm}
    />
  );
};

/**
 *
 * @param modal
 * @param mutate
 * @param config
 * @param data
 * @param loading
 * @param options
 * @constructor
 */
export const CommonStructureModalUpset = ({
  modal,
  mutate,
  data = {},
  structureForm = null,
  loading = false,
}) => {
  const formRef = useRef(null);

  const error = mutate.error;
  const onSubmit = async (data) => {
    mutate && (await mutate.mutateAsync(data));
    modal.hideModal();
  };

  const onOk = () => {
    formRef.current.submit();
  };

  useEffect(() => {
    modal.updateModal({ onOk: onOk });
  }, [modal]);

  return (
    <Spinner spinning={loading}>
      <CustomStructureForm
        formRef={formRef}
        {...formLayout}
        onSubmit={onSubmit}
        error={error}
        data={data}
        structureForm={structureForm}
      />
    </Spinner>
  );
};

/**
 *
 * @param source
 * @param onCreate
 * @param onUpload
 * @param onDownload
 * @param tableQuery
 * @param columns
 * @param structureTableFilter
 * @param title
 * @param loading
 * @constructor
 */
export const CommonStructureIndex = ({
  source,
  onCreate = null,
  onUpload = null,
  onDownload = null,
  tableQuery = null,
  structureTable = [],
  structureTableFilter = null,
  title = null,
  loading = false,
}: any) => {
  const { _t } = useGlobalTranslate();

  // const schemaTableFilter = useGenerateSchema(configTableFilter, { locale });
  // const schemaTable = useGenerateColumns(configTable, { locale });
  const formLayout = {
    labelCol: { span: 24 },
    /*  wrapperCol: { span: 24 },*/
    fieldCol: { span: 24 },
  };
  const onSearch = (data) => {
    tableQuery &&
      tableQuery.change.setPagination({
        pageSize: defaultPageSize,
        current: defaultPage,
      });
    tableQuery && tableQuery.filter.setFilters(data);
  };

  const columns = structureTable.render();
  const hasPagination = !!source.data?.per_page;
  // const showPagination = !! (hasPagination && source.data?.total_pages && source.data?.total_pages > 1);
  return (
    <Card
      size={"small"}
      title={title && <ContainerHeader title={title} />}
      extra={
        <div style={{ padding: 0 }}>
          {onUpload && (
            <Button
              onClick={onUpload}
              icon={<CloudUploadOutlined />}
              style={{ marginBottom: 0 }}
            />
          )}
          {onDownload && (
            <Button
              disabled={loading}
              onClick={onDownload}
              icon={<CloudDownloadOutlined />}
              style={{ marginBottom: 0 }}
            />
          )}
          {onCreate && (
            <Button onClick={onCreate} style={{ marginBottom: 0 }}>
              {_t("create")}
            </Button>
          )}
        </div>
      }
    >
      {tableQuery.filter && (
        <CustomStructureForm
          {...formLayout}
          onSubmit={onSearch}
          submitOnReset
          structureForm={structureTableFilter}
        />
      )}

      <Table
        className="gx-table-responsive"
        columns={columns}
        rowKey="id"
        dataSource={hasPagination ? source.data?.data : source.data}
        size="small"
        loading={loading}
        pagination={
          /*showPagination*/ hasPagination && {
            defaultPageSize: tableQuery.change.pagination.pageSize,
            pageSizeOptions: pageSizeOptions,
            total: source.data?.total,
            pageSize: source.data?.per_page,
            showSizeChanger: true,
            current: tableQuery.change.pagination.current,
            position: ["topRight", "bottomRight"],
          }
        }
        onChange={tableQuery.change.onTableChange}
      />
    </Card>
  );
};

export const CommonUpset = ({
  drawer,
  mutate,
  config = {},
  data = {},
  loading = false,
  options = null,
}) => {
  const tabs = options?.tabs || [];
  const locale = options?.locale || [];
  const device = deviceTypes;

  const schema = useGenerateSchema(config, { locale, device }, data || {});

  const onSubmit = async (data) => {
    mutate && (await mutate.mutateAsync(data));
    drawer.hideDrawer();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Spinner spinning={loading}>
      <CustomForm
        tabs={tabs}
        {...formLayout}
        resErrors={mutate.error}
        onSubmit={onSubmit}
        schema={schema}
      />
    </Spinner>
  );
};

/**
 *
 * @param modal
 * @param mutate
 * @param config
 * @param data
 * @param loading
 * @param options
 * @constructor
 */
export const CommonModalUpset = ({
  modal,
  mutate,
  config = {},
  data = {},
  loading = false,
  options = null,
}) => {
  const tabs = options?.tabs || [];
  const locale = options?.locale || [];
  const device = deviceTypes;

  const schema = useGenerateSchema(config, { locale, device }, data || {});

  const formRef = useRef(null);

  const onSubmit = async (data) => {
    mutate && (await mutate.mutateAsync(data));
    modal.hideModal();
  };

  const onOk = () => {
    formRef.current.submit();
  };

  useEffect(() => {
    modal.updateModal({ onOk: onOk });
  }, [modal]);

  return (
    <CustomForm
      formRef={formRef}
      tabs={tabs}
      {...formLayout}
      resErrors={mutate.error}
      buttonsProps={{ reset: false, submit: false }}
      onSubmit={onSubmit}
      schema={schema}
    />
  );
};

// Download with secret token link

/**
 *
 * @param href
 */
export const createLinkAndClickIt = (href) => {
  const link = document.createElement("a");
  link.href = href;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return true;
};

/**
 *
 */
export const useCommonDownloadBySecretHref = () => {
  const apiSecret = useApiSecret();

  return async (target, query = {}) => {
    const secret = await apiSecret.mutateAsync({}); // request for secret key
    const href =
      backendUrl +
      target +
      generateUrlQuery({ ...query, download: true, ...secret });

    return createLinkAndClickIt(href);
  };
};

/**
 *
 */
export const useCommonDownloadBySecretHrefSecond = (target, query = {}) => {
  const apiSecret = useApiSecret();

  return async () => {
    const secret = await apiSecret.mutateAsync({}); // request for secret key
    const href =
      backendUrl +
      target +
      generateUrlQuery({ ...query, download: true, ...secret });

    return createLinkAndClickIt(href);
  };
};
