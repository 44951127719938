import { DatePicker, Input, Select } from "antd";
import Uploader from "components/molecules/uploader";
import Checkbox from "components/atoms/checkbox";
import TableActionButtons from "components/molecules/tableActionButtons";
import React from "react";
import JsonEditor from "components/atoms/jsonEditor";
import RoleCheckboxes from "components/molecules/roleCheckboxes";
import { Link } from "react-router-dom";
import AmountInput from "components/atoms/amountInput";

/**
 *
 * @param _t
 */
export const getTablePredefined = (_t) => {
  const variants = {
    // typeSortedDatetime: (overwrite = {}) => {
    //
    //     return variants.typeDatetime({sorter: true})
    //
    //     return {
    //         title: _t("id"),
    //         dataIndex: "id",
    //         sorter: true,
    //         ...overwrite
    //     };
    // },

    created_at: (overwrite = {}) => {
      return {
        title: _t("created_at"),
        dataIndex: "created_at",
        sorter: true,
        ...overwrite,
      };
    },
    updated_at: (overwrite = {}) => {
      return {
        title: _t("updated_at"),
        dataIndex: "updated_at",
        sorter: true,
        ...overwrite,
      };
    },
    deleted_at: (overwrite = {}) => {
      return {
        title: _t("deleted_at"),
        dataIndex: "deleted_at",
        sorter: true,
        ...overwrite,
      };
    },

    asString: (overwrite = {}) => {
      return {
        ...overwrite,
      };
    },

    version: (overwrite = {}) => {
      return {
        title: _t("version"),
        dataIndex: "version",
        align: "right",
        sorter: false,
        render: (val) => val,
        ...overwrite,
      };
    },

    balance: (overwrite = {}) => {
      return {
        title: _t("balance"),
        dataIndex: "balance",
        align: "right",
        sorter: false,
        render: (val) => (val / 100).toFixed(2),
        ...overwrite,
      };
    },

    // variants.currency({dataIndex: 'balance_currency'}),
    // variants.asNumber({title: _t('balance_version'), dataIndex: 'balance_version'}),
    // variants.balance({dataIndex: 'balance_balance'}),

    login: (overwrite = {}) => {
      return {
        title: _t("login"),
        render: (item) => (
          <Link to={`/user/${item?.id}/profile`}>
            {item?.login}
          </Link>
        ),
        // dataIndex: "login",
        ...overwrite,
      };
    },
    partner_code: (overwrite = {}) => {
      return {
        title: _t("partner_code"),
        dataIndex: "partner_code",
        ...overwrite,
      };
    },
    nickname: (overwrite = {}) => {
      return {
        title: _t("nickname"),
        dataIndex: "nickname",
        ...overwrite,
      };
    },

    id: (overwrite = {}) => {
      return {
        title: _t("id"),
        dataIndex: "id",
        sorter: true,
        ...overwrite,
      };
    },
    name: (overwrite = {}) => {
      return {
        title: _t("name"),
        dataIndex: "name",
        sorter: true,
        ...overwrite,
      };
    },
    uuid: (overwrite = {}) => {
      return {
        title: _t("uuid"),
        dataIndex: "uuid",
        sorter: true,
        ...overwrite,
      };
    },
    provider_tx: (overwrite = {}) => {
      return {
        title: _t("provider_tx"),
        dataIndex: "provider_tx",
        sorter: true,
        ...overwrite,
      };
    },
    status: (overwrite = {}) => {
      return {
        title: _t("status"),
        dataIndex: "status",
        sorter: true,
        ...overwrite,
      };
    },
    amount: (overwrite = {}) => {
      return {
        title: _t("amount"),
        dataIndex: "amount",
        sorter: true,
        render: (val) => val / 100,
        ...overwrite,
      };
    },

    translate: (overwrite = {}) => {
      return {
        title: (params) => _t("translate {locale}", params),
        dataIndex: "translate.{locale}",
        ...overwrite,
      };
    },
    key: (overwrite = {}) => {
      return {
        title: _t("key"),
        dataIndex: "key",
        sorter: true,
        ...overwrite,
      };
    },
    email: (overwrite = {}) => {
      return {
        title: _t("email"),
        dataIndex: "email",
        sorter: true,
        ...overwrite,
      };
    },
    email_verified_at: (overwrite = {}) => {
      return {
        title: _t("email_verified_at"),
        dataIndex: "email_verified_at",
        render: (val) => (val ? val : "-"),
        ...overwrite,
      };
    },
    phone: (overwrite = {}) => {
      return {
        title: _t("phone"),
        dataIndex: "phone",
        sorter: true,
        ...overwrite,
      };
    },
    phone_verified_at: (overwrite = {}) => {
      return {
        title: _t("phone_verified_at"),
        dataIndex: "phone_verified_at",
        render: (val) => (val ? val : "-"),
        ...overwrite,
      };
    },
    first_name: (overwrite = {}) => {
      return {
        title: _t("first_name"),
        dataIndex: "first_name",
        ...overwrite,
      };
    },
    last_name: (overwrite = {}) => {
      return {
        title: _t("last_name"),
        dataIndex: "last_name",
        ...overwrite,
      };
    },
    last_action_at: (overwrite = {}) => {
      return {
        title: _t("last_action_at"),
        dataIndex: "last_action_at",
        ...overwrite,
      };
    },
    xp: (overwrite = {}) => {
      return {
        title: _t("xp"),
        dataIndex: "xp",
        ...overwrite,
      };
    },
    country: (overwrite = {}) => {
      return {
        title: _t("country"),
        dataIndex: "country",
        ...overwrite,
      };
    },
    locale: (overwrite = {}) => {
      return {
        title: _t("locale"),
        dataIndex: "locale",
        ...overwrite,
      };
    },
    currency: (overwrite = {}) => {
      return {
        title: _t("currency"),
        dataIndex: "currency",
        ...overwrite,
      };
    },
    gate: (overwrite = {}) => {
      return {
        title: _t("gate"),
        dataIndex: "gate",
        sorter: true,
        ...overwrite,
      };
    },
    gate_key: (overwrite = {}) => {
      return {
        title: _t("gate_key"),
        dataIndex: "gate_key",
        sorter: true,
        ...overwrite,
      };
    },

    strategy: (overwrite = {}) => {
      return {
        title: _t("strategy"),
        dataIndex: "strategy",
        render: (val) => val && JSON.stringify(val),
        ...overwrite,
      };
    },

    runner: (overwrite = {}) => {
      return {
        title: _t("runner"),
        dataIndex: "runner",
        sorter: true,
        ...overwrite,
      };
    },

    runner_key: (overwrite = {}) => {
      return {
        title: _t("runner_key"),
        dataIndex: "runner_key",
        sorter: true,
        ...overwrite,
      };
    },

    has_mobile: (overwrite = {}) => {
      return {
        title: _t("has_mobile"),
        dataIndex: "has_mobile",
        sorter: true,
        ...overwrite,
      };
    },

    has_desktop: (overwrite = {}) => {
      return {
        title: _t("has_desktop"),
        dataIndex: "has_desktop",
        sorter: true,
        ...overwrite,
      };
    },

    provider: (overwrite = {}) => {
      return {
        title: _t("provider"),
        dataIndex: "provider",
        sorter: true,
        ...overwrite,
      };
    },
    type: (overwrite = {}) => {
      return {
        title: _t("type"),
        dataIndex: "type",
        sorter: true,
        ...overwrite,
      };
    },
    slug: (overwrite = {}) => {
      return {
        title: _t("slug"),
        dataIndex: "slug",
        sorter: true,
        ...overwrite,
      };
    },

    __text: (overwrite = {}) => {
      return {
        render: (val) => `${val}`,
        ...overwrite,
      };
    },

    active: (overwrite = {}) => {
      return {
        title: _t("active"),
        dataIndex: "active",
        render: (val) => `${val}`,
        ...overwrite,
      };
    },
    kyc_status: (overwrite = {}) => {
      return {
        title: _t("kyc_status"),
        dataIndex: "kyc_status",
        render: (val) => `${val}`,
        ...overwrite,
      };
    },

    gender: (overwrite = {}) => {
      return {
        title: _t("gender"),
        dataIndex: "gender",
        ...overwrite,
      };
    },
    birthday: (overwrite = {}) => {
      return {
        title: _t("birthday"),
        dataIndex: "birthday",
        ...overwrite,
      };
    },
    position: (overwrite = {}) => {
      return {
        title: _t("position"),
        dataIndex: "position",
        ...overwrite,
      };
    },
    aliases: (overwrite = {}) => {
      return {
        title: _t("aliases"),
        dataIndex: "aliases",
        render: (val) => val?.join(","),
        ...overwrite,
      };
    },
    allow_countries: (overwrite = {}) => {
      return {
        title: _t("allow_countries"),
        dataIndex: "allow_countries",
        render: (val) => val?.join(", "),
        ...overwrite,
      };
    },
    allow_currencies: (overwrite = {}) => {
      return {
        title: _t("allow_currencies"),
        dataIndex: "allow_currencies",
        render: (val) => val?.join(", "),
        ...overwrite,
      };
    },

    currencies: (overwrite = {}) => {
      return {
        title: _t("currencies"),
        dataIndex: "currencies",
        render: (val) => val?.join(","),
        ...overwrite,
      };
    },
    languages: (overwrite = {}) => {
      return {
        title: _t("languages"),
        dataIndex: "languages",
        render: (val) => val?.join(","),
        ...overwrite,
      };
    },
    // created_at: (overwrite = {}) => {
    //     return {title: _t("created_at"), dataIndex: "created_at", ...overwrite};
    // },
    action: (
      overwrite = {},
      { onShow = null, onEdit = null, onDelete = null }
    ) => {
      return {
        title: _t("action"),
        render: (item) => (
          <TableActionButtons
            onShow={onShow && (() => onShow(item))}
            onEdit={onEdit && (() => onEdit(item))}
            onDelete={onDelete && (() => onDelete(item))}
          />
        ),
        ...overwrite,
      };
    },
  };

  return variants;
};

/**
 *
 * @param _t
 */
export const getTableFilterPredefined = (_t) => {
  const size = {
    25: { xs: 24 / 4, md: 8 / 4 },
    50: { xs: 24 / 2, md: 8 / 2 },
    100: { xs: 24, md: 8 },
  };

  const variants = {
    currency_in: (overwrite = {}) => {
      return {
        component: Select,
        name: "where.currency.in",
        label: _t("currency"),
        placeholder: _t("currency"),
        componentProps: {
          allowClear: true,
          options: [
            { label: "EUR", value: "EUR" },
            { label: "USD", value: "USD" },
          ],
          mode: "tags",
        },
        colParams: size[50],
        ...overwrite,
      };
    },
    country_in: (overwrite = {}) => {
      return {
        component: Select,
        name: "where.country.in",
        label: _t("country"),
        placeholder: _t("country"),
        componentProps: {
          allowClear: true,
          // options: [
          //     {"label" : 'EUR', 'value' : 'EUR'},
          //     {"label" : 'USD', 'value' : 'USD'}
          // ],
          mode: "tags",
        },
        colParams: size[50],
        ...overwrite,
      };
    },
    status_in: (overwrite = {}) => {
      return {
        component: Select,
        name: "where.country.in",
        label: _t("country"),
        placeholder: _t("country"),
        componentProps: {
          allowClear: true,
          // options: [
          //     {"label" : 'EUR', 'value' : 'EUR'},
          //     {"label" : 'USD', 'value' : 'USD'}
          // ],
          mode: "tags",
        },
        colParams: size[50],
        ...overwrite,
      };
    },

    balance_from: (overwrite = {}) => {
      return {
        component: AmountInput,
        componentProps: {
          type: "number",
          allowClear: true,
        },
        name: "where.balance.>=",
        label: _t("balance_from"),
        placeholder: _t("balance_from"),
        colParams: size[50],
        ...overwrite,
      };
    },
    balance_to: (overwrite = {}) => {
      return {
        component: AmountInput,
        componentProps: {
          type: "number",
          allowClear: true,
        },
        name: "where.balance.<=",
        label: _t("balance_to"),
        placeholder: _t("balance_to"),
        colParams: size[50],
        ...overwrite,
      };
    },

    amount_from: (overwrite = {}) => {
      return {
        component: AmountInput,
        componentProps: {
          type: "number",
          allowClear: true,
        },
        name: "where.amount.>=",
        label: _t("amount_from"),
        placeholder: _t("amount_from"),
        colParams: size[50],
        ...overwrite,
      };
    },
    amount_to: (overwrite = {}) => {
      return {
        component: AmountInput,
        componentProps: {
          type: "number",
          allowClear: true,
        },
        name: "where.amount.<=",
        label: _t("amount_to"),
        placeholder: _t("amount_to"),
        colParams: size[50],
        ...overwrite,
      };
    },

    created_at: (overwrite = {}) => {
      return {
        component: DatePicker.RangePicker,
        componentProps: {
          allowClear: true,
          showTime: true,
        },
        name: "where.created_at.in", // TODO >= and <=
        label: _t("created_at"),
        placeholder: _t("created_at"),
        colParams: size[100],
        ...overwrite,
      };
    },

    updated_at: (overwrite = {}) => {
      return {
        component: DatePicker.RangePicker,
        componentProps: {
          allowClear: true,
          showTime: true,
        },
        name: "where.updated_at.in", // TODO >= and <=
        label: _t("updated_at"),
        placeholder: _t("updated_at"),
        colParams: size[100],
        ...overwrite,
      };
    },

    partner_code: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.partner_code.like",
        label: _t("partner_code"),
        placeholder: _t("partner_code"),
        colParams: size[100],
        ...overwrite,
      };
    },

    email: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.email.like",
        label: _t("email"),
        placeholder: _t("email"),
        colParams: size[100],
        ...overwrite,
      };
    },
    kyc_status: (overwrite = {}) => {
      return {
        component: Select,
        componentProps: {
          allowClear: true,
          options: [
            { label: "true", value: 1 },
            { label: "false", value: 0 },
          ],
          // mode: "tags",
        },

        name: "where.kyc_status",
        label: _t("kyc_status"),
        placeholder: _t("kyc_status"),
        colParams: size[25],
        ...overwrite,
      };
    },
    active: (overwrite = {}) => {
      return {
        component: Select,
        componentProps: {
          allowClear: true,
          options: [
            { label: "true", value: 1 },
            { label: "false", value: 0 },
          ],
          // mode: "tags",
        },

        name: "where.active",
        label: _t("active"),
        placeholder: _t("active"),
        colParams: size[25],
        ...overwrite,
      };
    },

    phone: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          type: "number",
          allowClear: true,
        },
        name: "where.phone.like",
        label: _t("phone"),
        placeholder: _t("phone"),
        colParams: size[100],
        ...overwrite,
      };
    },

    name: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.name",
        label: _t("name"),
        placeholder: _t("name"),
        colParams: size[100],
        ...overwrite,
      };
    },

    provider: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.provider",
        label: _t("provider"),
        placeholder: _t("provider"),
        colParams: size[100],
        ...overwrite,
      };
    },

    runner: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.runner",
        label: _t("runner"),
        placeholder: _t("runner"),
        colParams: size[100],
        ...overwrite,
      };
    },

    runner_key: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.runner_key",
        label: _t("runner_key"),
        placeholder: _t("runner_key"),
        colParams: size[100],
        ...overwrite,
      };
    },

    gate: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.gate",
        label: _t("gate"),
        placeholder: _t("gate"),
        colParams: size[100],
        ...overwrite,
      };
    },

    gate_key: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.gate_key",
        label: _t("gate_key"),
        placeholder: _t("gate_key"),
        colParams: size[100],
        ...overwrite,
      };
    },

    type: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.type",
        label: _t("type"),
        placeholder: _t("type"),
        colParams: size[100],
        ...overwrite,
      };
    },

    slug: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.slug",
        label: _t("slug"),
        placeholder: _t("slug"),
        colParams: size[100],
        ...overwrite,
      };
    },

    key: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.key",
        label: _t("key"),
        placeholder: _t("key"),
        colParams: size[100],
        ...overwrite,
      };
    },
    translate: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.translate.{locale}.like",
        label: (params) => _t("translate {locale}", params),
        placeholder: _t("translate {locale}"),
        colParams: size[100],
        ...overwrite,
      };
    },

    search_full_data: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: {
          allowClear: true,
        },
        name: "where.@search",
        label: _t("search"),
        placeholder: _t("search"),
        colParams: size[100],
        ...overwrite,
      };
    },
  };

  // name: "translate.{locale}",
  // label: (params) => _t("translate {locale}", params),

  return variants;
};

/**
 *
 * @param _t
 */
export const getFormPredefined = (_t) => {
  const variants = {
    system_method_id: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "system_method_id",
        label: _t("system_method_id"),
        placeholder: _t("system_method_id"),
        componentProps: {
          options: options.select,
          showSearch: true,
          filterOption: (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          },
        },
        rules: [
          {
            required: true,
            message: _t("validation.required_system_method_id"),
          },
        ],
        ...overwrite,
      };
    },
    system_game_id: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "system_game_id",
        label: _t("system_game_id"),
        placeholder: _t("system_game_id"),
        componentProps: {
          options: options.select,
          showSearch: true,
          filterOption: (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          },
        },
        rules: [
          { required: true, message: _t("validation.required_system_game_id") },
        ],
        ...overwrite,
      };
    },
    resources: (overwrite = {}, options) => {
      return {
        component: RoleCheckboxes,
        name: "resources",
        label: _t("resources"),
        placeholder: _t("resources"),
        componentProps: {
          options: options.select,
        },
        rules: [
          { required: true, message: _t("validation.required_resources") },
        ],
      };
    },
    email: (overwrite = {}) => {
      return {
        component: Input,
        name: "email",
        label: _t("email"),
        placeholder: _t("email"),
        rules: [{ required: true, message: _t("validation.required_email") }],
        ...overwrite,
      };
    },
    comment: (overwrite = {}) => {
      return {
        component: Input,
        name: "comment",
        label: _t("comment"),
        placeholder: _t("comment"),
        rules: [{ required: true, message: _t("validation.required_comment") }],
        ...overwrite,
      };
    },
    phone_verify: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "phone_verify",
        componentProps: { label: _t("phone_verify") },
        ...overwrite,
      };
    },
    email_verify: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "email_verify",
        componentProps: { label: _t("email_verify") },
        ...overwrite,
      };
    },
    phone: (overwrite = {}) => {
      return {
        component: Input,
        name: "phone",
        label: _t("phone"),
        placeholder: _t("phone"),
        rules: [{ required: true, message: _t("validation.required_phone") }],
        ...overwrite,
      };
    },
    partner_code: (overwrite = {}) => {
      return {
        component: Input,
        name: "partner_code",
        label: _t("partner_code"),
        placeholder: _t("partner_code"),
        rules: [
          { required: true, message: _t("validation.required_partner_code") },
        ],
        ...overwrite,
      };
    },
    login: (overwrite = {}) => {
      return {
        component: Input,
        name: "login",
        label: _t("login"),
        placeholder: _t("login"),
        rules: [{ required: true, message: _t("validation.required_login") }],
        ...overwrite,
      };
    },
    gender: (overwrite = {}) => {
      return {
        component: Select,
        name: "gender",
        label: _t("gender"),
        placeholder: _t("gender"),
        componentProps: {
          options: [
            { label: "male", value: "male" },
            { label: "female", value: "female" },
          ],
        },
        rules: [{ required: true, message: _t("validation.required_gender") }],
      };
    },

    kyc_status: (overwrite = {}) => {
      return {
        component: Select,
        name: "kyc_status",
        label: _t("kyc_status"),
        placeholder: _t("kyc_status"),
        componentProps: {
          options: [
            { label: "true", value: true },
            { label: "false", value: false },
          ],
        },
        rules: [
          { required: true, message: _t("validation.required_kyc_status") },
        ],
      };
    },

    country: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "country",
        label: _t("country"),
        placeholder: _t("country"),
        componentProps: {
          options: options.select,
          showSearch: true,
          filterOption: (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          },
        },
        rules: [{ required: true, message: _t("validation.required_country") }],
        ...overwrite,
      };
    },
    locale: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "locale",
        label: _t("locale"),
        placeholder: _t("locale"),
        componentProps: {
          options: options.select,
          showSearch: true,
          filterOption: (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          },
        },
        rules: [{ required: true, message: _t("validation.required_locale") }],
        ...overwrite,
      };
    },
    birthday: (overwrite = {}) => {
      return {
        component: Input,
        name: "birthday",
        label: _t("birthday"),
        placeholder: _t("birthday"),
        rules: [
          { required: true, message: _t("validation.required_birthday") },
        ],
        ...overwrite,
      };
    },
    key: (overwrite = {}) => {
      return {
        component: Input,
        name: "key",
        label: _t("key"),
        placeholder: _t("key"),
        rules: [{ required: true, message: _t("validation.required_key") }],
        ...overwrite,
      };
    },
    password: (overwrite = {}) => {
      return {
        component: Input,
        name: "password",
        label: _t("password"),
        placeholder: _t("password"),
        rules: [
          // {required: true, message: _t("validation.required_password")}
        ],
        ...overwrite,
      };
    },
    role_id: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "role_id",
        label: _t("role_id"),
        placeholder: _t("role_id"),
        componentProps: {
          options: options.select,
        },
        rules: [{ required: true, message: _t("validation.required_role_id") }],
        ...overwrite,
      };
    },

    brand_ids: (overwrite = {}, options) => {
      return {
        component: Select,
        name: "brand_ids",
        label: _t("brand_ids"),
        placeholder: _t("brand_ids"),
        componentProps: {
          options: options.select,
          mode: "tags",
        },
        rules: [
          { required: true, message: _t("validation.required_brand_ids") },
        ],
        ...overwrite,
      };
    },
    allow_countries: (overwrite = {}) => {
      return {
        component: Select,
        name: "allow_countries",
        label: _t("allow_countries"),
        placeholder: _t("allow_countries"),
        componentProps: {
          mode: "tags",
        },
        rules: [
          {
            required: true,
            message: _t("validation.required_allow_countries"),
          },
        ],
        ...overwrite,
      };
    },
    allow_currencies: (overwrite = {}) => {
      return {
        component: Select,
        name: "allow_currencies",
        label: _t("allow_currencies"),
        placeholder: _t("allow_currencies"),
        componentProps: {
          mode: "tags",
        },
        rules: [
          {
            required: true,
            message: _t("validation.required_allow_currencies"),
          },
        ],
        ...overwrite,
      };
    },
    name: (overwrite = {}) => {
      return {
        component: Input,
        name: "name",
        label: _t("name"),
        placeholder: _t("name"),
        rules: [{ required: true, message: _t("validation.required_name") }],
        ...overwrite,
      };
    },
    last_name: (overwrite = {}) => {
      return {
        component: Input,
        name: "last_name",
        label: _t("last_name"),
        placeholder: _t("last_name"),
        rules: [
          { required: true, message: _t("validation.required_last_name") },
        ],
        ...overwrite,
      };
    },
    first_name: (overwrite = {}) => {
      return {
        component: Input,
        name: "first_name",
        label: _t("first_name"),
        placeholder: _t("first_name"),
        rules: [
          { required: true, message: _t("validation.required_first_name") },
        ],
        ...overwrite,
      };
    },
    nickname: (overwrite = {}) => {
      return {
        component: Input,
        name: "nickname",
        label: _t("nickname"),
        placeholder: _t("nickname"),
        rules: [
          { required: true, message: _t("validation.required_nickname") },
        ],
        ...overwrite,
      };
    },

    amount: (overwrite = {}) => {
      return {
        component: AmountInput,
        componentProps: {
          type: "number",
        },
        name: "amount",
        label: _t("amount"),
        placeholder: _t("amount"),
        rules: [{ required: true, message: _t("validation.required_amount") }],
        ...overwrite,
      };
    },
    currency: (overwrite = {}) => {
      return {
        component: Input,
        name: "currency",
        label: _t("currency"),
        placeholder: _t("currency"),
        rules: [
          { required: true, message: _t("validation.required_currency") },
        ],
        ...overwrite,
      };
    },
    slug: (overwrite = {}) => {
      return {
        component: Input,
        name: "slug",
        label: _t("slug"),
        placeholder: _t("slug"),
        rules: [{ required: true, message: _t("validation.required_slug") }],
        ...overwrite,
      };
    },
    token: (overwrite = {}) => {
      return {
        component: Input,
        name: "token",
        label: _t("token"),
        placeholder: _t("token"),
        rules: [{ required: true, message: _t("validation.required_token") }],
        ...overwrite,
      };
    },
    provider: (overwrite = {}) => {
      return {
        component: Input,
        name: "provider",
        label: _t("provider"),
        placeholder: _t("provider"),
        rules: [
          { required: true, message: _t("validation.required_provider") },
        ],
        ...overwrite,
      };
    },
    runner: (overwrite = {}) => {
      return {
        component: Input,
        name: "runner",
        label: _t("runner"),
        placeholder: _t("runner"),
        rules: [{ required: true, message: _t("validation.required_runner") }],
        ...overwrite,
      };
    },
    runner_key: (overwrite = {}) => {
      return {
        component: Input,
        name: "runner_key",
        label: _t("runner_key"),
        placeholder: _t("runner_key"),
        rules: [
          { required: true, message: _t("validation.required_runner_key") },
        ],
        ...overwrite,
      };
    },

    gate: (overwrite = {}) => {
      return {
        component: Input,
        name: "gate",
        label: _t("gate"),
        placeholder: _t("gate"),
        rules: [{ required: true, message: _t("validation.required_gate") }],
        ...overwrite,
      };
    },
    gate_key: (overwrite = {}) => {
      return {
        component: Input,
        name: "gate_key",
        label: _t("gate_key"),
        placeholder: _t("gate_key"),
        rules: [
          { required: true, message: _t("validation.required_gate_key") },
        ],
        ...overwrite,
      };
    },
    type: (overwrite = {}) => {
      return {
        component: Input,
        name: "type",
        label: _t("type"),
        placeholder: _t("type"),
        rules: [{ required: true, message: _t("validation.required_type") }],
        ...overwrite,
      };
    },
    position: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: { type: "number" },
        name: "position",
        label: _t("position"),
        ...overwrite,
      };
    },
    translate: (overwrite = {}) => {
      return {
        component: Input,
        name: "translate.{locale}",
        label: (params) => _t("translate {locale}", params),
        rules: [
          { required: true, message: _t("validation.required_translate") },
        ],
        ...overwrite,
      };
    },
    title: (overwrite = {}) => {
      return {
        component: Input,
        name: "title.{locale}",
        label: (params) => _t("title {locale}", params),
        rules: [{ required: true, message: _t("validation.required_title") }],
        ...overwrite,
      };
    },
    text: (overwrite = {}) => {
      return {
        component: Input,
        name: "text.{locale}",
        label: (params) => _t("text {locale}", params),
        rules: [{ required: true, message: _t("validation.required_text") }],
        ...overwrite,
      };
    },
    strategy: (overwrite = {}) => {
      return {
        component: Input,
        name: "strategy.{device}",
        label: (params) => _t("strategy {device}", params),
        rules: [
          { required: true, message: _t("validation.required_strategy") },
        ],
        ...overwrite,
      };
    },
    xp: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: { type: "number" },
        name: "xp",
        label: _t("xp"),
        placeholder: _t("xp"),
        rules: [{ required: true, message: _t("validation.required_xp") }],
        ...overwrite,
      };
    },
    image: (overwrite = {}) => {
      return {
        component: Uploader,
        name: "image.{device}",
        label: (params) => _t("image {device}", params),
        rules: [{ required: true, message: _t("validation.required_image") }],
        ...overwrite,
      };
    },
    file: (overwrite = {}) => {
      return {
        component: Uploader,
        name: "file",
        label: _t("file"),
        rules: [{ required: true, message: _t("validation.required_file") }],
        ...overwrite,
      };
    },

    transfer_on_import_create: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "create-if-not-exists-in-db",
        componentProps: {
          label: _t("create-if-not-exists-in-db"),
        },
        ...overwrite,
      };
    },
    transfer_on_import_update: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "update-if-exists-in-csv",
        componentProps: {
          label: _t("update-if-exists-in-csv"),
        },
        ...overwrite,
      };
    },
    transfer_on_import_delete: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "remove-if-not-exists-csv",
        componentProps: {
          label: _t("remove-if-not-exists-csv"),
        },
        ...overwrite,
      };
    },
    background: (overwrite = {}) => {
      return {
        component: Uploader,
        name: "background.{device}",
        label: (params) => _t("background {device}", params),
        rules: [
          { required: true, message: _t("validation.required_background") },
        ],
        ...overwrite,
      };
    },
    active: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "active",
        componentProps: {
          label: _t("active"),
        },
        ...overwrite,
      };
    },
    aliases: (overwrite = {}) => {
      return {
        component: Select,
        name: "aliases",
        label: _t("aliases"),
        rules: [{ required: true, message: _t("validation.required_aliases") }],
        componentProps: { mode: "tags" },
        ...overwrite,
      };
    },
    currencies: (overwrite = {}) => {
      return {
        component: Select,
        name: "currencies",
        label: _t("currencies"),
        rules: [
          { required: true, message: _t("validation.required_currencies") },
        ],
        componentProps: { mode: "tags" },
        ...overwrite,
      };
    },
    languages: (overwrite = {}) => {
      return {
        component: Select,
        name: "languages",
        label: _t("languages"),
        rules: [
          { required: true, message: _t("validation.required_languages") },
        ],
        componentProps: { mode: "tags" },
        ...overwrite,
      };
    },
    seo_title: (overwrite = {}) => {
      return {
        component: Input.TextArea,
        name: "seo_title.{locale}",
        label: (params) => _t("seo_title {locale}", params),
        placeholder: (params) => _t("seo_title {locale}", params),
        ...overwrite,
      };
    },
    seo_description: (overwrite = {}) => {
      return {
        component: Input.TextArea,
        name: "seo_description.{locale}",
        label: (params) => _t("seo_description {locale}", params),
        placeholder: (params) => _t("seo_description {locale}", params),
        ...overwrite,
      };
    },
    seo_keywords: (overwrite = {}) => {
      return {
        component: Input.TextArea,
        name: "seo_keywords.{locale}",
        label: (params) => _t("seo_keywords {locale}", params),
        placeholder: (params) => _t("seo_keywords {locale}", params),
        ...overwrite,
      };
    },
    has_mobile: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "has_mobile",
        componentProps: { label: _t("has_mobile") },
        ...overwrite,
      };
    },
    has_desktop: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "has_desktop",
        componentProps: { label: _t("has_desktop") },
        ...overwrite,
      };
    },
    options: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "options",
        label: _t("options"),
        placeholder: _t("options"),
        componentProps: {
          containerId: "options",
        },
        rules: [{ required: true, message: _t("validation.required_options") }],
        ...overwrite,
      };
    },
    settings: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "settings",
        label: _t("settings"),
        placeholder: _t("settings"),
        componentProps: {
          containerId: "settings",
        },
        rules: [
          { required: true, message: _t("validation.required_settings") },
        ],
        ...overwrite,
      };
    },
    public: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "public",
        label: _t("public"),
        placeholder: _t("public"),
        componentProps: {
          containerId: "public",
        },
        rules: [{ required: true, message: _t("validation.required_public") }],
        ...overwrite,
      };
    },
    system: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "system",
        label: _t("system"),
        placeholder: _t("system"),
        componentProps: {
          containerId: "system",
        },
        rules: [
          { required: true, message: _t("validation.required_country_system") },
        ],
        ...overwrite,
      };
    },
    country_currency_map: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "country_currency_map",
        label: _t("country_currency_map"),
        placeholder: _t("country_currency_map"),
        componentProps: {
          containerId: "country_currency_map",
        },
        rules: [
          {
            required: true,
            message: _t("validation.required_country_currency_map"),
          },
        ],
        ...overwrite,
      };
    },
    input: (overwrite = {}) => {
      return {
        component: JsonEditor,
        name: "input",
        label: _t("input"),
        placeholder: _t("input"),
        componentProps: {
          containerId: "input",
        },
        rules: [{ required: true, message: _t("validation.required_input") }],
        ...overwrite,
      };
    },
    has_demo: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "has_demo",
        componentProps: { label: _t("has_demo") },
        ...overwrite,
      };
    },
    is_hot: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "is_hot",
        componentProps: { label: _t("is_hot") },
        ...overwrite,
      };
    },
    is_new: (overwrite = {}) => {
      return {
        component: Checkbox,
        name: "is_new",
        componentProps: { label: _t("is_new") },
        ...overwrite,
      };
    },
    likes: (overwrite = {}) => {
      return {
        component: Input,
        componentProps: { type: "number" },
        name: "likes",
        label: _t("likes"),
        ...overwrite,
      };
    },
  };

  return variants;
};
