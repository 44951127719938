import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Drawer as AntdDrawer, Modal as AntModal } from "antd";
import { ModalTypes, ModalParams } from "./types";
import DrawerLabel from "components/atoms/drawerLabel";

const Modal: React.FC<ModalTypes> = forwardRef(({ hideModal }, ref) => {
  const [visible, setVisible] = useState({});
  const [params, setParams] = useState<ModalParams>({});
  const [focus, setFocus] = useState("default");

  useImperativeHandle(ref, () => ({
    modalOpen(name: string, param: any) {
      setParams({ ...params, [name]: param });
      setFocus(name);
      setVisible({ ...visible, [name]: true });
    },
    modalUpdate(name: string, param: any) {
      setParams({ ...params, [name]: { ...params[name], ...param } });
    },
    modalClose(name: any) {
      const modalParams = { ...params };
      delete modalParams[name];
      setParams(modalParams);
      setVisible({ ...visible, [name]: false });
    },
  }));

  return (
    <>
      {Object.keys(visible).map((name, idx) => {
        const {
          component: Content,
          componentProps,
          body,
          modalFooter,
          className,
          style,
          title,
          modalSize = "m",
          onOk,
        } = params?.[name] || {};

        return (
          <>
            <AntModal
              key={name + idx}
              destroyOnClose
              title={title}
              visible={visible?.[name]}
              style={{
                ...style,
                zIndex: name === focus ? 900 : 800,
              }}
              onCancel={() => hideModal(name)}
              onOk={() => onOk()}
              className={`p-dialog-${modalSize} ${className}`}
            >
              <div className={"gx-p-3"}>
                {Content ? <Content {...componentProps} /> : body}
              </div>
            </AntModal>
          </>
        );
      })}
    </>
  );
});

export default Modal;
