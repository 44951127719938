import Structure, {NodeType, strf} from "./Structure";
import TableActionButtons from "../../molecules/tableActionButtons";
import React from "react";
import { Link } from "react-router-dom";
import {Badge} from "antd";
import {arrayMap} from "../../../utils/helpers/helperFunctions";


/**
 *
 */
export type TablePropsType = {
  [key: string]: any;
}

/**
 *
 */
export type TableType = {
  field?: string,
  props?: TablePropsType;
}

/**
 *
 */
export default class StructureTable extends Structure {

  render() {
    const nodes: any = this.getNodes();

    nodes.map((node) => {
      node.dataIndex = node.path?.split("."); // dot to array 'translate.en' > ['translate', 'en']
      node.title = node.label;

      return node;
    });

    return nodes;
  }

  method_gate(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "gate",
      label: this._t("method_gate"),
      sorter: true,
      ...overwrite.props,
    });
  }

  method_gate_key(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "gate_key",
      label: this._t("method_gate_key"),
      sorter: true,
      ...overwrite.props,
    });
  }

  game_provider(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "provider",
      label: this._t("game_provider"),
      sorter: true,
      ...overwrite.props,
    });
  }

  game_runner(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "runner",
      label: this._t("game_runner"),
      sorter: true,
      ...overwrite.props,
    });
  }

  game_runner_key(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "runner_key",
      label: this._t("game_runner_key"),
      sorter: true,
      ...overwrite.props,
    });
  }

  method_input(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "input",
      label: this._t("method_input"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }
  method_strategy(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "strategy",
      label: this._t("method_strategy"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  allow_countries(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "allow_countries",
      label: this._t("allow_countries"),
      // sorter: true,
      render: (val) => val && val.join(', '),
      ...overwrite.props,
    });
  }

  allow_currencies(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "allow_currencies",
      label: this._t("allow_currencies"),
      // sorter: true,
      render: (val) => val && val.join(', '),
      ...overwrite.props,
    });
  }

  flags(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "flags",
      label: this._t("flags"),
      // sorter: true,
      render: (val) => val && val.join(', '),
      ...overwrite.props,
    });
  }

  country_currency_map(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "country_currency_map",
      label: this._t("country_currency_map"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  type(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "type",
      label: this._t("type"),
      sorter: true,
      ...overwrite.props,
    });
  }

  status(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "status",
      label: this._t("status"),
      sorter: true,
      ...overwrite.props,
    });
  }


  system(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "system",
      label: this._t("system"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  public(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "public",
      label: this._t("public"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  title_string(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "title",
      label: this._t("title"),
      sorter: true,
      ...overwrite.props,
    });
  }

  text_string(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "text",
      label: this._t("text"),
      sorter: true,
      ...overwrite.props,
    });
  }

  name(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "name",
      label: this._t("name"),
      sorter: true,
      ...overwrite.props,
    });
  }

  path(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "path",
      label: this._t("path"),
      sorter: true,
      ...overwrite.props,
    });
  }
  namespace(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "namespace",
      label: this._t("namespace"),
      sorter: true,
      ...overwrite.props,
    });
  }

  position(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "position",
      label: this._t("position"),
      sorter: true,
      ...overwrite.props,
    });
  }

  slug(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "slug",
      label: this._t("slug"),
      sorter: true,
      ...overwrite.props,
    });
  }


  show_from(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "show_from",
      label: this._t("show_from"),
      sorter: true,
      ...overwrite.props,
    });
  }

  show_to(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "show_to",
      label: this._t("show_to"),
      sorter: true,
      ...overwrite.props,
    });
  }

  show_condition(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "show_condition",
      label: this._t("show_condition"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }


  category_position(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "category_position",
      label: this._t("category_position"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }



  token(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "token",
      label: this._t("token"),
      // sorter: true,
      ...overwrite.props,
    });
  }

  languages(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "languages",
      label: this._t("languages"),
      // sorter: true,
      render: (val) => val && val.join(', '),
      ...overwrite.props,
    });
  }

  currencies(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "currencies",
      label: this._t("currencies"),
      // sorter: true,
      render: (val) => val && val.join(', '),
      ...overwrite.props,
    });
  }

  round_id(roundIndex, overwrite: NodeType = {}) {
    const map = arrayMap(roundIndex?.data, {key: 'id', value: 'title'});
    return this.add({
      path: overwrite.field || "round_id",
      label: this._t("round_id"),
      sorter: true,
      render: (val) => map[val],
      ...overwrite.props,
    });
  }

  role_id(roleIndex, overwrite: NodeType = {}) {
    const map = arrayMap(roleIndex?.data, {key: 'id', value: 'name'});
    return this.add({
      path: overwrite.field || "role_id",
      label: this._t("role_id"),
      sorter: true,
      render: (val) => map[val],
      ...overwrite.props,
    });
  }

  brand_ids(brandIndex, overwrite: NodeType = {}) {
    const map = arrayMap(brandIndex?.data, {key: 'id', value: 'name'});
    return this.add({
      path: overwrite.field || "brand_ids",
      label: this._t("brand_ids"),
      // sorter: true,
      render: (val) => {
        if(val.includes('*')){
           return '[all]';
        }

        let result = [];
        for(var i in val){
          result.push( map[ val[i] ] );
        }
        return result.join(', ');
      },
      ...overwrite.props,
    });
  }



  settings(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "settings",
      label: this._t("settings"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }


  options(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "options",
      label: this._t("options"),
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  active(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "active",
      label: this._t("active"),
      align: "center",
      sorter: true,
      render: (val) => val ? <Badge status="success" /> : <Badge status="error" />,
      ...overwrite.props,
    });
  }

  has_mobile(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "has_mobile",
      label: this._t("has_mobile"),
      align: "center",
      sorter: true,
      render: (val) => val ? <Badge status="success" /> : <Badge status="error" />,
      ...overwrite.props,
    });
  }
  has_desktop(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "has_desktop",
      label: this._t("has_desktop"),
      align: "center",
      sorter: true,
      render: (val) => val ? <Badge status="success" /> : <Badge status="error" />,
      ...overwrite.props,
    });
  }

  nickname(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "nickname",
      label: this._t("nickname"),
      sorter: true,
      ...overwrite.props,
    });
  }
  phone(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "phone",
      label: this._t("phone"),
      sorter: true,
      ...overwrite.props,
    });
  }
  country(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "country",
      label: this._t("country"),
      sorter: true,
      ...overwrite.props,
    });
  }
  city(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "city",
      label: this._t("city"),
      sorter: true,
      ...overwrite.props,
    });
  }
  organization(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "organization",
      label: this._t("organization"),
      sorter: true,
      ...overwrite.props,
    });
  }
  appointment(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "appointment",
      label: this._t("appointment"),
      sorter: true,
      ...overwrite.props,
    });
  }
  locale(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field || "locale",
      label: this._t("locale"),
      sorter: true,
      ...overwrite.props,
    });
  }
  first_name(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "first_name",
      label: this._t("first_name"),
      sorter: true,
      ...overwrite.props,
    });
  }
  last_name(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "last_name",
      label: this._t("last_name"),
      sorter: true,
      ...overwrite.props,
    });
  }


  full_name(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "full_name",
      label: this._t("full_name"),
      sorter: true,
      ...overwrite.props,
    });
  }

  last_action_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "last_action_at",
      label: this._t("last_action_at"),
      sorter: true,
      ...overwrite.props,
    });
  }
  xp(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "xp",
      label: this._t("xp"),
      sorter: true,
      ...overwrite.props,
    });
  }
  partner_code(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "partner_code",
      label: this._t("partner_code"),
      sorter: true,
      ...overwrite.props,
    });
  }
  kyc_status(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "kyc_status",
      label: this._t("kyc_status"),
      sorter: true,
      render: (val) => `${val}`,
      ...overwrite.props,
    });
  }

  gender(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "gender",
      label: this._t("gender"),
      sorter: true,
      ...overwrite.props,
    });
  }

  version(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "version",
      label: this._t("version"),
      align: "right",
      sorter: true,
      render: (val) => val,
      ...overwrite.props,
    });
  }

  balance(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "balance",
      label: this._t("balance"),
      align: "right",
      sorter: true,
      render: (val) => (val / 100).toFixed(2),
      ...overwrite.props,
    });
  }

  amount(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "amount",
      label: this._t("amount"),
      align: "right",
      sorter: true,
      render: (val) => (val / 100).toFixed(2),
      ...overwrite.props,
    });
  }

  currency(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "currency",
      label: this._t("currency"),
      sorter: true,
      ...overwrite.props,
    });
  }

  phone_verified_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "phone_verified_at",
      label: this._t("phone_verified_at"),
      sorter: true,
      render: (val) => (val ? val : "-"),
      ...overwrite.props,
    });
  }

  birthday(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "birthday",
      label: this._t("birthday"),
      sorter: true,
      ...overwrite.props,
    });
  }

  email(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "email",
      label: this._t("email"),
      sorter: true,
      render: (val, item) => (
        <Link to={`/user/${item?.id}/profile`}>
          {val}
        </Link>
      ),
      ...overwrite.props,
    });
  }


  email_verified_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "email_verified_at",
      label: this._t("email_verified_at"),
      sorter: true,
      render: (val) => (val ? val : "-"),
      ...overwrite.props,
    });
  }
  login(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "login",
      label: this._t("login"),
      sorter: true,
      render: (val, item) => (
        <Link to={`/user/${item?.id}/profile`}>
          {val}
        </Link>
      ),
      ...overwrite.props,
    });
  }




  id(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "id",
      label: this._t("id"),
      sorter: true,
      ...overwrite.props,
    });
  }

  user_id(overwrite : TableType = {}) {
    return this.id({
      field: overwrite.field ||  "user_id",
      props: {
        label: this._t("user_id"),
        ...overwrite.props
      }
    });
  }

  brand_id(overwrite : TableType = {}) {
    return this.id({
      field: overwrite.field ||  "brand_id",
      props: {
        label: this._t("brand_id"),
        ...overwrite.props
      }
    });
  }



  uuid(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "uuid",
      label: this._t("uuid"),
      sorter: true,
      ...overwrite.props,
    });
  }

  provider_tx(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "provider_tx",
      label: this._t("provider_tx"),
      sorter: true,
      ...overwrite.props,
    });
  }


  key(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "key",
      label: this._t("key"),
      sorter: true,
      ...overwrite.props,
    });
  }

  translate(languages, overwrite : TableType = {}) {
    const path = overwrite.field || "translate.{locale}";
    return this.each(languages, (s, locale) => {
      return this.add({
        path: strf(path, {locale}),
        label: this._t("translate {locale}", { locale: locale }),
        // sorter: true,
        ...overwrite.props,
      });
    });
  }

  created_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "created_at",
      label: this._t("created_at"),
      sorter: true,
      ...overwrite.props,
    });
  }

  updated_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "updated_at",
      label: this._t("updated_at"),
      sorter: true,
      ...overwrite.props,
    });
  }

  deleted_at(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field ||  "deleted_at",
      label: this._t("deleted_at"),
      sorter: true,
      ...overwrite.props,
    });
  }

  action({ onShow = null, onEdit = null, onDelete = null }, overwrite : TableType = {}) {

    if (!onShow && !onEdit && !onDelete) {
      return this;
    }

    return this.add({
      label: this._t("action"),
      align: 'center',
      render: (item) => (
        <TableActionButtons
          onShow={onShow && (() => onShow(item))}
          onEdit={onEdit && (() => onEdit(item))}
          onDelete={onDelete && (() => onDelete(item))}
        />
      ),
      ...overwrite.props,
    });
  }

  __value(overwrite : TableType = {}) {
    return this.add({
      path: overwrite.field,
      label: this._t(overwrite.field),
      ...overwrite.props,
    });
  }


  x_json(path, title, overwrite : TableType = {}) {
    return this.add({
      label: title,
      path: overwrite.field || path,
      align: "right",
      // sorter: true,
      render: (val) => val && JSON.stringify(val),
      ...overwrite.props,
    });
  }

  x_custom(path: string, label: string, sorter: boolean, overwrite: TableType = {}) {
    return this.add({
      path: path,
      label: label,
      sorter: !! sorter,
      ...overwrite.props,
    });
  }

}
