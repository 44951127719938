import SystemAdmin from "routes/system/admin";
import SystemRoles from "routes/system/roles";
import SystemTranslateGlobal from "routes/system/translate_global";
import CommonUserSearch from "routes/common/user/search";
import CommonUserProfile from "routes/common/user/profile";
import CommonTranslate from "routes/common/translate/index";
import CommonContentPage from "routes/common/content/page";
import CommonContentBanner from "routes/common/content/banner";
import CommonContentFile from "routes/common/content/file";
import CommonContentImage from "routes/common/content/image";
import CommonContentNews from "routes/common/content/news";
import SystemAccount from "routes/global/system/account";
// import CommonSeriesTag from "routes/common/series/tag";
// import CommonSeriesCategory from "routes/common/series/category";
import SystemDashboard from "routes/system/dashboard";
import CommonNotaRound from "routes/common/nota/round";
import CommonNotaIssue from "routes/common/nota/issue";
import CommonNotaAuthor from "routes/common/nota/author";
import CommonNotaFeedback from "routes/common/nota/feedback";
import CommonNotaGamefile from "routes/common/nota/gamefile";
import CommonNotaCertificate from "routes/common/nota/certificate";

export const routesConfig = {
  dashboard: {
    path: "/dashboard",
    isAllowResource: "",
    component: SystemDashboard,
  },
  account: {
    path: "/account",
    isAllowResource: "",
    component: SystemAccount,
    params: {},
  },
  system_admin: {
    path: "/system/admin",
    isAllowResource: "admin:admin@index",
    component: SystemAdmin,
  },
  system_roles: {
    path: "/system/roles",
    isAllowResource: "role:role@index",
    component: SystemRoles,
  },
  system_translate_global: {
    path: "/system/translate_global",
    isAllowResource: "translate:translate_global@index",
    component: SystemTranslateGlobal,
  },
  translate: {
    path: "/translate",
    isAllowResource: "translate:translate@index",
    component: CommonTranslate,
  },
  user_search: {
    path: "/user/search",
    isAllowResource: "user:user_search@search",
    component: CommonUserSearch,
  },
  user_profile: {
    path: "/user/:user/profile",
    isAllowResource: "user:*",
    component: CommonUserProfile,
  },
  content_page: {
    path: "/content/page",
    isAllowResource: "content:page@index",
    component: CommonContentPage,
  },
  content_banner: {
    path: "/content/banner",
    isAllowResource: "content:banner@index",
    component: CommonContentBanner,
  },
  content_file: {
    path: "/content/file",
    isAllowResource: "content:file@index",
    component: CommonContentFile,
  },
  content_image: {
    path: "/content/image",
    isAllowResource: "content:image@index",
    component: CommonContentImage,
  },
  content_news: {
    path: "/content/news",
    isAllowResource: "content:news@index",
    component: CommonContentNews,
  },
  nota_issue: {
    path: "/nota/issue",
    isAllowResource: "nota:issue@index",
    component: CommonNotaIssue,
  },
  nota_round: {
    path: "/nota/round",
    isAllowResource: "nota:round@index",
    component: CommonNotaRound,
  },
  nota_author: {
    path: "/nota/author",
    isAllowResource: "nota:author@index",
    component: CommonNotaAuthor,
  },
  nota_feedback: {
    path: "/nota/feedback",
    isAllowResource: "nota:feedback@index",
    component: CommonNotaFeedback,
  },
  nota_gamefile: {
    path: "/nota/gamefile",
    isAllowResource: "nota:gamefile@index",
    component: CommonNotaGamefile,
  },
  nota_certificate: {
    path: "/nota/certificate",
    isAllowResource: "nota:certificate@index",
    component: CommonNotaCertificate,
  },

  // series_tag: {
  //   path: "/series/tag",
  //   isAllowResource: "series:tag@index",
  //   component: CommonSeriesTag,
  // },
  // series_category: {
  //   path: "/series/category",
  //   isAllowResource: "series:category@index",
  //   component: CommonSeriesCategory,
  // },
};
