import React from "react";
import { Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress";
import { useAuth } from "./Sidebar/UserProfile";
import { useGlobalTranslate } from "utils/translation/hooks";
import Logo from "components/atoms/logo";
import LogoContainer from "containers/components/atoms/logo";

const SignIn = () => {
  const { _t } = useGlobalTranslate();
  const { login, isLoading } = useAuth();

  const onFinish = (values) => login(values);
  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                {_t("welcome back")}
                {/*<IntlMessages id="app.userAuth.signIn" />*/}
              </h1>
            </div>
            <div className="gx-app-logo">
              <LogoContainer />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                initialValue=""
                rules={[
                  { required: true, message: _t("validation.required_email") },
                ]}
                name="email"
              >
                <Input placeholder={_t("email")} />
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[
                  { required: true, message: _t("validation.required_password") },
                ]}
                name="password"
              >
                <Input type="password" placeholder={_t("password")} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  {_t("signin")}
                </Button>
              </Form.Item>

              <span className="gx-text-light gx-fs-sm">
                {" "}
                {/*demo user: admin@admin.loc / password*/}
              </span>
            </Form>
          </div>

          {isLoading && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
