const projectEnv: string = process.env.NODE_ENV || "";
const developmentUrl = "http://localhost:3000";
let backendUrl: string = "";
const hostname = window.location.hostname;
const protocol = window.location.protocol;

switch (projectEnv) {
  case "development":
    backendUrl =
      developmentUrl ||
      `${protocol}//${hostname}`;
    break;
  case "stage":
    backendUrl = `${protocol}//${hostname}`;
    break;
  default:
    backendUrl = `${protocol}//${hostname}`;
    break;
}

export default backendUrl;
