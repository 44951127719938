import React from "react";
import { Button, ButtonProps } from "antd";

const Btn: React.FC<
  ButtonProps & { btnLabel: string; themeType: ButtonProps["type"] }
> = (props) => {
  const { btnLabel, themeType, ...restProps } = props;
  return (
    <Button {...restProps} type={themeType}>
      {props.btnLabel}
    </Button>
  );
};

export default Btn;
