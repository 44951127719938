import { useEffect, useMemo, useRef, useState } from "react";
import {
  listSort,
  UseFiltersResult,
  useListSortType,
} from "./types";
import isEqual from "lodash.isequal";
import { filterValues } from "./helperFunctions";
import { message, PaginationProps } from "antd";
import { defaultPage, defaultPageSize } from "./constants";
import { useGlobalTranslate } from "utils/translation/hooks";
import { useDrawer } from "services/drawer/hooks";
import { useModal } from "services/modal/hooks";
import dotProp from "dot-prop";
import { useCommonDownloadBySecretHref } from "components/atoms/common";
import {useApiBootstrapIndex} from "../../server/apiGeneratorHooks";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useOutsideClick = (ref: any) => {
  const [clickOutSide, setClick] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClick(true);
      } else {
        setClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return [clickOutSide];
};

export const useFilters = (initialFilters: any): UseFiltersResult => {
  const [filters, setFiltersNewFilters] = useState(initialFilters);
  const setFilters = (allFilters: any) => {
    const newFilters = isEqual(allFilters, filters) ? filters : allFilters;
    setFiltersNewFilters(newFilters);
  };

  return { filters, setFilters };
};

export const useClientTableFilterOldOldOld = (data: any, filters: any) =>
  useMemo(() => filterValues(data, filters), [data, filters]);

export const useClientTableFilter = (data: any, query: any) =>
  useMemo(() => filterValues20(data, query), [data, query]);

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export const filterValues20 = (data = [], query: any) => {
  let filtered = [...data];

  let order = { ...(query.order || {}) };
  let where = { ...(query.where || {}) };

  /**
   * ordering
   */
  const orderCases = {
    asc: (value1, value2) => (value1 > value2 ? 1 : -1),
    desc: (value1, value2) => (value1 < value2 ? 1 : -1),
  };

  for (const field in order) {
    const strategy = order[field];
    const callback = orderCases[strategy];
    filtered = filtered.sort((item1, item2) => {
      const value1 = dotProp.get(item1, field, "");
      const value2 = dotProp.get(item2, field, "");
      return callback ? callback(value1, value2) : 0;
    });
  }

  /**
   * filtering
   */
  const filterCases = {
    "@default": (search, item, value) => search == value,
    "@search": (search, item, value) => JSON.stringify(item).includes(search), // special full search
    ">": (search, item, value) => value > search,
    "<": (search, item, value) => value < search,
    ">=": (search, item, value) => value >= search,
    "<=": (search, item, value) => value <= search,
    "=": (search, item, value) => value == search,
    "in": (search, item, value) => value in search,
    "like": (search, item, value) => value.includes(search),
    "%like": (search, item, value) => value.includes(search),
    "like%": (search, item, value) => value.includes(search),
  };

  where = flattenObject(where);

  for (const field in where) {
    let search = where[field];
    let way = field;
    let rule = way.split(".").pop(); // translate.pt.like || translate.pt

    let callback = filterCases[rule]
      ? filterCases[rule]
      : filterCases["@default"]; // choose correct callback

    way = filterCases[rule] ? way.split(".").slice(0, -1).join(".") : way; // remove rule from way
    rule = filterCases[rule] ? rule : "@default"; // reset rule if needed

    // console.log(way, rule, search);

    filtered = filtered.filter((item) => {
      return callback(search, item, dotProp.get(item, way, ""));
    });
  }

  return filtered;
};

export const usePagination = (defaultPagination: PaginationProps) => {
  const [pagination, setPagination] = useState(defaultPagination);

  return { pagination, setPagination };
};
const defaultInitialSort: listSort = {};

export const useSort = (
  initialParams: listSort = defaultInitialSort
): useListSortType => {
  const [sortList, setSortList] = useState<listSort>(initialParams);
  const setSort = (sortParams) => {
    let sortList = {};
    if (!Array.isArray(sortParams)) {
      if (sortParams.order) {
        sortList[sortParams.field] = sortParams.order.split("end")[0];
      }
    } else if (Array.isArray(sortParams)) {
      sortParams.forEach((item) => {
        if (item.order) {
          sortList[item.field] = item.order.split("end")[0];
        }
      });
    }
    setSortList(sortList);
  };
  return { sortList, setSort };
};

export const useTableOnChange = (
  defaultPagination = {
    defaultPageSize,
    defaultPage,
  },
  defaultSortProps = {}
) => {
  const { pagination, setPagination } = usePagination({
    pageSize: defaultPagination.defaultPageSize,
    current: defaultPagination.defaultPage,
  });
  const { sortList, setSort } = useSort(defaultSortProps);

  const onTableChange = (
    pagination: PaginationProps,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === "sort") {
      setSort(sorter);
    } else {
      setPagination(pagination);
    }
  };

  return {
    pagination,
    sortList,
    onTableChange,
    setPagination,
    setSort,
    query: {
      page: pagination.current,
      per_page: pagination.pageSize,
      order: sortList,
    },
  };
};

export const useRolesOptions = (options: string[]) => {
  return useMemo(() => {
    const result = {};
    options &&
      options?.forEach((item) => {
        const key = item.split(":")[0];
        result[key] = !result[key]
          ? [{ label: item, value: item }]
          : [...result[key], { label: item, value: item }];
      });
    return result;
  }, [options]);
};

export const useTableQuery = ({ defaults = {} }) => {
  const filter = useFilters(defaults || {});
  const change = useTableOnChange();

  return { filter, change, query: { ...change.query, ...filter.filters } };
};

export const useCommonDownload = (target, query = {}) => {
  const { _t } = useGlobalTranslate();
  const download = useCommonDownloadBySecretHref();

  if (!target || !query) {
    return null;
  }

  return async () => {
    const hide = message.loading({ content: _t("download..."), duration: 3 });
    await download(target, query);
    hide();
  };
};

export const useCommonComponentCrud = ({
  props = {},
  componentShow = null,
  componentStore = null,
  componentUpdate = null,
  componentDestroy = null,
  componentUpload = null,
  componentDownload = null,
}) => {
  const { _t } = useGlobalTranslate();

  const downloadAction = useCommonDownload(componentDownload?.target, componentDownload?.query);
  const drawer = useDrawer();
  const modal = useModal();

  const onCreate = () =>
    drawer.showDrawer({
      title: _t("create"),
      componentProps: { drawer, ...props },
      component: componentStore,
    });

  const onEdit = (item) =>
    drawer.showDrawer({
      title: _t("edit"),
      componentProps: { drawer, ...props, item },
      component: componentUpdate,
    });

  const onDelete = (item) =>
    modal.showModal({
      title: _t("delete"),
      componentProps: { modal, ...props, item },
      component: componentDestroy,
    });

  const onShow = (item) =>
    drawer.showDrawer({
      title: _t("show"),
      componentProps: { drawer, ...props, item },
      component: componentShow,
    });

  const onUpload = () =>
    modal.showModal({
      title: _t("upload"),
      componentProps: { modal, ...props },
      component: componentUpload,
    });

  const onDownload = () =>
      downloadAction();

  return {
    onCreate: componentStore && onCreate,
    onEdit: componentUpdate && onEdit,
    onDelete: componentDestroy && onDelete,
    onShow: componentShow && onShow,
    onUpload: componentUpload && onUpload,
    onDownload: componentDownload && onDownload,
  };
};



export const useBootstrap = (way) => {

  const index = useApiBootstrapIndex({staleTime: 60 * 60 * 24});

  if (index.data) {
    localStorage.setItem("bootstrap", JSON.stringify(index.data));
  }


  let raw = localStorage.getItem("bootstrap");
  let json = JSON.parse(raw);

  return {
    ...json[way]
  };
};