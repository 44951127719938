import React from "react";

/**
 *
 * @constructor
 */
const SystemDashboard = () => {
  return (
    <div>todo dashboard</div>
  );
};

export default SystemDashboard;
