import Structure, {strf} from "./Structure";
import { Input, Select } from "antd";
import Btn from "components/atoms/button";
import AmountInput from "components/atoms/amountInput";
import DatePicker from "components/atoms/datePicker";
import {arrayOptionsMap, objectOptionsMap} from "../../../utils/helpers/helperFunctions";

const size = {
  25: { xs: 24 / 4, md: 12 / 4 },
  50: { xs: 24 / 2, md: 12 / 2 },
  100: { xs: 24, md: 12 },
};



//need to beautify
const greaterValidationCheck = (fromName, toName, errorMessage) =>
    ({getFieldValue, validateFields, getFieldsError}) => ({

      validator(rule, value, callback) {
        let from = getFieldValue(fromName);
        let to = getFieldValue(toName);

        // catch dates: "2022-10-10 11:11:11"
        // catch strings: "123"
        if (typeof from === 'string' && from !== '') {
          from = from.replace(/[^\d.]/g, '');
          from = parseFloat(from);
        }

        if (typeof to === 'string' && to !== '') {
          to = to.replace(/[^\d.]/g, '');
          to = parseFloat(to);
        }


        try {
          if (!!to && !!from && from > to) {
            return Promise.reject(new Error(errorMessage));
          }
          if (rule.field === toName) {
            const fromErr = getFieldsError([fromName]);
            if (fromErr[0].errors.length) {
              validateFields([fromName]);
            }
          } else {
            const toErr = getFieldsError([toName]);
            if (toErr[0].errors.length) {
              validateFields([toName]);
            }
          }
          return Promise.resolve();
        } catch (err) {
          callback(err);
        }
      },
    });


const filterSelectOptions = (input, option) =>
      option.props?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
;

export type TableFilterPropsType = {
  [key: string]: any;
}

export type TableFilterType = {
  field?: string;
  props?: TableFilterPropsType;
  fromProps?: TableFilterPropsType;
  toProps?: TableFilterPropsType;
};

export default class StructureTableFilter extends Structure {

  currency(constantIndex, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'currency', {
      label: this._t('currency'),
      options : objectOptionsMap(constantIndex.data?.currency, true),
      ...overwrite.props || {}
    });
  }

  phone_verified_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'phone_verified_at',
        {label: this._t('phone_verified_at_from'), ...overwrite.props, ...overwrite.fromProps},
        {label: this._t('phone_verified_at_to'), ...overwrite.props, ...overwrite.toProps},
        true
      );
  }

  email_verified_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'email_verified_at',
      {label: this._t('email_verified_at_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('email_verified_at_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  last_action_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'last_action_at',
      {label: this._t('last_action_at_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('last_action_at'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  created_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'created_at',
      {label: this._t('created_at_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('created_at_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  updated_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'updated_at',
      {label: this._t('updated_at_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('updated_at_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  deleted_at(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'deleted_at',
      {label: this._t('deleted_at_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('deleted_at_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  show_from(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'show_from',
      {label: this._t('show_from_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('show_from_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  show_to(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'show_to',
      {label: this._t('show_to_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('show_to_to'), ...overwrite.props, ...overwrite.toProps},
      true
    );
  }

  birthday(overwrite : TableFilterType = {}) {
    return this.__between_date(overwrite.field || 'birthday',
      {label: this._t('birthday_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('birthday_to'), ...overwrite.props, ...overwrite.toProps},
      false
    );
  }

  xp(overwrite : TableFilterType = {}) {
    return this.__between_number(overwrite.field || 'xp',
      {label: this._t('xp_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('xp_to'), ...overwrite.props, ...overwrite.toProps}
    );
  }


  amount(overwrite : TableFilterType = {}) {
    return this.__between_money(overwrite.field || 'amount',
      {label: this._t('amount_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('amount_to'), ...overwrite.props, ...overwrite.toProps}
    );
  }


  balance(overwrite : TableFilterType = {}) {
    return this.__between_money(overwrite.field || 'balance',
      {label: this._t('balance_from'), ...overwrite.props, ...overwrite.fromProps},
      {label: this._t('balance_to'), ...overwrite.props, ...overwrite.toProps}
    );
  }
  
  method_gate(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `gate`, {
      label: this._t("method_gate"),
      ...overwrite.props
    });
  }

  method_gate_key(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `gate_key`, {
      label: this._t("method_gate_key"),
      ...overwrite.props
    });
  }

  type(options, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'type', {
      label: this._t('type'),
      options : options,
      mode: 'multiple',
      ...overwrite.props
    });
  }

  status(options, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'status', {
      label: this._t('status'),
      options : options,
      ...overwrite.props
    });
  }

  game_provider(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `provider`, {
      label: this._t("game_provider"),
      ...overwrite.props
    });
  }
  game_runner(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `runner`, {
      label: this._t("game_runner"),
      ...overwrite.props
    });
  }
  game_runner_key(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `runner_key`, {
      label: this._t("game_runner_key"),
      ...overwrite.props
    });
  }

  title_string(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `title`, {
      label: this._t("title"),
      ...overwrite.props
    });
  }

  text_string(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `text`, {
      label: this._t("text"),
      ...overwrite.props
    });
  }


  name(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `name`, {
      label: this._t("name"),
      ...overwrite.props
    });
  }

  namespace(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `namespace`, {
      label: this._t("namespace"),
      ...overwrite.props
    });
  }

  slug(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `slug`, {
      label: this._t("slug"),
      ...overwrite.props
    });
  }

  token(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `token`, {
      label: this._t("token"),
      ...overwrite.props
    });
  }
  
  active(overwrite : TableFilterType = {}) {
    return this.__select_single(overwrite.field || 'active', {
      label: this._t('active'),
      options : [
        {label: "true", value: 1},
        {label: "false", value: 0},
      ],
      ...overwrite.props
    });
  }

  key(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `key`, {
      label: this._t("key"),
      ...overwrite.props
    });
  }

  nickname(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `nickname`, {
      label: this._t("nickname"),
      ...overwrite.props
    });
  }

  uuid(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `uuid`, {
      label: this._t("uuid"),
      ...overwrite.props
    });
  }

  first_name(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `first_name`, {
      label: this._t("first_name"),
      ...overwrite.props
    });
  }

  last_name(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `last_name`, {
      label: this._t("last_name"),
      ...overwrite.props
    });
  }

  full_name(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `full_name`, {
      label: this._t("full_name"),
      ...overwrite.props
    });
  }

  provider_tx(overwrite : TableFilterType = {}) {
    return this.__input_like(overwrite.field || `provider_tx`, {
      label: this._t("provider_tx"),
      ...overwrite.props
    });
  }
  
  search_by_data(overwrite : TableFilterType = {}) {
    return this.add({
      component: Input,
      path: `where.search.@search`,
      props: {
        label: this._t("search"),
        placeholder: this._t("search"),
        colParams: size[50],
        ...overwrite.props
      },
    });
  }

  login(overwrite : TableFilterType = {}) {
    return this.__input_like( overwrite.field || `login`, {
      label: this._t("login"),
      ...overwrite.props
    });
  }

  partner_code(overwrite : TableFilterType = {}) {
    return this.__input_like( overwrite.field || `partner_code`, {
      label: this._t("partner_code"),
      ...overwrite.props
    });
  }

  email(overwrite : TableFilterType = {}) {
    return this.__input_like( overwrite.field || `email`, {
      label: this._t("email"),
      ...overwrite.props
    });
  }

  phone(overwrite : TableFilterType = {}) {
    return this.__input_like( overwrite.field || `phone`, {
      label: this._t("phone"),
      ...overwrite.props
    });
  }

  id(overwrite : TableFilterType = {}) {
    return this.__select_tags(overwrite.field || 'id', {
      label: this._t('id'),
      ...overwrite.props
    });
  }

  user_id(overwrite : TableFilterType = {}) {
    return this.__select_tags(overwrite.field || 'user_id', {
      label: this._t('user_id'),
      ...overwrite.props
    });
  }

  brand_id(overwrite : TableFilterType = {}) {
    return this.__select_tags(overwrite.field || 'brand_id', {
      label: this._t('brand_id'),
      ...overwrite.props
    });
  }

  round_id(roundIndex, overwrite : TableFilterType = {}) {
    return this.__select_single(overwrite.field || 'round_id', {
      label: this._t('round_id'),
      options : arrayOptionsMap(roundIndex?.data, {labelKey: 'title', valueKey: 'id'}),
      ...overwrite.props
    });
  }

  country(constantIndex, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'country', {
      label: this._t('country'),
      options : objectOptionsMap(constantIndex.data?.country, true),
      ...overwrite.props
    });
  }

  locale(constantIndex, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'locale', {
      label: this._t('locale'),
      options : objectOptionsMap(constantIndex.data?.language, true),
      ...overwrite.props
    });
  }

  role_id(roleIndex, overwrite : TableFilterType = {}) {
    return this.__select_multiple(overwrite.field || 'role_id', {
      label: this._t('role_id'),
      options: arrayOptionsMap(roleIndex?.data, {labelKey: 'name', valueKey: 'id'}),
      ...overwrite.props
    });
  }

  kyc_status(overwrite : TableFilterType = {}) {
    return this.__select_single(overwrite.field || 'kyc_status', {
      label: this._t('kyc_status'),
      options : [
        {label: "true", value: 1},
        {label: "false", value: 0},
      ],
      ...overwrite.props
    });
  }

  gender(overwrite : TableFilterType = {}) {
    return this.__select_single(overwrite.field || 'gender', {
      label: this._t('gender'),
      options: [
        { label: "male", value: 'male' },
        { label: "female", value: 'female' },
      ],
      ...overwrite.props
    });
  }

  translate(languages, overwrite : TableFilterType = {}, strategy = "like") {
    return this.each(languages, (s: this, locale) => {
      return s.__input_like( (overwrite.field || 'translate') + `.${locale}`, {
        label: s._t("translate {locale}", { locale: locale }),
        ...overwrite.props
      });
    });
  }

  btn_reset(props : TableFilterPropsType = {}) {
    return this.__btn("reset",{
      btnLabel: this._t("reset"),
      ...props
    });
  }

  btn_submit(props : TableFilterPropsType = {}) {
    return this.__btn("submit",{
      btnLabel: this._t("submit"),
      htmlType: 'submit',
      ...props
    });
  }

  btns_submit_reset(submitProps : TableFilterPropsType = {}, resetProps : TableFilterPropsType = {}) {
    return this.group((s: this) => {
      s.btn_submit(submitProps);
      s.btn_reset(resetProps);
    });
  }

  // predefined ======================================================

  __btn(type, props: TableFilterPropsType = {}){
    return this.add({
      component: Btn,
      type: type,
      props: {
        themeType: "primary",
        noStyle: true,
        ...props,
      },
    });
  }

  __select_single(field, props: TableFilterPropsType = {}) {
    return this.add({
      component: Select,
      path: `where.${field}`,
      props: {
        allowClear: true,
        colParams: size[50],
        filterOption: filterSelectOptions,
        ...props,
      },
    });
  }

  /**
   *
   * @param field
   * @param props
   */
  __select_multiple(field, props: TableFilterPropsType = {}) {
    return this.add({
      component: Select,
      path: `where.${field}.in`,
      props: {
        allowClear: true,
        colParams: size[50],
        mode: 'multiple',
        filterOption: filterSelectOptions,
        ...props,
      },
    });
  }

  __select_tags(field, props: TableFilterPropsType = {}) {
    return this.add({
      component: Select,
      path: `where.${field}.in`,
      props: {
        allowClear: true,
        colParams: size[50],
        mode: 'tags',
        ...props,
      },
    });
  }

  __input_like(field, props: TableFilterPropsType = {}, strategy = 'like') {
    return this.add({
        component: Input,
        path: `where.${field}.${strategy}`,
        props: {
          colParams: size[50],
          allowClear: true,
          ...props,
        }
    });
  }


  __between_number(field, fromProps: TableFilterPropsType = {}, toProps: TableFilterPropsType = {}, ) {

    const decoratorProps = {
      colParams: size[25],
      type: 'number',
    };

    return this.__between(Input, field, {...fromProps, ...decoratorProps}, {...toProps, ...decoratorProps});
  }

  __between_money(field, fromProps: TableFilterPropsType = {}, toProps: TableFilterPropsType = {}, ) {

    const decoratorProps = {
      colParams: size[25],
    };

    return this.__between(AmountInput, field, {...fromProps, ...decoratorProps}, {...toProps, ...decoratorProps});
  }

  __between_date(field, fromProps: TableFilterPropsType = {}, toProps: TableFilterPropsType = {}, showTime = true) {

    const decoratorProps = {
      // placeholder: '2022-10-10 01:01:01',
      showTime: showTime,
      colParams: showTime ? size[50] : size[25],
      style: {width: "100%"},
    };

    return this.__between(DatePicker, field, {...fromProps, ...decoratorProps}, {...toProps, ...decoratorProps});
  }

  __between(component, field, fromProps: TableFilterPropsType = {}, toProps: TableFilterPropsType = {}, ) {

    const path = `where.${field}.{strategy}`;
    const pack = [
      { strategy: ">=", props: fromProps },
      { strategy: "<=", props: toProps },
    ];

    return this.each(pack, (s: this, params, options, idx) => {
      return s.add({
        component: component,
        path: strf(path, {strategy: params.strategy}),
        props: {
          colParams: size[50],
          allowClear: true,
          rules: [
            greaterValidationCheck(
              strf(path, {strategy: pack[0].strategy}),
              strf(path, {strategy: pack[1].strategy}),
              this._t('from_to_error')
            )
          ],
          ...params.props,
        }
      });
    });
  }


}
