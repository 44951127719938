import React from "react";
import { Avatar, Popover, Space, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useApiAuthDestroy } from "server/apiGeneratorHooks";
import { UserOutlined } from "@ant-design/icons";
import { useCurrentAdmin } from "fragmentedStore";
import { useGlobalTranslate } from "utils/translation/hooks";
import { useDrawer } from "services/drawer/hooks";
import Customizer from "containers/Customizer";
const { Title } = Typography;

const UserInfo = () => {
  const { _t } = useGlobalTranslate();
  const drawer = useDrawer();
  const token = localStorage.getItem("x-admin-token");
  const [currentAdmin] = useCurrentAdmin();
  const history = useHistory();
  const apiLogout = useApiAuthDestroy(token);
  const onLogout = () => {
    apiLogout.mutate(null);
    history.push("/signin");
  };
  const showSettings = () => {
    drawer.showDrawer({
      title: _t("theme"),
      componentProps: { drawer },
      component: Customizer,
    });
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link className={"gx-link"} to="/account">{_t("my_account")}</Link></li>
      <li onClick={showSettings}>{_t("theme_settings")}</li>
      <li onClick={onLogout}>{_t("logout")}</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal 1"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Space>
        {/*<Avatar*/}
        {/*  icon={<UserOutlined />}*/}
        {/*  size={28}*/}
        {/*  className="gx-avatar gx-pointer"*/}
        {/*  alt=""*/}
        {/*/>*/}
        <div className={"gx-fs-md gx-pointer"}>{currentAdmin?.email}</div>
      </Space>
    </Popover>
  );
};

export default UserInfo;
