import React from "react";
import { useGlobalTranslate } from "utils/translation/hooks";
import { Empty } from "antd";
import {
  useApiUserShow,
} from "server/apiGeneratorHooks";
import {
  Button,
  Card,
  Descriptions,
} from "antd";
import ContainerHeader from "components/ContainerHeader";
import Spinner from "components/atoms/spinner";




const ProfileShow = ({ user }) => {
  const { _t } = useGlobalTranslate();

  return (
    <Descriptions column={2} bordered size={"small"}>
      <Descriptions.Item label={_t("id")}>{user.id}</Descriptions.Item>
      <Descriptions.Item label={_t("full_name")}>{user.full_name}</Descriptions.Item>
      <Descriptions.Item label={_t("phone")}>{user.phone}</Descriptions.Item>
      <Descriptions.Item label={_t("email")}>{user.email}</Descriptions.Item>
      <Descriptions.Item label={_t("country")}>{user.country}</Descriptions.Item>
      <Descriptions.Item label={_t("city")}>{user.city}</Descriptions.Item>
      <Descriptions.Item label={_t("organization")}>{user.organization}</Descriptions.Item>
      <Descriptions.Item label={_t("appointment")}>{user.city}</Descriptions.Item>
      <Descriptions.Item label={_t("created_at")}>{user.created_at}</Descriptions.Item>
      <Descriptions.Item label={_t("updated_at")}>{user.updated_at}</Descriptions.Item>
      <Descriptions.Item label={_t("deleted_at")}>{user.deleted_at}</Descriptions.Item>
    </Descriptions>
  );
};

/**
 *
 * @constructor
 */
const CommonUserProfile = ({ match }) => {
  const { _t } = useGlobalTranslate();
  const userId = match.params.user;

  const apiUser = useApiUserShow(userId);

  // TODO thinkink
  if (apiUser.isFetching && !apiUser.isRefetching) {
    return <Spinner />;
  }

  // TODO thinkink
  if (apiUser.error) {
    return <Empty description={_t("no_data")} />;
  }

  return (
    <>
      <Card
        size={"small"}
        title={
          <ContainerHeader
            title={apiUser.data?.email}
          />
        }
        extra={
          <>
            <Button disabled size={"small"}>{_t("btn")}</Button>
          </>
        }
      >
        {apiUser.data && <ProfileShow user={apiUser.data} />}
      </Card>

    </>
  );
};

export default CommonUserProfile;
