import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DrawerTypes, DrawerParams } from "./types";
import { Drawer as AntdDrawer } from "antd";
import DrawerLabel from "components/atoms/drawerLabel";

const Drawer: React.FC<DrawerTypes> = forwardRef(({ hideDrawer }, ref) => {
  const [drawerVisible, setVisible] = useState({});
  const [drawerFocus, setFocus] = useState("default");
  const [drawerParams, setDrawerParams] = useState<DrawerParams>({});
  const size = {
    sm: { width: "30vw" },
    m: { width: "50vw" },
    l: { width: "80vw" },
  };

  useImperativeHandle(ref, () => ({
    drawerOpen(name: any, param: any) {
      setVisible({ ...drawerVisible, [name]: true });
      setFocus(name);
      setDrawerParams({ ...drawerParams, [name]: param });
    },
    drawerUpdate(name: any, param: any) {
      setDrawerParams({
        ...drawerParams,
        [name]: { ...param[name], ...param },
      });
    },
    drawerClose(name: any) {
      const params = { ...drawerParams };
      delete params[name];
      setDrawerParams(params);
      setVisible({ ...drawerVisible, [name]: false });
    },
  }));

  const checkFocus = (name) => {
    if (name !== drawerFocus) {
      setFocus(name);
    }
  };
  console.log("drawerParams", drawerParams);
  return (
    <>
      {Object.keys(drawerVisible).map((name, idx) => {
        const {
          component: Content,
          componentProps,
          body,
          drawerFooter,
          className,
          style,
          title,
          drawerSize = "m",
        } = drawerParams?.[name] || {};

        return (
          <>
            <AntdDrawer
              autoFocus={false}
              key={name}
              width={800}
              style={{
                ...style,
                zIndex: name === drawerFocus ? 900 : 800,
              }}
              onClose={() => hideDrawer(name)}
              visible={drawerVisible?.[name]}
              title={title}
              maskClosable={true}
            >
              <div className={"gx-p-3"}>
                {Content ? <Content {...componentProps} /> : body}
                <div
                  className={"drawer-label__wrap"}
                  style={{
                    right: 800,
                  }}
                >
                  {Object.keys(drawerVisible).map((drawerName, idx1) => {
                    if (!Object.values(drawerVisible)[idx1]) {
                      return null;
                    }
                    if (!!drawerParams?.[drawerName]?.labelTitle) {
                      return (
                        <DrawerLabel
                          key={"label" + drawerName}
                          onClick={() => checkFocus(drawerName)}
                          selected={drawerName === drawerFocus}
                          title={drawerParams?.[drawerName]?.labelTitle}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </AntdDrawer>
          </>
        );
      })}
    </>
  );
});

export default Drawer;
