import React from "react";
import {
  useApiAdminShow,
  useApiAdminUpdate,
  useApiRoleIndex,
} from "server/apiGeneratorHooks";
import { useGlobalTranslate } from "utils/translation/hooks";
import { arrayOptionsMap } from "utils/helpers/helperFunctions";
import { useCurrentAdmin } from "fragmentedStore";
import { getFormPredefined } from "components/atoms/predefined";
import CustomForm from "services/customForm";
import { useGenerateSchema } from "services/customForm/hooks";
import { Card } from "antd";
import ContainerHeader from "components/ContainerHeader";
import Spinner from "components/atoms/spinner";

const useCurrentFormPredefined = () => {
  const { _t } = useGlobalTranslate();
  const variants = getFormPredefined(_t);

  const roles = useApiRoleIndex({ staleTime: 60 * 60 });
  const rolesOption = arrayOptionsMap(roles?.data);

  return [
    variants.name(),
    variants.email(),
    variants.password(),
    variants.role_id({ hidden: true }, { select: rolesOption }),
    variants.active({ hidden: true }),
    variants.settings(),
  ];
};
const formLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const SystemAccount = () => {
  const { _t } = useGlobalTranslate();
  const [currentAdmin] = useCurrentAdmin();
  const configForm = useCurrentFormPredefined();
  const apiUpdate = useApiAdminUpdate(currentAdmin?.id);
  const apiShow = useApiAdminShow(currentAdmin?.id, {
    enabled: apiUpdate.isIdle,
  });
  const schema = useGenerateSchema(configForm, {}, apiShow.data || {});
  if (apiShow.isLoading) {
    return <Spinner />;
  }

  return (
    <Card
      size={"small"}
      title={<ContainerHeader title={_t("menu.account_title")} />}
    >
      <CustomForm
        {...formLayout}
        resErrors={apiUpdate.error?.errorData?.errors}
        onSubmit={apiUpdate.mutate}
        schema={schema}
      />
    </Card>
  );
};

export default SystemAccount;
