import React, { useEffect, useMemo } from "react";
import { Form, Tabs, Badge, Button } from "antd";
import { useGetFormErrors, useResErrors } from "services/customForm/hooks";
import { divideOnTabs, getFormInitialValues } from "./helper";
import RowWrap from "./RowWrap";
import ColWrap from "./ColWrap";
import { useGlobalTranslate } from "utils/translation/hooks";
import { cleanEmptyValFromObj } from "utils/helpers/helperFunctions";
const { TabPane } = Tabs;

const CustomForm: React.FC<any> = ({
  formName = "form",
  tabs = [],
  schema,
  onSubmit,
  resErrors,
  formRef,
  buttonsProps = {
    reset: true,
    submit: true,
  },
  ...formProps
}) => {
  const { _t } = useGlobalTranslate();
  const [form] = Form.useForm();
  const { setErrors, tabError, hasErrors } = useGetFormErrors(tabs);
  useResErrors(resErrors, form, setErrors);
  const modifiedSchema = divideOnTabs(schema, tabs);
  const checkHidden = (formItem: any) => {
    if (formItem.hidden) {
      return false;
    }
  };
  const formInitialValues = useMemo(
    () => getFormInitialValues(modifiedSchema),
    [modifiedSchema]
  );
  const onFieldsChange = (values: any, errorFields: any) => {
    const errors = errorFields.map(({ name, errors }: any) => ({
      name,
      errors,
    }));
    console.log("onFieldsChange", values);
    setErrors(errors);
  };
  useEffect(() => {
    if (resErrors) {
      const formErrors: any = [];
      Object.entries(resErrors).forEach((key, index) => {
        formErrors.push({ name: key[0].split("."), errors: key[1] });
      });
      setErrors(formErrors);
      form && form.setFields(formErrors);
    }
  }, [resErrors]);
  const onFinish = (values: any) => {
    console.log("onFinish", values);
    const filteredData = cleanEmptyValFromObj(values);
    onSubmit(filteredData);
  };
  const onReset = () => {
    form.resetFields();
    setErrors([]);
  };
  const modifyFieldName = (name: string) => name.split(".");

  return (
    <Form
      ref={formRef}
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ ...formInitialValues }}
      onFieldsChange={onFieldsChange}
      {...formProps}
    >
      <RowWrap isRow={!formProps.wrapperCol} gutter={[12, 8]}>
        {modifiedSchema?.map((item: any, idx: number) => {
          if (!item) {
            return "not valid component, set up correct form schema!!!";
          }
          const {
            component: Component,
            componentProps = {},
            colParams = {},
            ...props
          } = item;
          checkHidden(item);
          if (!!item.tab) {
            return false;
          }

          return (
            <ColWrap
              key={"modifiedSchema" + idx}
              isCol={!formProps.wrapperCol}
              {...colParams}
            >
              <Form.Item
                key={item.name + idx}
                {...props}
                name={modifyFieldName(item.name)}
              >
                <Component {...componentProps} />
              </Form.Item>
            </ColWrap>
          );
        })}
      </RowWrap>
      {tabs && tabs?.length ? (
        <Tabs>
          {tabs?.map((tabKey: string, idx) => {
            return (
              <TabPane
                forceRender
                tab={
                  <>
                    {tabKey.toUpperCase()}
                    {tabError?.[tabKey] && <Badge dot={true} />}
                  </>
                }
                key={tabKey + idx}
              >
                {modifiedSchema.map((item: any, idx: number) => {
                  const {
                    component: Component,
                    colParams = {},
                    ...props
                  } = item;
                  checkHidden(item);
                  if (item.tab === tabKey) {
                    return (
                      <ColWrap
                        key={"modifiedSchema" + tabKey + idx}
                        isCol={!formProps.wrapperCol}
                        {...colParams}
                      >
                        <Form.Item
                          key={item.name + item.tab + idx}
                          {...props}
                          name={modifyFieldName(item.name)}
                        >
                          <Component {...props} />
                        </Form.Item>
                      </ColWrap>
                    );
                  }
                })}
              </TabPane>
            );
          })}
        </Tabs>
      ) : null}
      {hasErrors && (
        <div
          role="alert"
          style={{ color: "#f5222d" }}
          className="ant-form-item-explain-error"
        >
          {_t("form_has_errors")}
        </div>
      )}

      <div style={{ marginTop: "12px", marginBottom: "8px" }}>
        {buttonsProps?.submit && (
          <Button type="primary" htmlType="submit">
            {buttonsProps?.submitTitle || _t("submit")}
          </Button>
        )}
        {buttonsProps?.reset && (
          <Button type="primary" htmlType="submit" onClick={onReset}>
            {buttonsProps?.resetTitle || _t("reset")}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default CustomForm;
