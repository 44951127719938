import React from "react";
import {
  useApiAdminIndex,
  useApiAdminShow,
  useApiAdminStore,
  useApiAdminUpdate,
  useApiAdminDestroy,
  useApiRoleIndex,
} from "server/apiGeneratorHooks";
import {useCommonComponentCrud, useTableQuery} from "utils/helpers/hooks";
import {useGlobalTranslate} from "utils/translation/hooks";
import {
  CommonStructureDestroy, CommonStructureIndex, CommonStructureUpset,
} from "components/atoms/common";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import StructureTable from "components/atoms/structure/StructureTable";
import StructureForm from "components/atoms/structure/StructureForm";
import {useIsAdminAllow} from "services/rolesRestrictions/hooks";

/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({onShow = null, onEdit = null, onDelete = null}) => {
  const {_t} = useGlobalTranslate();
  const structureTableFilter = new StructureTableFilter({_t: _t});
  const structureTable = new StructureTable({_t: _t});

  const roleIndex = useApiRoleIndex({staleTime: 60 * 60});

  structureTableFilter
    .id()
    .name()
    .email()
    .role_id(roleIndex)
    .active()
    .created_at()
    .updated_at()
    .deleted_at()
    .btns_submit_reset();


  structureTable
    .id()
    .name()
    .email()
    .role_id(roleIndex)
    .settings()
    .active()
    .created_at()
    .updated_at()
    .deleted_at()
    .action({onShow, onEdit, onDelete});

  return {structureTableFilter, structureTable};
};

/**
 *
 * @param item
 */
const useForm = ({item = null}) => {
  const {_t} = useGlobalTranslate();
  const structureForm = new StructureForm({_t: _t});

  const roleIndex = useApiRoleIndex({staleTime: 60 * 60});

  structureForm
    // .id()
    .name()
    .email()
    .password()
    .role_id(roleIndex)
    .settings()
    .active()
    .btns_submit_reset()
  ;

  return structureForm;
};

/**
 *
 * @param drawer
 * @constructor
 */
const Store = ({drawer}) => {
  const structureForm = useForm({});
  const apiStore = useApiAdminStore();

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiStore}
      structureForm={structureForm}
      data={{}}
      loading={apiStore.isLoading}
    />
  );
};

/**
 *
 * @param drawer
 * @param item
 * @constructor
 */
const Update = ({drawer, item}) => {
  const structureForm = useForm({item});
  const apiUpdate = useApiAdminUpdate(item.id);
  const apiShow = useApiAdminShow(item.id, {enabled: apiUpdate.isIdle});

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiUpdate}
      structureForm={structureForm}
      data={apiShow.data}
      loading={apiShow.isFetching}
    />
  );
};

/**
 *
 * @param modal
 * @param item
 * @constructor
 */
const Destroy = ({modal, item}) => {
  const apiDestroy = useApiAdminDestroy(item.id);

  return (
    <CommonStructureDestroy
      modal={modal}
      mutate={apiDestroy}
      data={item}
      structure={{}}
      loading={apiDestroy.isLoading}
    />
  );
};

/**
 *
 * @constructor
 */
const SystemAdmin = () => {
  const {_t} = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = 'admin:admin';

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    componentStore: isAllow(prefix + '@store') && Store,
    componentUpdate: isAllow(prefix + '@update') && Update,
    componentDestroy: isAllow(prefix + '@destroy') && Destroy,
    // componentUpload: isAllow(prefix + '@upload') && Upload,
    // componentDownload: isAllow(prefix + '@*') && {},
  });

  const apiIndex = useApiAdminIndex({query: tableQuery.query});
  const {structureTableFilter, structureTable} = useTable({onShow: null, onEdit: crud.onEdit, onDelete: crud.onDelete});

  return (
    <CommonStructureIndex
      title={_t("menu.system.admin")}
      source={apiIndex}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={null}
      onDownload={null}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default SystemAdmin;
