import React, { useState } from "react";
import { Avatar, message, Popover, Tooltip } from "antd";
import {
  useCurrentAdmin,
  useEnterPage,
} from "fragmentedStore";
import {
  useApiAuthDestroy,
  useApiAuthStore,
  useApiProfileIndex,
} from "server/apiGeneratorHooks";

export const useAuth = () => {
  const lsKey = "x-admin-token";

  const onLoginSuccess = (data) => {
    localStorage.setItem(lsKey, data.access_token.token);
    setCurrentAdmin(data.profile);
  };

  const onLogoutSuccess = (data) => {
    localStorage.removeItem(lsKey);
    setCurrentAdmin(null);
  };

  const onProfileSuccess = (data) => {
    const profile = data.profile;
    setCurrentAdmin(profile);
  };

  const [token, setToken] = useState(undefined);
  const [enterPage, setEnterPage] = useEnterPage();
  const [currentAdmin, setCurrentAdmin] = useCurrentAdmin();
  const { mutate: mutateLogin, isLoading: isLoadingLogin } = useApiAuthStore();
  const { mutate: mutateLogout, isLoading: isLoadingLogout } =
    useApiAuthDestroy(token);
  const {
    data: profileData,
    refetch: refetchProfile,
    isLoading: isLoadingProfile,
  } = useApiProfileIndex({
    enabled: false,
    onSuccess: onProfileSuccess,
    onError: () => {
      localStorage.removeItem(lsKey);
      message && message.error("profile fail");
    },
  });

  if (token === undefined) {
    // NOTE check for undefined
    setToken(localStorage.getItem(lsKey)); // will setup null if not find
  }

  const login = (values) => {
    mutateLogin(values, {
      onSuccess: onLoginSuccess,
      onError: () => message && message.error("login fail"),
    });
  };

  const logout = () => {
    mutateLogout(null, {
      onSuccess: onLogoutSuccess,
      onError: () => message && message.error("logout fail"),
    });
  };

  const profile = () => {
    if (!token || currentAdmin) {
      return null;
    }

    refetchProfile();
  };

  return {
    login,
    logout,
    profile,
    isLoading: isLoadingLogin || isLoadingLogout || isLoadingProfile,
  };
};

var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
