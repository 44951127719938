import React from "react";
import { DrawerLabelProps } from "components/atoms/drawerLabel/types";
import classNames from "classnames";

const DrawerLabel: React.FC<DrawerLabelProps> = ({
  onClick,
  selected,
  title,
}) => {
  return (
    <div
      className={classNames(
        "drawer-label",
        selected && "drawer-label--selected"
      )}
      onClick={onClick}
    >
      <span className={"drawer-label__title"}>{title}</span>
    </div>
  );
};

export default DrawerLabel;
