import React from "react";
import { Button, Input, Select, Tabs, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Structure, { NodeType, strf } from "./Structure";
import Btn from "components/atoms/button";
import Checkbox from "../checkbox";
import Uploader from "../../molecules/uploader";
import JsonEditor from "../jsonEditor";
import {
  arrayOptionsMap,
  objectOptionsMap,
} from "../../../utils/helpers/helperFunctions";
import RoleCheckboxes from "../../molecules/roleCheckboxes";
/*import TextEditor from "../textEditor";*/
import TextEditor from "../textEditorV2";
import DatePicker from "components/atoms/datePicker";

const { TabPane } = Tabs;

const isEmptyObject = (value) =>
  value && Object.keys(value).length === 0 && value.constructor === Object;

const filterSelectOptions = (input, option) =>
  option.props?.value.toString().toLowerCase().indexOf(input.toLowerCase()) >=
    0 ||
  option.props?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >=
    0;
const size = {
  25: { xs: 24 / 4, md: 12 / 4 },
  50: { xs: 24 / 2, md: 12 / 2 },
  100: { xs: 24, md: 24 },
};

const CustomFileUpload = (props) => {
  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>Click to Upload {props.title}</Button>
    </Upload>
  );
};

const Hr = (props) => {
  return <hr {...props} />;
};

export type PropsTypes = {
  [key: string]: any;
};

export type OverwriteTypes = {
  field?: any;
  required?: boolean;
  props?: PropsTypes;
};

export default class StructureForm extends Structure {
  // special blocks =========================

  // @ts-ignore
  render() {
    const nodes: any = this.getNodes();

    return nodes;
  }

  transfer_on_import(use: any = {}, overwrite: OverwriteTypes = {}) {
    use.create &&
      this.__checkbox("create-if-not-exists-in-db", {
        label: this._t("transfer_on_import_create"),
        ...overwrite.props,
      });

    use.update &&
      this.__checkbox("update-if-exists-in-csv", {
        label: this._t("transfer_on_import_update"),
        ...overwrite.props,
      });

    use.delete &&
      this.__checkbox("remove-if-not-exists-csv", {
        label: this._t("transfer_on_import_delete"),
        ...overwrite.props,
      });

    return this;
  }

  tabs(array, f, options: any = {}) {
    const nodes = array.map((value): NodeType => {
      const s = this.clone();
      f(s, value, options);
      return {
        component: TabPane,
        props: {
          tab: value,
          key: value,
          forceRender: true,
        },

        type: "tab",
        value: value,
        /*       options: options,*/
        node: s.getNodes(),
      };
    });

    return this.add({
      component: Tabs,
      props: {
        type: "tabs",
      },
      value: array,
      options: options,
      node: nodes,
    });
  }

  // variants ====================

  combine(pack, func, options = {}) {
    const divider = pack.divider ? pack.divider : ".";
    delete pack.divider;

    const keys = Object.keys(pack);
    const appendValue = (array, key, params = { path: "" }) => {
      return array[key].map((v) => {
        const add = { [key]: v };
        const path = params.path ? params.path + divider + v : v;
        return { ...params, path, ...add };
      });
    };

    if (keys.length === 3) {
      return this.each(appendValue(pack, keys[0]), (s, value) =>
        this.each(appendValue(pack, keys[1], value), (s, value) =>
          this.each(appendValue(pack, keys[2], value), func, options)
        )
      );
    } else if (keys.length === 2) {
      return this.each(appendValue(pack, keys[0]), (s, value) =>
        this.each(appendValue(pack, keys[1], value), func, options)
      );
    } else if (keys.length === 1) {
      return this.each(appendValue(pack, keys[0]), func, options);
    }

    throw Error("Combine can not use with empty pack");
  }

  menu_image(transform, overwrite: OverwriteTypes = {}) {
    return this.image(transform, {
      field: overwrite.field || "menu_image",
      props: {
        label: (combine) =>
          this._t("menu_image {locale} {device}", {
            locale: "",
            device: "",
            ...combine,
          }),
        placeholder: "menu_image placeholder ...",
        ...overwrite.props,
      },
    });
  }

  background(transform, overwrite: OverwriteTypes = {}) {
    return this.image(transform, {
      field: overwrite.field || "background",
      props: {
        label: (combine) =>
          this._t("background {locale} {device}", {
            locale: "",
            device: "",
            ...combine,
          }),
        placeholder: "background placeholder ...",
        ...overwrite.props,
      },
    });
  }

  image(transform, overwrite: OverwriteTypes = {}) {
    if (isEmptyObject(transform)) {
      return this.__image(overwrite.field || "image", {
        label: this._t("image"),
        ...overwrite.props,
      });
    }

    return this.__combine_image(transform, overwrite.field || "image", {
      label: (combine) =>
        this._t("image {locale} {device}", {
          locale: "",
          device: "",
          ...combine,
        }),
      placeholder: "image placeholder ...",
      ...overwrite.props,
    });
  }

  __combine_image(transform, field, props: PropsTypes = {}) {
    return this.combine(
      transform,
      (s: this, combine, options = {}) => {
        const label =
          props.label instanceof Function ? props.label(combine) : props.label;

        return s.__image(field + "." + combine.path, {
          ...props,
          ...{ label: label },
        });
      },
      {}
    );
  }

  file(overwrite: OverwriteTypes = {}) {
    return this.__file(overwrite.field || "file", {
      label: this._t("file"),
      ...overwrite.props,
    });
  }

  path(overwrite: OverwriteTypes = {}) {
    return this.__file(overwrite.field || "path", {
      label: this._t("path"),
      ...overwrite.props,
    });
  }

  seo(
    locales,
    overwriteTitle: OverwriteTypes = {},
    overwriteText: OverwriteTypes = {},
    overwriteDescription: OverwriteTypes = {}
  ) {
    return this.tabs(locales, (s: this, locale, options) => {
      return s
        .__input((overwriteTitle.field || "seo_title") + `.${locale}`, {
          label: this._t("seo_title {locale}", { locale }),
          placeholder: "seo_title placeholder ...",
          rules: [
            {
              required: overwriteTitle.required || false,
              message: this._t("validation.required"),
            },
          ],
          ...overwriteTitle.props,
        })
        .__input((overwriteText.field || "seo_text") + `.${locale}`, {
          label: this._t("seo_text {locale}", { locale }),
          placeholder: "seo_text placeholder ...",
          rules: [
            {
              required: overwriteText.required || false,
              message: this._t("validation.required"),
            },
          ],
          ...overwriteText.props,
        })
        .__input(
          (overwriteDescription.field || "seo_description") + `.${locale}`,
          {
            label: this._t("seo_description {locale}", { locale }),
            placeholder: "seo_description placeholder ...",
            rules: [
              {
                required: overwriteDescription.required || false,
                message: this._t("validation.required"),
              },
            ],
            ...overwriteDescription.props,
          }
        );
    });
  }

  role_resources(roleResourcePack, overwrite: OverwriteTypes = {}) {
    return this.add({
      component: RoleCheckboxes,
      path: overwrite.field || "resources",
      props: {
        label: this._t("resources"),
        placeholder: "resources placeholder ...",
        colParams: size[100],
        options: roleResourcePack,
        ...overwrite.props,
      },
    });
  }

  method_gate(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "gate", {
      label: this._t("method_gate"),
      placeholder: "method_gate placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  method_gate_key(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "gate_key", {
      label: this._t("method_gate_key"),
      placeholder: "method_gate_key placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  settings(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "settings", {
      label: this._t("settings"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  options(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "options", {
      label: this._t("options"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  method_input(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "input", {
      label: this._t("method_input"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  method_strategy(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "strategy", {
      label: this._t("method_strategy"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  allow_countries(constantIndex, overwrite: OverwriteTypes = {}) {
    return this.countries(constantIndex, {
      field: overwrite.field || "allow_countries",
      props: {
        label: this._t("allow_countries"),
        placeholder: "allow_countries placeholder ...",
        ...overwrite.props,
      },
    });
  }

  allow_currencies(constantIndex, overwrite: OverwriteTypes = {}) {
    return this.currencies(constantIndex, {
      field: overwrite.field || "allow_currencies",
      props: {
        label: this._t("allow_currencies"),
        placeholder: "allow_currencies placeholder ...",
        ...overwrite.props,
      },
    });
  }

  country_currency_map(constantIndex, overwrite: OverwriteTypes = {}) {
    // TODO correct mapping constantIndex ...
    return this.__input_json_editor(overwrite.field || "country_currency_map", {
      label: this._t("country_currency_map"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  category_position(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "category_position", {
      label: this._t("category_position"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  show_condition(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "show_condition", {
      label: this._t("show_condition"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  type(options, overwrite: OverwriteTypes = {}) {
    return this.__select_single(overwrite.field || "type", {
      label: this._t("type"),
      placeholder: "type placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  xp(overwrite: OverwriteTypes = {}) {
    return this.__input_number(overwrite.field || "xp", {
      label: this._t("xp"),
      placeholder: "xp placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  time(overwrite: OverwriteTypes = {}) {
    return this.__input_number(overwrite.field || "time", {
      label: this._t("time"),
      placeholder: "time placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  game_provider(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "provider", {
      label: this._t("game_provider"),
      placeholder: "game_provider placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  game_runner(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "runner", {
      label: this._t("game_runner"),
      placeholder: "game_runner placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  game_runner_key(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "runner_key", {
      label: this._t("game_runner_key"),
      placeholder: "game_runner_key placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  system(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "system", {
      label: this._t("system"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  public(overwrite: OverwriteTypes = {}) {
    return this.__input_json_editor(overwrite.field || "public", {
      label: this._t("public"),
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  name(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "name", {
      label: this._t("name"),
      placeholder: "name placeholder ...",
      rules: [{ required: true, message: this._t("validation.required") }],
      ...overwrite.props,
    });
  }

  namespace(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "namespace", {
      label: this._t("namespace"),
      placeholder: "namespace placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  slug(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "slug", {
      label: this._t("slug"),
      placeholder: "slug placeholder ...",
      rules: [{ required: true, message: this._t("validation.required") }],
      ...overwrite.props,
    });
  }

  position(overwrite: OverwriteTypes = {}) {
    return this.__input_number(overwrite.field || "position", {
      label: this._t("position"),
      placeholder: "position placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  video(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "video", {
      label: this._t("video"),
      placeholder: "video placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  email(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "email", {
      label: this._t("email"),
      placeholder: "email placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  password(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "password", {
      label: this._t("password"),
      placeholder: "password placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  token(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "token", {
      label: this._t("token"),
      placeholder: "token placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  languages(constantIndex, overwrite: OverwriteTypes = {}) {
    const options = objectOptionsMap(constantIndex.data?.language, true);
    return this.__select_multiple(overwrite.field || "languages", {
      label: this._t("languages"),
      placeholder: "languages placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  round_id(roundIndex, overwrite: OverwriteTypes = {}) {
    const options = arrayOptionsMap(roundIndex?.data, {
      labelKey: "title",
      valueKey: "id",
    });
    return this.__select_single(overwrite.field || "round_id", {
      label: this._t("round_id"),
      placeholder: "round_id placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  role_id(roleIndex, overwrite: OverwriteTypes = {}) {
    const options = arrayOptionsMap(roleIndex?.data, {
      labelKey: "name",
      valueKey: "id",
    });
    return this.__select_single(overwrite.field || "role_id", {
      label: this._t("role_id"),
      placeholder: "role_id placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  brand_ids(brandIndex, overwrite: OverwriteTypes = {}) {
    const options = arrayOptionsMap(brandIndex?.data, {
      labelKey: "name",
      valueKey: "id",
    });
    return this.__select_multiple(overwrite.field || "brand_ids", {
      label: this._t("brand_ids"),
      placeholder: "brand_ids placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  series_tags(tagIndex, overwrite: OverwriteTypes = {}) {
    const options = arrayOptionsMap(tagIndex?.data, {
      labelKey: "slug" /*'title.en'*/,
      valueKey: "id",
    });
    return this.__select_multiple(overwrite.field || "tags", {
      label: this._t("tags"),
      placeholder: "tags placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  series_categories(categoryIndex, overwrite: OverwriteTypes = {}) {
    const options = arrayOptionsMap(categoryIndex?.data, {
      labelKey: "slug",
      valueKey: "id",
    });
    return this.__select_multiple(overwrite.field || "categories", {
      label: this._t("categories"),
      placeholder: "categories placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  currencies(constantIndex, overwrite: OverwriteTypes = {}) {
    const options = objectOptionsMap(constantIndex.data?.currency, true);
    return this.__select_multiple(overwrite.field || "currencies", {
      label: this._t("currencies"),
      placeholder: "currencies placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  countries(constantIndex, overwrite: OverwriteTypes = {}) {
    const options = objectOptionsMap(constantIndex.data?.country, true);
    return this.__select_multiple(overwrite.field || "countries", {
      label: this._t("countries"),
      placeholder: "countries placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      options: options,
      ...overwrite.props,
    });
  }

  active(overwrite: OverwriteTypes = {}) {
    return this.__checkbox(overwrite.field || "active", {
      label: this._t("active"),
      placeholder: "active placeholder ...",
      ...overwrite.props,
    });
  }

  has_mobile(overwrite: OverwriteTypes = {}) {
    return this.__checkbox(overwrite.field || "has_mobile", {
      label: this._t("has_mobile"),
      placeholder: "has_mobile placeholder ...",
      ...overwrite.props,
    });
  }

  has_desktop(overwrite: OverwriteTypes = {}) {
    return this.__checkbox(overwrite.field || "has_desktop", {
      label: this._t("has_desktop"),
      placeholder: "has_desktop placeholder ...",
      ...overwrite.props,
    });
  }

  key(overwrite: OverwriteTypes = {}) {
    return this.__input(overwrite.field || "key", {
      label: this._t("key"),
      placeholder: "key placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  hr(props: PropsTypes = {}) {
    this.add({
      component: Hr,
      props: {
        style: { opacity: 0.5 },
        ...props,
      },
    });
  }

  btn_submit(props: PropsTypes = {}) {
    return this.___btn({
      btnLabel: this._t("submit"),
      themeType: "primary",
      htmlType: "submit",
      ...props,
    });
  }

  btn_reset(props: PropsTypes = {}) {
    return this.___btn({
      btnLabel: this._t("reset"),
      themeType: "primary",
      htmlType: "button",
      ...props,
    });
  }

  btns_submit_reset(submitProps: PropsTypes = {}, resetProps: PropsTypes = {}) {
    this.hr();
    this.btn_submit(submitProps);
    this.btn_reset(resetProps);

    return this;
  }

  translate(languages, overwrite: OverwriteTypes = {}) {
    return this.each(languages, (s: this, locale) => {
      this.__input((overwrite.field || "translate") + `.${locale}`, {
        label: this._t("translate {locale}", { locale }),
        placeholder: "placeholder translate ...",
        rules: [
          {
            required: overwrite.required || false,
            message: this._t("validation.required"),
          },
        ],
        ...overwrite.props,
      });
    });
  }

  menu_title(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "menu_title") + `.${locale}`, {
      label: this._t("menu_title {locale}", { locale }),
      placeholder: "menu_title placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  title(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "title") + `.${locale}`, {
      label: this._t("title {locale}", { locale }),
      placeholder: "title placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  text(locale, overwrite: OverwriteTypes = {}) {
    return this.__input_editor((overwrite.field || "text") + `.${locale}`, {
      label: this._t("text {locale}", { locale }),
      placeholder: "text placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  text_short(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "text_short") + `.${locale}`, {
      label: this._t("text_short {locale}", { locale }),
      placeholder: "text_short placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  description(locale, overwrite: OverwriteTypes = {}) {
    return this.__input_editor(
      (overwrite.field || "description") + `.${locale}`,
      {
        label: this._t("description {locale}", { locale }),
        placeholder: "description placeholder ...",
        rules: [
          {
            required: overwrite.required || false,
            message: this._t("validation.required"),
          },
        ],
        ...overwrite.props,
      }
    );
  }

  note(locale, overwrite: OverwriteTypes = {}) {
    return this.__input_editor((overwrite.field || "note") + `.${locale}`, {
      label: this._t("note {locale}", { locale }),
      placeholder: "note placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  btn_title(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "btn_title") + `.${locale}`, {
      label: this._t("btn_title {locale}", { locale }),
      placeholder: "btn_title placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  btn_target(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "btn_target") + `.${locale}`, {
      label: this._t("btn_target {locale}", { locale }),
      placeholder: "btn_target placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  tags(overwrite: OverwriteTypes = {}) {
    return this.__select_tags(overwrite.field || "tags", {
      label: this._t("tags"),
      placeholder: "tags placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  flags(overwrite: OverwriteTypes = {}) {
    return this.__select_tags(overwrite.field || "flags", {
      label: this._t("flags"),
      placeholder: "flags placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  show_from(overwrite: OverwriteTypes = {}) {
    return this.__input_date(
      overwrite.field || "show_from",
      {
        label: this._t("show_from"),
        placeholder: "show_from placeholder ...",
        rules: [
          {
            required: overwrite.required || false,
            message: this._t("validation.required"),
          },
        ],
        ...overwrite.props,
      },
      true
    );
  }

  show_to(overwrite: OverwriteTypes = {}) {
    return this.__input_date(
      overwrite.field || "show_to",
      {
        label: this._t("show_to"),
        placeholder: "show_to placeholder ...",
        rules: [
          {
            required: overwrite.required || false,
            message: this._t("validation.required"),
          },
        ],
        ...overwrite.props,
      },
      true
    );
  }

  target(locale, overwrite: OverwriteTypes = {}) {
    return this.__input((overwrite.field || "target") + `.${locale}`, {
      label: this._t("target {locale}", { locale }),
      placeholder: "target placeholder ...",
      rules: [
        {
          required: overwrite.required || false,
          message: this._t("validation.required"),
        },
      ],
      ...overwrite.props,
    });
  }

  __input(path, props: PropsTypes = {}) {
    return this.add({
      component: Input,
      path: path,
      props: {
        colParams: size[100],
        rules: [],
        ...props,
      },
    });
  }

  __input_date(path, props: PropsTypes = {}, showTime = true) {
    return this.add({
      component: DatePicker,
      path: path,
      props: {
        colParams: size[100],
        showTime: showTime,
        rules: [],
        ...props,
      },
    });
  }

  __input_number(path, props: PropsTypes = {}) {
    return this.add({
      component: Input,
      path: path,
      props: {
        colParams: size[100],
        type: "number",
        rules: [],
        ...props,
      },
    });
  }

  __input_editor(path, props: PropsTypes = {}) {
    return this.add({
      component: TextEditor,
      path: path,
      props: {
        colParams: size[100],
        rules: [],
        ...props,
      },
    });
  }

  __input_json_editor(path, props: PropsTypes = {}) {
    return this.add({
      component: JsonEditor,
      path: path,
      props: {
        containerId: path,
        colParams: size[100],
        rules: [],
        ...props,
      },
    });
  }

  ___btn(props: PropsTypes = {}) {
    return this.add({
      component: Btn,
      type: "submit",
      props: {
        btnLabel: this._t("submit"),
        themeType: "primary",
        htmlType: "submit",
        noStyle: true,
        ...props,
      },
    });
  }

  __select_single(path, props: PropsTypes = {}) {
    return this.add({
      component: Select,
      path: path,
      props: {
        colParams: size[100],
        filterOption: filterSelectOptions,
        ...props,
      },
    });
  }

  __select_multiple(path, props: PropsTypes = {}) {
    return this.add({
      component: Select,
      path: path,
      props: {
        allowClear: true,
        colParams: size[100],
        mode: "multiple",
        filterOption: filterSelectOptions,
        ...props,
      },
    });
  }

  __select_tags(path, props: PropsTypes = {}) {
    return this.add({
      component: Select,
      path: path,
      props: {
        colParams: size[50],
        mode: "tags",
        ...props,
      },
    });
  }

  __checkbox(path, props: PropsTypes = {}) {
    return this.add({
      component: Checkbox,
      path: path,
      props: {
        colParams: size[100],
        ...props,
      },
    });
  }

  __file(path, props: PropsTypes = {}) {
    return this.add({
      component: Uploader,
      path: path,
      props: {
        label: this._t("file"),
        ...props,
      },
    });
  }

  __image(path, props: PropsTypes = {}) {
    return this.add({
      component: Uploader,
      path: path,
      props: {
        label: this._t("image"),
        ...props,
      },
    });
  }
}
