import React, { useMemo } from "react";
import { LocaleSwitchProps } from "components/molecules/localeSwitch/types";
import { Popover } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import LocaleMenuItem from "components/atoms/localeMenuItem";

const LocaleSwitch: React.FC<LocaleSwitchProps> = ({
  selectedLocale,
  localeData,
  switchLocale,
}) => {
  const localeMenu = useMemo(() => {
    return (
      <CustomScrollbars className="gx-popover-lang-scroll">
        <ul className="gx-sub-popover">
          {localeData?.map((item) => (
            <li
              className="gx-media gx-pointer"
              key={JSON.stringify(item)}
              onClick={() => {
                switchLocale(item);
              }}
            >
              <LocaleMenuItem icon={item.icon} name={item.name} />
            </li>
          ))}
        </ul>
      </CustomScrollbars>
    );
  }, [localeData]);
  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={localeMenu}
      trigger="click"
    >
      <span className="gx-pointer gx-flex-row gx-align-items-center">
        <LocaleMenuItem icon={selectedLocale.icon} name={selectedLocale.name} />
        <i className="icon icon-chevron-down gx-pl-2" />
      </span>
    </Popover>
  );
};

export default LocaleSwitch;
