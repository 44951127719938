import React from "react";
import {
  useApiCertificateStore,
  useApiCertificateUpdate,
  useApiCertificateShow,
  useApiCertificateDestroy,
  useApiCertificateIndex,
} from "server/apiGeneratorHooks";
import {
  useCommonComponentCrud,
  useTableQuery
} from "utils/helpers/hooks";
import { useGlobalTranslate } from "utils/translation/hooks";
import StructureForm from "components/atoms/structure/StructureForm";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import StructureTable from "components/atoms/structure/StructureTable";
import {CommonStructureDestroy, CommonStructureIndex, CommonStructureUpset} from "components/atoms/common";
import {useIsAdminAllow} from "services/rolesRestrictions/hooks";

/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({onShow, onEdit, onDelete}) => {
  const { _t } = useGlobalTranslate();
  const structureTableFilter = new StructureTableFilter({ _t: _t });
  const structureTable = new StructureTable({ _t: _t });

  structureTableFilter
      // .id()
      .name({field: 'code', props: {label: _t('code')}})
      .name()
      // .xp({field: 'time', props: {label: _t('time')}})
      .name({field: 'text', props: {label: _t('text')}})
      .active()
      // .created_at()
      // .updated_at()
      .btns_submit_reset()
  ;

  structureTable
      .id()
      .name({field: 'code', props: {label: _t('code')}})
      .name()
      .position({field: 'time', props: {label: _t('time')}})
      .name({field: 'text', props: {label: _t('text')}})
      .active()
      .created_at()
      .updated_at()
      .action({ onShow, onEdit, onDelete })
  ;

  return {structureTableFilter , structureTable};
}

/**
 *
 * @param item
 */
const useForm = ({item = null}) => {
  const { _t } = useGlobalTranslate();
  const structureForm = new StructureForm({ _t: _t });

  structureForm
      // .id()
      .name({field: 'code', props: {label: _t('code')}})
      .name()
      .time()
      .name({field: 'text', props: {label: _t('text')}})
      .active()
      .btns_submit_reset()
  ;

  return structureForm;
}


/**
 *
 * @param drawer
 * @constructor
 */
const Store = ({drawer}) => {
  const structureForm = useForm({});
  const apiStore = useApiCertificateStore();

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiStore}
      structureForm={structureForm}
      data={{}}
      loading={apiStore.isLoading}
    />
  );
};

/**
 *
 * @param drawer
 * @param item
 * @constructor
 */
const Update = ({drawer, item}) => {
  const structureForm = useForm({item});
  const apiUpdate = useApiCertificateUpdate(item.id);
  const apiShow = useApiCertificateShow(item.id, {enabled: apiUpdate.isIdle});

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiUpdate}
      structureForm={structureForm}
      data={apiShow.data}
      loading={apiShow.isFetching || apiUpdate.isLoading}
    />
  );
};

/**
 *
 * @param modal
 * @param item
 * @constructor
 */
const Destroy = ({modal, item}) => {
  const apiDestroy = useApiCertificateDestroy(item.id);

  return (
    <CommonStructureDestroy
      modal={modal}
      mutate={apiDestroy}
      structure={{}}
      data={item}
      loading={apiDestroy.isLoading}
    />
  );
};


/**
 *
 * @constructor
 */
const CommonNotaCertificate = () => {
  const {_t} = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = 'nota:certificate';

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    componentStore: isAllow(prefix + '@store') && Store,
    componentUpdate: isAllow(prefix + '@update') && Update,
    componentDestroy: isAllow(prefix + '@destroy') && Destroy,
    // componentUpload: isAllow(prefix + '@upload') && Upload,
    // componentDownload: isAllow(prefix + '@download') && {},
  });

  const apiIndex = useApiCertificateIndex({query: tableQuery.query});
  const {structureTableFilter, structureTable} = useTable(
    {onShow: null, onEdit: crud.onEdit, onDelete: crud.onDelete},
  );

  return (
    <CommonStructureIndex
      title={_t("menu.nota.certificate")}
      source={apiIndex}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={crud.onUpload}
      onDownload={crud.onDownload}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default CommonNotaCertificate;
