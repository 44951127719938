import React from "react";
import { Input } from "antd";
import { coinsToAmount, amountToCoins } from "utils/helpers/helperFunctions";

const AmountInput = (props) => {
  return (
    <Input
      {...props}
      type={"number"}
      defaultValue={coinsToAmount(props.defaultValue) || undefined}
      value={coinsToAmount(props.value)}
      onChange={(e) => {
        props.onChange(amountToCoins(e.target.value));
      }}
    />
  );
};

export default AmountInput;
