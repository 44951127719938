import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

const RestrictedRoute = ({
  component: Component,
  currentAdmin,
  ...rest
}: any) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        currentAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/signin", state: { from: location } }} />
        )
      }
    />
  );
};

export default RestrictedRoute;
