import React from "react";
import Spinner from "components/atoms/spinner";

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <Spinner />
    {/*  <img src="/assets/images/loader.svg" alt="loader"/>*/}
  </div>
);
export default CircularProgress;
