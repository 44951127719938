import React, { useEffect } from "react";
import * as ace from "brace";
import "brace/mode/javascript";
import "brace/theme/clouds_midnight";
import { EditorChangeEvent } from "brace";

const JsonEditor = ({
  value,
  onChange,
  containerId = "javascript-editor",
}: any) => {
  let editor = null;
  const onChangeHandler = (value) => {
    try {
      onChange && onChange(JSON.parse(value));
    } catch (e) {
      console.log("JsonEditor error");
    }
  };

  useEffect(() => {
    editor = ace.edit(containerId);
    editor.getSession().setMode("ace/mode/javascript");
    editor.setTheme("ace/theme/clouds_midnight");
    if (value) {
      editor.setValue(JSON.stringify(value, null, 2));
    }
    editor.addEventListener("change", (ev: EditorChangeEvent) => {
      const value = editor.getValue();
      onChangeHandler(value);
    });
  }, []);

  useEffect(() => () => editor && editor.destroy(), []);

  return (
    <div
      key={containerId}
      id={containerId}
      style={{ height: "200px", width: "100%" }}
    />
  );
};

export default JsonEditor;
