// @ts-nocheck
// tslint:disable
/* tslint:disable */

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";

import axiosInstance from "./axiosConfig";
import { parseFormData } from "utils/helpers/helperFunctions";

export declare type ResponseServerGet<
  TData = unknown,
  TError = unknown
> = UseQueryResult<TData, TError>;

export declare type ResponseServerPost<
  TData = unknown,
  TError = unknown
> = UseMutationResult<TData, TError>;

export declare type ResponseServerPut<
  TData = unknown,
  TError = unknown
> = UseMutationResult<TData, TError>;

export declare type ResponseServerPatch<
  TData = unknown,
  TError = unknown
> = UseMutationResult<TData, TError>;

export declare type ResponseServerDelete<
  TData = unknown,
  TError = unknown
> = UseMutationResult<TData, TError>;

export declare type ResponseServerError = any;
export type ParamsType = {
  options?: { [key: string]: any };
  method: string;
  url: string;
};

const isObjectHasFile = (obj) => {
  for (const value of Object.values(obj)) {
    if (value instanceof File || value instanceof Blob) {
      return true;
    } else if (!!value && typeof value === "object") {
      if (isObjectHasFile(value)) {
        return true;
      }
    }
  }

  return false;
};

const serializeObjectToQuery = function (obj, prefix) {
  var str = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? serializeObjectToQuery(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }

  str = str.filter((n) => n);

  return str.join("&");
};

export const generateUrlQuery = (params) => {
  if (Object.keys(params).length === 0) {
    return "";
  }

  let searchParams = serializeObjectToQuery(params);

  searchParams = searchParams.split("%5B").join("[");
  searchParams = searchParams.split("%5D").join("]");

  return "?" + searchParams;
};

export const useMakeRequest = () => {
  const request = (params: any) =>
    axiosInstance
      .request(params)
      .then((r) => r.data)
      .catch((err) => {
        throw err?.response?.data?.errors;
      });

  return [request];
};

/**
 * Usage
 *
 * const apiDestroy = useApiGameboxGameDestroy(123, ':game');
 * apiDestroy.mutateAsync({':game' : 456, value1: 123, , value2: 123});
 *
 * const apiDestroy = useApiGameboxGameDestroy(123, ':placeholder_game');
 * apiDestroy.mutateAsync({':placeholder_game' : 456, value1: 123, value2: 123}); // will replace from data to url this key
 *
 */

const replaceUrlPlaceholderByData = (params: ParamsType, data = {}) => {
  Object.keys(data).forEach((key: string) => {
    if (
      key.charAt(0) === ":" &&
      (typeof data[key] == "string" || typeof data[key] == "number")
    ) {
      params.url = params.url.split(key).join(data[key]);
      delete data[key];
    }
  });

  return { params, data };
};

const replaceDataByFormData = (params: ParamsType, data = {}) => {

  // console.log('xxx replaceDataByFormData params', params);
  // console.log('xxx replaceDataByFormData data', data);

  if (isObjectHasFile(data)) {
    params.options.headers = params.options.headers || {};
    params.options.headers["Content-Type"] = "multipart/form-data";
    data = parseFormData(data);

    // >>> FIX laravel PUT upload file via post >>>
    if (params.method !== "POST") {
      data.append("_method", params.method.toLowerCase());
      params.method = "POST";
    }
    // <<< FIX laravel PUT upload file via post <<<
  }

  return { params, data };
};

const prepareRequestParams = (params: ParamsType, data = {}) => {


  // console.log('xxx prepareRequestParams params', params);
  // console.log('xxx prepareRequestParams data', data);

  const origin = { params: { ...params }, data: { ...data } }; // copy originals
  const replaced = replaceUrlPlaceholderByData(origin.params, origin.data);
  const modified = replaceDataByFormData(replaced.params, replaced.data);

  return {
    url: modified.params.url,
    headers: modified.params.options.headers || {},
    method: modified.params.method,
    data: modified.data,
  };
};

const buildQueryIdentify = (params: OptionsType) => {
  const url = params.url;
  const method = params.method;
  const query = params.options["query"] || {};

  const result = {
    key: [],
    invalidateSet: [],
    url: url,
    query: query,
    method: method,
  };

  const base = url
    .replace(/\?.+$/, "") // cut query ?xxx
    .replace(/\/$/, ""); // cut trailing slash if need

  if (method === "PUT" || method === "PATCH" || method === "DELETE") {
    const index = base.substring(0, url.lastIndexOf("/"));
    result.invalidateSet.push([index]);
  }

  result.key.push(base);
  result.invalidateSet.push([base]);

  if (method === "GET") {
    result.key.push(JSON.stringify(query));
  }

  return result;
};

export const useServerGet = (params: ParamsType) => {
  const [request] = useMakeRequest();
  const key = buildQueryIdentify(params).key;
  const requestParams = prepareRequestParams(params);

  return useQuery(key, () => request(requestParams), params.options);
};

export const useServerPost = (params: ParamsType) => {
  const [request] = useMakeRequest();
  const queryClient = useQueryClient();
  const invalidateSet = buildQueryIdentify(params).invalidateSet;

  return useMutation(
    (data: any) => {
      const requestParams = prepareRequestParams(params, data);
      return request(requestParams);
    },
    {
      onSuccess: () => {
        const invalidate = invalidateSet.concat(params.options.invalidateSet || []);
        invalidate.forEach((key) => queryClient.invalidateQueries(key));

        params.options.onSuccess && params.options.onSuccess();
      },
    }
  );
};
export const useServerPut = (params: ParamsType) => {
  const [request] = useMakeRequest();
  const queryClient = useQueryClient();
  const invalidateSet = buildQueryIdentify(params).invalidateSet;

  return useMutation(
    (data: any) => {
      const requestParams = prepareRequestParams(params, data);
      return request(requestParams);
    },
    {
      onSuccess: () => {
        const invalidate = invalidateSet.concat(params.options.invalidateSet || []);
        invalidate.forEach((key) => queryClient.invalidateQueries(key));

        params.options.onSuccess && params.options.onSuccess();
      },
    }
  );
};
export const useServerPatch = (params: ParamsType) => {
  const [request] = useMakeRequest();
  const queryClient = useQueryClient();
  const invalidateSet = buildQueryIdentify(params).invalidateSet;

  return useMutation(
    (data: any) => {
      const requestParams = prepareRequestParams(params, data);
      return request(requestParams);
    },
    {
      onSuccess: () => {
        const invalidate = invalidateSet.concat(params.options.invalidateSet || []);
        invalidate.forEach((key) => queryClient.invalidateQueries(key));

        params.options.onSuccess && params.options.onSuccess();
      },
    }
  );
};
export const useServerDelete = (params: ParamsType) => {
  const [request] = useMakeRequest();
  const queryClient = useQueryClient();
  const invalidateSet = buildQueryIdentify(params).invalidateSet;

  return useMutation(
    (data: any) => {
      const requestParams = prepareRequestParams(params, data);
      return request(requestParams);
    },
    {
      onSuccess: () => {
        const invalidate = invalidateSet.concat(params.options.invalidateSet || []);
        invalidate.forEach((key) => queryClient.invalidateQueries(key));

        params.options.onSuccess && params.options.onSuccess();
      },
    }
  );
};

/*export const useServerPut = (
  options: OptionsType
) /!* : UseMutationResult *!/ => {
  return useMutation(
    (data: any) => {
      const {
        method: transformedMethod,
        data: transformedData,
        headers: transformedHeaders,
        detect,
      } = sendServerDataAsFormDataCheckLaravelPutFileFix(data, "post");

      return axiosInstance
        .post(options.url, transformedData, transformedHeaders)
        .then((data) => data.data)
        .catch((error) => Promise.reject(error.response?.data || error));
    },
    {
      // useErrorBoundary: (error) => error.response?.data?.errors && error.response.status >= 400 && error.response.status <= 500,
      // onError: (error) => error.response?.data?.errors && error.response.status >= 400 && error.response.status <= 500,
    }
  );
};*/

/*export const useServerPut = (
  options: OptionsType
) /!* : UseMutationResult *!/ => {
  return useMutation((data: any) => {
    const {
      method: transformedMethod,
      data: transformedData,
      headers: transformedHeaders,
      detect,
    } = sendServerDataAsFormDataCheckLaravelPutFileFix(data, "put");

    return axiosInstance[transformedMethod](
      options.url,
      transformedData,
      transformedHeaders
    ).then((data) => data.data);
  });
};*/
//
// export const useServerPatch = (options: OptionsType) /* : UseMutationResult */ => {
//   const [request] = useMakeRequest();
//
//   return useMutation(
//     (data: any) =>
//       request({
//         type: options.type,
//         request: {
//           method: options.method,
//           url: options.url + data.id,
//           data: data,
//         },
//       }),
//     {
//       onSuccess: (responseData: any, requestData: any = {}) => {
//         options.onSuccess && options.onSuccess(responseData, options, requestData, options.keys);
//       },
//       onError: (responseData: any, requestData: any = {}) => {
//         options.onError && options.onError(responseData, options, requestData);
//       },
//     }
//   );
// };
