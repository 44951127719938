import { Menu } from "antd";
import { Link } from "react-router-dom";
import React from "react";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

export const renderMenu = (checkAllow, item, type?) => {

    const check = [];

    if (item.isAllowResource) {
        check.push(item.isAllowResource);
    }

    for (var i in item?.items) {
        if (item.items[i].isAllowResource) {
            check.push(item.items[i].isAllowResource);
        }
    }

    const isAllow = checkAllow(check);

    if (!isAllow) {
        return null;
    }


  if (item.items && type != "submenu") {
    return (
      <MenuItemGroup
        key={item.key}
        className="gx-menu-group"
        title={item.title}
      >
        {item.items?.map((subitem, index) =>
          renderMenu(checkAllow, subitem, subitem.items ? "submenu" : "default")
        )}
      </MenuItemGroup>
    );
  } else if (type === "submenu") {
    return (
      <SubMenu
        key={item.key}
        // popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <span>
            <i className={"icon"}>{item.icon}</i>
            <span>{item.title}</span>
          </span>
        }
      >
        {item.items?.map((subitem, index) => renderMenu(checkAllow, subitem))}
      </SubMenu>
    );
  } else {
    return (
      <Menu.Item key={item.url}>
        <Link to={item.url}>
          <i className={"icon"}>{item.icon}</i>
          <span>{item.title}</span>
        </Link>
      </Menu.Item>
    );
  }
};
