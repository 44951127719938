import React, { useMemo, useState } from "react";
import { Modal, Upload } from "antd";
import { UploaderProps } from "./types";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import type { UploadFile } from "antd/es/upload/interface";
import { CSS } from "@dnd-kit/utilities";

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  // prevent preview event when drag end
  /*  const className = isDragging
    ? css`
        a {
          pointer-events: none;
        }
      `
    : "";*/

  return (
    <div
      ref={setNodeRef}
      style={style}
      /*    className={className}*/
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

const Uploader: React.FC<UploaderProps> = ({
  onChange,
  value,
  multiple = false,
  cdn = "",
}) => {
  const defaultFileList = useMemo(() => {
    return multiple
      ? value?.map((item) => ({
          url: cdn + item,
        })) || []
      : !!value?.length
      ? [
          {
            uid: "",
            name: "",
            status: "",
            url: cdn + value,
          },
        ]
      : [];
  }, [cdn]);
  const [fileList, setFileList] = useState<any>(defaultFileList);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const onChangeHandler = ({ fileList, file }) => {
    const dataFile = file?.size ? file : null;
    setFileList(fileList);
    const data = multiple
      ? fileList.map((item) =>
          item.hasOwnProperty("originFileObj")
            ? item.originFileObj
            : item?.url?.replace(cdn, "")
        )
      : dataFile;

    onChange(data);
  };
  const handleCancel = () => setPreviewVisible(false);
  const onPreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext items={fileList.map((i) => i.uid)}>
          <Upload
            name={"file"}
            onChange={onChangeHandler}
            beforeUpload={() => false}
            fileList={fileList}
            onPreview={onPreview}
            listType="picture-card"
            itemRender={(originNode, file) => {
              return (
                <DraggableUploadListItem originNode={originNode} file={file} />
              );
            }}
          >
            {fileList.length && !multiple ? null : uploadButton}
          </Upload>
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default Uploader;
