import { CheckSelectedItemsArgs, GetTabErrorByLangResult } from "./types";
import dotProp from "dot-prop";

export const unpackObjFromStr = (
  str: string = "",
  divider: string = ".",
  value: any = undefined
) => {
  if (!str.includes(divider)) {
    return value;
  }
  return str.split(divider).reduceRight((obj, elem, index, arr) => {
    return { [elem]: index === arr.length - 1 ? value : obj };
  }, {});
};

export const getNestedValue = (obj: any = {}, path: any, divider = ".") => {
  var properties = Array.isArray(path) ? path : path.split(divider);
  return properties.reduce((p: any, c: any) => p && p[c], obj);
};

export const checkObjectType = (val: any) =>
  typeof val === "object" &&
  !Array.isArray(val) &&
  Object.prototype.toString.call(val) !== "[object File]";

export const checkStringType = (val: any) => typeof val === "string";

export const parseNestedData = (values: any) => {
  const resultData: any = {};
  let startKey = "";
  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = parsDataKey === "" ? key : parsDataKey + `.${key}`;
      if (checkObjectType(values[key])) {
        parseQ(values[key], resKey);
      } else {
        resultData[resKey] = values[key];
      }
    }
  };

  parseQ(values, startKey);
  return resultData;
};

export const filterValues = (data = [], filters: any) => {
  const flatFilters = parseNestedData(filters);
  const filteredData = data.filter((item: any) => {
    const flatDataItem = parseNestedData(item);
    let res = true;
    for (let key in flatFilters) {
      if (
        !!flatDataItem[key] &&
        checkStringType(flatDataItem[key]) &&
        checkStringType(flatFilters[key]) &&
        !flatDataItem[key].includes(flatFilters[key])
      ) {
        res = false;
      }
    }
    return res;
  });
  return filteredData;
};

export const blockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.add("blocked-scroll");
  } else {
    document.body.className += " blocked-scroll";
  }
};

export const unblockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.remove("blocked-scroll");
  } else {
    document.body.className = document.body.className.replace(
      new RegExp(
        "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
        "gi"
      ),
      " "
    );
  }
};

export const getToken = () => localStorage.getItem("token");
export const removeToken = () => localStorage.removeItem("token");
export const isLoggedIn = () => getToken();

export const queryUrl = (params: any) => {
  var searchParams = new URLSearchParams();
  for (let key in params) {
    //!!params[key] &&
    searchParams.append(key, params[key]);
  }

  return searchParams;
};

export const checkSelectedItems = ({
  selected,
  allData,
  selector,
}: CheckSelectedItemsArgs): any => {
  let result = [];
  if (!!selected && Array.isArray(selected) && selected.includes("*")) {
    result = allData.map((item) => item[selector]);
  } else {
    result = selected;
  }

  return result;
};

export const isJsonValid = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const objectOptionsMap = (
  data: { [key: string]: string | number },
  appendValueToLabel = false
) => {
  const values = Object.values(data || {});
  const keys = Object.keys(data || {});
  return values.map((item, idx) => ({
    label: appendValueToLabel ? item + " [" + keys[idx] + "]" : item,
    value: keys[idx],
  }));
};

export const arrayMap = (data: any[], { key, value }: any = {}) => {
  let result = [];
  for (var i in data) {
    result[data[i][key]] = data[i][value];
  }

  return result;
};

export const arrayOptionsMap = (
  data: any[],
  { labelKey, valueKey }: any = {}
) => {
  return data?.map((item, idx) => ({
    label: typeof item !== "object" ? item : dotProp.get(item, labelKey),
    value: typeof item !== "object" ? item : dotProp.get(item, valueKey),
  }));
};

export const parseFormData = (values: any) => {
  const resultData = new FormData();
  let startKey = "";

  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = parsDataKey === "" ? key : parsDataKey + `[${key}]`;
      console.log(
        "erdsrefdeds",
        key,
        values[key],
        Object.prototype.toString.call(values[key])
      );
      if (
        typeof values[key] === "object" &&
        !Array.isArray(values[key]) &&
        Object.prototype.toString.call(values[key]) !== "[object File]"
      ) {
        parseQ(values[key], resKey);
      } else if (typeof values[key] === "boolean") {
        if (!!values[key]) {
          resultData.append(resKey, "1");
        } else {
          resultData.append(resKey, "0");
        }
      } else if (Array.isArray(values[key])) {
        values[key].forEach((item: any, idx: any) => {
          console.log("efdad", key, item, Object.prototype.toString.call(item));
          if (
            typeof item === "object" &&
            Object.prototype.toString.call(item) !== "[object File]" &&
            !Array.isArray(item)
          ) {
            parseQ(item, resKey + `[${idx}]` + parsDataKey);
          } else {
            resultData.append(resKey + `[${idx}]`, item);
          }
        });
      } else {
        resultData.append(resKey, values[key]);
      }
    }
  };

  parseQ(values, startKey);

  return resultData;
};

export const cleanEmptyValFromObj = (obj) => {
  const cleanObj = { ...obj };

  const clear = (value) => {
    for (let key in value) {
      if (value[key] === "" || value[key] === undefined) {
        delete value[key];
      } else if (typeof value[key] === "object" && !Array.isArray(value[key])) {
        clear(value[key]);
      } else if (Array.isArray(value[key])) {
        value[key].forEach((item, idx) => {
          if (typeof item === "object" && !Array.isArray(item)) {
            clear(value[key]);
          } else if (value[key] === "" || value[key] === undefined) {
            delete value[key];
          }
        });
      }
    }
  };
  clear(cleanObj);

  return cleanObj;
};

export const toFixedStrict = (num, fixed = 2) =>
  parseFloat(`${num}`).toFixed(2);

const isTrueAmount = (amount: number | string): boolean =>
  amount > 0 || (typeof amount === "string" && amount.length > 0);

export const coinsToAmount = (amount) =>
  isTrueAmount(amount) ? +amount / 100 : undefined;
export const amountToCoins = (amount) =>
  isTrueAmount(amount) ? +amount * 100 : undefined;
