import createStore from "fragmented-store";
import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_TYPE_DARK,
} from "constants/ThemeSetting";
import { localeData } from "utils/helpers/constants";

export const {
  Provider: FragmentedStoreProvider,
  useEnterPage,
  useCurrentAdmin,
  useStore,
  useThemeColor,
  useThemeType,
  useTableFilter,
  useToggleCollapsedSideNav,
  useUpdateWindowWidth,
  useIsDirectionRTL,
  useNavStyle,
  useLayoutType,
  useLocale,
  useNavCollapsed,
  useWidth,
} = createStore({
  enterPage: window.location.pathname,
  currentAdmin: null,
  tableFilter: {},
  //from redux
  themeColor: "",
  navStyle: localStorage.getItem("navStyle") || NAV_STYLE_FIXED,
  layoutType: localStorage.getItem("layoutType") || LAYOUT_TYPE_FULL,
  themeType: localStorage.getItem("themeType") || THEME_TYPE_DARK,
  isDirectionRTL: false,
  locale: localeData[localStorage.getItem("locale")] || localeData["en"],
  navCollapsed: true,
  width: window.innerWidth,
});
