import React from "react";
import {
  useApiRoleIndex,
  useApiRoleResourceIndex,
  useApiRoleShow,
  useApiRoleStore,
  useApiRoleUpdate,
  useApiRoleDestroy,
} from "server/apiGeneratorHooks";
import {
  useClientTableFilter,
  useCommonComponentCrud,
  useRolesOptions,
  useTableQuery,
} from "utils/helpers/hooks";
import {useGlobalTranslate} from "utils/translation/hooks";
import {
  CommonStructureDestroy, CommonStructureIndex, CommonStructureUpset,
} from "components/atoms/common";
import {useIsAdminAllow} from "services/rolesRestrictions/hooks";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import StructureTable from "components/atoms/structure/StructureTable";
import StructureForm from "components/atoms/structure/StructureForm";


/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({onShow, onEdit, onDelete}) => {
  const {_t} = useGlobalTranslate();

  const structureTableFilter = new StructureTableFilter({_t: _t});
  const structureTable = new StructureTable({_t: _t});

  structureTableFilter
    .name()
    .created_at()
    .updated_at()
    .btns_submit_reset()
  ;

  structureTable
    .id()
    .name()
    .created_at()
    .updated_at()
    .action({onShow, onEdit, onDelete})
  ;

  return {structureTableFilter, structureTable};
}

/**
 *
 * @param item
 */
const useForm = ({item = null}) => {
  const {_t} = useGlobalTranslate();
  const structureForm = new StructureForm({_t: _t});

  const roleResourceIndex = useApiRoleResourceIndex({staleTime: 60 * 5});
  const roleResourcePack = useRolesOptions(roleResourceIndex?.data);

  structureForm
    .name()
    .role_resources(roleResourcePack)
    .btns_submit_reset()
  ;

  return structureForm;
}

/**
 *
 * @param drawer
 * @constructor
 */
const Store = ({drawer}) => {
  const structureForm = useForm({});
  const apiStore = useApiRoleStore();

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiStore}
      structureForm={structureForm}
      data={{}}
      loading={apiStore.isLoading}
    />
  );
};

/**
 *
 * @param drawer
 * @param item
 * @constructor
 */
const Update = ({drawer, item}) => {
  const structureForm = useForm({item});
  const apiUpdate = useApiRoleUpdate(item.id);
  const apiShow = useApiRoleShow(item.id, {enabled: apiUpdate.isIdle});

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiUpdate}
      structureForm={structureForm}
      data={apiShow.data}
      loading={apiShow.isFetching || apiUpdate.isLoading}
    />
  );
};

/**
 *
 * @param modal
 * @param item
 * @constructor
 */
const Destroy = ({modal, item}) => {
  const apiDestroy = useApiRoleDestroy(item.id);

  return (
    <CommonStructureDestroy
      modal={modal}
      mutate={apiDestroy}
      structure={{}}
      data={item}
      loading={apiDestroy.isLoading}
    />
  );
};

/**
 *
 * @constructor
 */
const SystemRoles = () => {
  const {_t} = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = 'role:role';

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    componentStore: isAllow(prefix + '@store') && Store,
    componentUpdate: isAllow(prefix + '@update') && Update,
    componentDestroy: isAllow(prefix + '@destroy') && Destroy,
    // componentUpload: isAllow(prefix + '@upload') && Upload,
    // componentDownload: isAllow(prefix + '@*') && {},
  });

  const apiIndex = useApiRoleIndex({query: tableQuery.query});
  const filteredData = useClientTableFilter(apiIndex.data, tableQuery.query);
  const {structureTableFilter, structureTable} = useTable(
    {onShow: null, onEdit: crud.onEdit, onDelete: crud.onDelete},
  );

  return (
    <CommonStructureIndex
      title={_t("menu.system.roles")}
      source={{data: filteredData || []}}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={crud.onUpload}
      onDownload={crud.onDownload}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default SystemRoles;
