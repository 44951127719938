import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "constants/ThemeSetting";
import {
  useNavStyle,
  useNavCollapsed,
  useWidth,
  useLocale,
} from "fragmentedStore";
import LocaleSwitchContainer from "containers/Topbar/LocaleSwitchContainer";
import LogoContainer from "containers/components/atoms/logo";

const { Header } = Layout;

const Topbar = () => {
  const [navStyle] = useNavStyle();
  const [navCollapsed, toggleCollapsedSideNav] = useNavCollapsed();
  const [width] = useWidth();

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              toggleCollapsedSideNav(!navCollapsed);
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <LogoContainer />
      </Link>
      <ul className="gx-header-notifications gx-ml-auto">
        <Auxiliary>
          <li className="gx-user-nav">
            <UserInfo />
          </li>
        </Auxiliary>
        <li className="gx-language">
          <LocaleSwitchContainer />
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
