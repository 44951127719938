import React from "react";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "constants/ThemeSetting";
import { useThemeType } from "fragmentedStore";
import { useMenuItems } from "containers/Sidebar/hooks";
import { renderMenu } from "containers/Sidebar/helpers";
import {useIsAdminAllow} from "../../services/rolesRestrictions/hooks";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [themeType] = useThemeType();
  const checkAllow = useIsAdminAllow();
  const location = useLocation();
  const menuItems = useMenuItems();
  const selectedKeys = location.pathname;
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {menuItems.map((item, index) => renderMenu(checkAllow, item))}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
