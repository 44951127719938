import React from "react";
import {Button, PageHeader} from "antd";

const ContainerHeader = ({title}) => {
  return (
    <div className="gx-page-heading" style={{marginBottom: 0}}>
      <h2 className="gx-page-title">{title}</h2>
    </div>
  )
};

export default ContainerHeader;

