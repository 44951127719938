import axios from "axios";
import backendUrl from "./apiUrl";


const axiosInstance = axios.create({
  baseURL: backendUrl,
  responseType: "json",
  withCredentials: true,
  headers: {},
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("x-admin-token");

  if (token) {
      config.headers["X-ADMIN-TOKEN"] = token;
  }

  return config;
});

// axiosInstance.interceptors.response.use((config) => {
//   const token = localStorage.getItem("x-admin-token");
//
//   if (token) {
//     config.headers["X-ADMIN-TOKEN"] = token;
//   }
//
//   return config;
// });

export default axiosInstance;
