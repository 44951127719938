import React from "react";
import { LocaleMenuItemProps } from "components/atoms/localeMenuItem/types";

const LocaleMenuItem: React.FC<LocaleMenuItemProps> = ({ icon, name }) => {
  return (
    <>
      <i className={`flag flag-24 gx-mr-2 flag-${icon}`} />
      <span className="gx-language-text">{name}</span>
    </>
  );
};

export default LocaleMenuItem;
