import React from "react";
import {useGlobalTranslate} from "utils/translation/hooks";
import {
  API_USER_SEARCH_URL,
  useApiUserSearch,
} from "server/apiGeneratorHooks";
import {
  CommonStructureIndex,
} from "components/atoms/common";
import {useCommonComponentCrud, useTableQuery} from "utils/helpers/hooks";
import StructureTable from "components/atoms/structure/StructureTable";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import {useIsAdminAllow} from "services/rolesRestrictions/hooks";

/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({onShow = null, onEdit = null, onDelete = null}) => {
  const {_t} = useGlobalTranslate();
  const structureTableFilter = new StructureTableFilter({_t: _t});
  const structureTable = new StructureTable({_t: _t});

  structureTableFilter
    .id()
    .email()
    // .password()
    .phone()
    .full_name()
    // .country()
    // .city()
    // .organization()
    // .appointment()
    // .created_at()
    // .updated_at()
    // .deleted_at()
    .btns_submit_reset()
  ;

  structureTable
    .id()
    .email()
    // .password()
    .phone()
    .full_name()
    .country()
    .city()
    .organization()
    // .appointment()
    // .settings()
    .created_at()
    .updated_at()
    // .deleted_at()
    .action({onShow, onEdit, onDelete})
  ;

  return {structureTableFilter, structureTable};
};

/**
 *
 * @constructor
 */
const CommonUserSearch = () => {
  const {_t} = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = "user:user_search";

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    // componentStore: isAllow(prefix + '@store') && Store,
    // componentUpdate: isAllow(prefix + '@update') && Update,
    // componentDestroy: isAllow(prefix + '@destroy') && Destroy,
    // componentUpload: isAllow(prefix + '@upload') && Upload,
    componentDownload: isAllow(prefix + '@*') && {
      target: API_USER_SEARCH_URL(),
      query: tableQuery.query
    },
  });

  const apiIndex = useApiUserSearch({query: tableQuery.query,});
  const {structureTableFilter, structureTable} = useTable(
    {onShow: null, onEdit: null, onDelete: null}
  );

  return (
    <CommonStructureIndex
      title={_t("menu.user.search")}
      source={apiIndex}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={crud.onUpload}
      onDownload={crud.onDownload}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default CommonUserSearch;
