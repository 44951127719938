export const buttons = [
  "undo",
  "redo",
  "|",
  "paragraph",
  "|",
  "bold",
  "italic",
  "underline",
  "|",
  "align",
  "ul",
  "ol",
  "eraser",
  "|",
  "link",
  // "---",
  "|",
  "source",
];

export const defaultConfig = {
  statusbar: false,
  colorPickerDefaultTab: "color",
  /*  buttons: buttons,
  buttonsXS: buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,*/
  extraButtons: [],
  enter: "br",
  showTooltip: false,
  controls: {
    ul: {
      name: "ul",
      list: null,
    },

    ol: {
      name: "ol",
      list: null,
    },
  },

  events: {},
};

export const extendedConfig = { ...defaultConfig };
