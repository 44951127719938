import React from "react";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "constants/ThemeSetting";
import { useNavStyle, useThemeType, useWidth } from "fragmentedStore";
import Logo from "components/atoms/logo";
import LogoContainer from "containers/components/atoms/logo";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [themeType] = useThemeType();
  const [width] = useWidth();
  let [navStyle] = useNavStyle();
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED ||
      navStyle === NAV_STYLE_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_DRAWER ||
      width < TAB_SIZE ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}

      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <LogoContainer />
        ) : themeType === THEME_TYPE_LITE ? (
          <LogoContainer />
        ) : (
          <LogoContainer />
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
