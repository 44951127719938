import React, { useEffect, useMemo, useState } from "react";
import { getFormErrors } from "./helper";
import { parseNestedData } from "utils/helpers/helperFunctions";
import dotProp from "dot-prop";
import { Badge, Col, Form } from "antd";
import ColWrap from "services/customForm/ColWrap";
import isEqual from "lodash.isequal";

export const useGetFormErrors = (tabs: any = []) => {
  const [formErrors, setErrors] = useState<any>([]);
  const setFormError = (error) => {
    const val = isEqual(formErrors, error) ? formErrors : error;
    setErrors(val);
  };
  const errors = useMemo(
    () => getFormErrors(formErrors, tabs),
    [formErrors, tabs]
  );
  console.log("errorserrors", errors, tabs);
  return {
    tabError: errors.tabHasError,
    hasErrors: errors.hasErrors,
    setErrors: setFormError,
    formErrors,
  };
};
export const useResErrors = (resErrors: any, form: any, setErrors: any) => {
  useEffect(() => {
    if (resErrors) {
      const formErrors: any = [];
      Object.entries(resErrors).forEach((key, index) => {
        formErrors.push({ name: [key[0]], errors: key[1] });
      });
      setErrors(formErrors);
      form && form.setFields(formErrors);
    }
  }, [resErrors]);
};

const checkTextTrans = (item, params) => {
  return typeof item === "function" ? item(params) : item ? item : null;
};

export const useGenerateSchema = (
  schemaConfig: any = [],
  params: any,
  dataInitial?: any
) => {
  let schema = [];
  const modifyItemData = (item, params) => {
    const translateRules = item?.rules?.map((rule) => {
      return { ...rule, message: checkTextTrans(rule.message, params) };
    });
    const additional: any = {
      title: item?.title ? checkTextTrans(item?.title, params) : null,
      placeholder: item?.placeholder
        ? checkTextTrans(item?.placeholder, params)
        : null,
      label: item?.label ? checkTextTrans(item?.label, params) : null,
      dataItemParams: params,
      props: {
        ...item?.props,
        label: item?.props?.label
          ? checkTextTrans(item?.props?.label, params)
          : null,
      },
    };
    if (item?.rules) {
      additional["rules"] = translateRules;
    } else {
      additional["rules"] = [{ required: false }];
    }

    return additional;
  };
  useMemo(() => {
    schemaConfig?.forEach((item) => {
      if (!item?.name?.includes("{")) {
        const additional = modifyItemData(item, {});
        schema.push({ ...item, ...additional });
      } else {
        let dataList = [item];
        const paramsKeys = Object.keys(params);
        paramsKeys.forEach((paramKey) => {
          dataList.forEach((data, idx) => {
            const paramValues = Object.values(params[paramKey]);
            paramValues.forEach((paramValue) => {
              let clonedData = {
                ...data,
                params: { ...data.params, [paramKey]: paramValue },
              };
              let newName = clonedData.name.replace(
                `{${paramKey}}`,
                `${paramValue}`
              );
              const additional = modifyItemData(item, clonedData.params);
              let isExist = false;
              dataList.forEach((data) => {
                if (data.name == newName) {
                  isExist = true;
                }
              });
              if (!isExist) {
                dataList = [...dataList];
                dataList.splice(idx, 0, {
                  ...clonedData,
                  name: newName,
                  ...additional,
                });
              }
            });
          });
        });
        const filteredDataList = dataList.filter(
          (item: any) => !item.name.includes("{")
        );
        schema = [...schema, ...filteredDataList];
      }
    });
  }, [schemaConfig, params]);

  let flatDataInitial: any = parseNestedData(dataInitial);
  const modifiedSchema = schema.map((item: any) => {
    let returnData = { ...item };
    if (flatDataInitial.hasOwnProperty(item.name)) {
      returnData["initialValue"] = flatDataInitial[item.name];
    } else if (dataInitial && dataInitial.hasOwnProperty(item.name)) {
      returnData["initialValue"] = dataInitial[item.name];
    }
    return returnData;
  });

  return modifiedSchema;
};

export const useGenerateColumns = (schemaConfig: any, params: any) => {
  let schema = [];
  const modifyItemData = (item, params) => {
    const additional: any = {
      title: item?.title ? checkTextTrans(item?.title, params) : null,
      dataItemParams: params,
    };

    return additional;
  };
  schemaConfig.forEach((item) => {
    if (!item?.dataIndex?.includes("{")) {
      const additional = modifyItemData(item, {});
      schema.push({ ...item, ...additional });
    } else {
      let dataList = [item];
      const paramsKeys = Object.keys(params);
      paramsKeys.forEach((paramKey) => {
        dataList.forEach((data, idx) => {
          const paramValues = Object.values(params[paramKey]);
          paramValues.forEach((paramValue) => {
            let clonedData = {
              ...data,
              params: { ...data.params, [paramKey]: paramValue },
            };
            let newName = clonedData.dataIndex.replace(
              `{${paramKey}}`,
              `${paramValue}`
            );
            const additional = modifyItemData(item, clonedData.params);
            let isExist = false;
            dataList.forEach((data) => {
              if (data.dataIndex == newName) {
                isExist = true;
              }
            });
            if (!isExist) {
              dataList = [...dataList];
              dataList.splice(idx, 0, {
                ...clonedData,
                dataIndex: newName,
                ...additional,
              });
            }
          });
        });
      });
      const filteredDataList = dataList.filter(
        (item: any) => !item.dataIndex.includes("{")
      );
      schema = [...schema, ...filteredDataList];
    }
  });
  const modifiedSchema = schema.map((item: any) => {
    const data = {
      ...item,
    };
    if (item.dataIndex) {
      data["dataIndex"] = item.dataIndex.split(".");
    }
    return data;
  });

  return modifiedSchema;
};

/*  for (let key in params) {
  if (Array.isArray(params[key])) {
    schema.forEach((item: any, qqIdx) => {
      const itemName = `${item?.name}`;
      if (itemName?.includes(key)) {
        params[key].forEach((par: any, idx1: number) => {
          /!* keyParams = { ...params };*!/
          /!*keyParams = { ...keyParams, [key]: params[key][idx1] };*!/
          console.log("keyParams", keyParams);
          /!*const paramsq = originItemName.split(":");*!/
          const newItemName = itemName.replace(`:${key}`, `.${par}`);
          const translateRules = item?.rules?.map((rule) => {
            return { ...rule, message: _t(rule.message, keyParams) };
          });
          const additional: any = {
            title: item?.title ? _t(item?.title, keyParams) : null,
            name: newItemName,
            placeholder: item?.placeholder
              ? _t(item?.placeholder, keyParams)
              : null,
            label: item?.name,
          };
          if (item?.rules) {
            additional["rules"] = translateRules;
          }
          schema = [...schema];
          schema.splice(qqIdx, 0, { ...item, ...additional });
        });
      }
      /!*else {
        const additional: any = {
          title: item?.title ? _t(item?.title) : null,
          placeholder: item?.placeholder ? _t(item?.placeholder) : null,
          label: item?.label ? _t(item?.label) : null,
        };
        const translateRules = item?.rules?.map((rule) => {
          return { ...rule, message: _t(rule.message, keyParams) };
        });
        if (item?.rules) {
          additional["rules"] = translateRules;
        }
        schema = [...schema];
        schema.splice(qqIdx, 1, { ...item, ...additional });
      }*!/
    });
  }
}*/

const DefaultComp = (props) => <>{props.children}</>;

export const usePrepareForm = (structure, data, options) => {
  const { initialValues, map, node, tabs } = useMemo(() => {
    const node = structure?.getNodes() || [];
    const map = {};
    const initialValues = {};
    const tabs = [];
    if (!structure) {
      return { initialValues, map, node, tabs };
    }
    const recursive = (node) => {
      node.map((item) => {
        if (item.node) {
          recursive(item.node);
        }
        if (!item.component) {
          item.component = DefaultComp;
        }
        if (item.type === "tab") {
          tabs.push(item.props.tab);
        }
        if (item.type === "reset") {
          item.props.onClick = options.onReset;
        }
        if (item.path) {
          item.props.name = item.path; // add name from path (update structure node)
          let value = item.value ? item.value : undefined; // get default
          value = dotProp.get(data, item.path, value); // get from data
          value = item.decode ? item.decode(value) : value; // try decode value
          initialValues[item.path] = value;
          // dotProp.set(initialValues, item.path, value);
          map[item.path] = item;
        }
      });
    };

    recursive(node);

    return { initialValues, map, node, tabs };
  }, [structure, data]);

  return { initialValues, map, node, tabs };
};

export const useRenderFormItems = (formProps, tabError) => {
  const render = (item: any) => {
    const { component: Component, ...props } = item || {
      component: DefaultComp,
    };
    const renderComponent = (item, Component, props) => {
      return (
        <Form.Item {...props.props}>
          <Component {...props.props} />
        </Form.Item>
      );
    };

    const renderGroup = (item, Component, props) => {
      const modifiedProps = { ...props.props };
      if (item.type === "tab") {
        modifiedProps["tab"] = (
          <>
            {item.props.tab.toUpperCase()}
            {tabError?.[item.props.tab] && <Badge dot={true} />}
          </>
        );
      }
      if (item.type === "group") {
        return (
          <ColWrap isCol={true} span={24}>
            <Component {...modifiedProps}>
              {item.node.map((node) => {
                return render(node);
              })}
            </Component>
          </ColWrap>
        );
      }
      return (
        <Component {...modifiedProps}>
          {item.node.map((node) => {
            return render(node);
          })}
        </Component>
      );
    };

    if (item.node) {
      if (item?.options?.wrapStyle || item?.options?.colParams) {
        return (
          <ColWrap
            isCol={item?.options?.colParams}
            span={24}
            {...formProps.fieldCol}
            {...item?.options?.colParams}
            style={item?.options?.wrapStyle}
          >
            {renderGroup(item, Component, props)}
          </ColWrap>
        );
      }
      return renderGroup(item, Component, props);
    }

    return (
      <ColWrap
        isCol={
          !item.props.noStyle && (item.props.colParams || formProps.fieldCol)
        }
        span={24}
        {...formProps.fieldCol}
        {...item.props.colParams}
      >
        {renderComponent(item, Component, props)}
      </ColWrap>
    );
  };

  return render;
};
