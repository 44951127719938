import { useGlobalTranslate } from "utils/translation/hooks";
import { Button, Space } from "antd";
import {
  AccountBookOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React from "react";

const style = { margin: 0, height: "20px", width: "20px" };

const TableActionButtons = ({
  onShow = null,
  onEdit = null,
  onDelete = null,
}) => {
  const { _t } = useGlobalTranslate();
  return (
    <Space>
      {onShow && (
        <Button
          title={_t("show")}
          size={"small"}
          style={style}
          onClick={onShow}
          icon={<AccountBookOutlined />}
        />
      )}
      {onEdit && (
        <Button
          title={_t("edit")}
          size={"small"}
          style={style}
          onClick={onEdit}
          icon={<EditOutlined />}
        />
      )}
      {onDelete && (
        <Button
          title={_t("delete")}
          size={"small"}
          style={style}
          onClick={onDelete}
          icon={<DeleteOutlined />}
          danger
        />
      )}
    </Space>
  );
};

export default TableActionButtons;
