import { useContext } from "react";
import DrawerContext from "./DrawerContext";

export const useDrawer = () => {
  const defaultDrawer = "default";
  const drawer: any = useContext(DrawerContext);
  const showDrawer = (params: {}, name = defaultDrawer) =>
    drawer.showDrawer(params, name);
  const updateDrawer = (params: {}, name = defaultDrawer) =>
    drawer.updateDrawer(params);
  const hideDrawer = (name = defaultDrawer) => drawer.hideDrawer(name);

  return { showDrawer, hideDrawer, updateDrawer };
};
