export type NodeType = {
  component?: any;
  path?: string;
  field?: string;
  extra?: any;
  type?: string;
  node?: string;
  render?: any;
  strategy?: any;
  size?: any;
  title?: string;
  sort?: boolean;
  align?: string;
  sorter?: boolean;
  value?: any;
  props?: {
    label?: string;
    placeholder?: string;
    rules?: any[];
    [key: string]: any;
  };
  options?: {
    [key: string]: any;
  };
  encode?: (val: any) => any;
  decode?: (val: any) => any;
};

export const strf = (str : string, replace = {}) => {
  let result = str;
  for(var key in replace){
    result = result.split("{" + key + "}").join(replace[key]);
  }
  return result;
};


export default class Structure {

  node = [];
  xx_options = { _t: undefined };

  constructor(xx_options = { _t: undefined }) {
    this.xx_options = xx_options;
  }

  _t(key, replace = {}) {
    return this.xx_options._t(key, replace);
  }

  render() {
    return this.generate(this.getNodes());
  }

  getNodes() {
    return this.node || [];
  }

  generate(node = []) {
    console.log("-----------", node);

    if (node?.length === 0) {
      return null;
    }

    const result = node.map((item) => {
      const { component: Component, ...props } = item;

      if (item.node) {
        return <Component {...props}>{this.generate(item.node)}</Component>;
      }

      return <Component {...props} />;
    });

    return <>{result}</>;
  }

  add(obj: any) {
    this.node.push(obj);
    return this;
  }

  group(f, options: any = {}) {
    const sub = this.clone();
    f(sub, options);

    return this.add({
      type: "group",
      options: options,
      node: sub.getNodes(),
    });
  }

  each(array, f, options: any = {}) {
    array.map((value: any, idx: number) => f(this, value, options, idx));

    return this;
  }

  // system ==============

  refresh() {
    this.node = [];

    return this;
  }

  clone() {
    // README https://gist.github.com/GeorgeGkas/36f7a7f9a9641c2115a11d58233ebed2
    const clone = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      JSON.parse(JSON.stringify(this))
    );

    clone.xx_options = this.xx_options;
    clone.refresh();

    return clone;
  }
}
