import React from "react";
import Checkbox from "components/atoms/checkbox";
import { Col, Collapse, Row } from "antd";

const { Panel } = Collapse;

const RoleCheckboxes = ({ value, onChange, options, ...rest }) => {
  const onChangeCheck = (name, checked) => {
    const roleVal = !!value ? value : [];
    if (!!checked) {
      const resultValue = [...roleVal, name];
      onChange(resultValue);
    } else {
      const index = roleVal.indexOf(name);
      if (index !== -1) {
        roleVal.splice(index, 1);
        onChange(roleVal);
      }
    }
  };

  return (
    <Collapse>
      {Object.keys(options).map((optionKey) => {
        return (
          <Panel header={optionKey} key={optionKey}>
            <Row gutter={[4, 4]} style={{ flexDirection: "row" }}>
              {options[optionKey].map((option) => {
                return (
                  <Col md={12}>
                    <Checkbox
                      onChange={(val) => onChangeCheck(option.value, val)}
                      value={value?.includes(option.value)}
                      label={option.label}
                    />
                  </Col>
                );
              })}
            </Row>
          </Panel>
        );
      })}
    </Collapse>
  );
};
export default RoleCheckboxes;
