import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import { CheckboxProps } from "./types";

const Checkbox: React.FC<CheckboxProps> = ({ label, value, onChange }) => {
  return (
    <AntCheckbox
      defaultChecked={value}
      onChange={(e) => onChange(e.target.checked)}
      value={value}
    >
      {label}
    </AntCheckbox>
  );
};

export default Checkbox;
