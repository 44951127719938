import { UseTransResult } from "utils/translation/types";
import { useContext } from "react";
import { TransContext } from "utils/translation/TransProvider";
import {strf} from "../../components/atoms/structure/Structure";


/**
 *
 */
export const useGlobalTranslate = (): UseTransResult => {
  const { dictionary = {}, languages = [] } = useContext(TransContext);

  const _t = (key: string, params: any = {}): string => {
    return strf(dictionary[key] || key, params);
  };

  return { _t, languages };
};
