import React from "react";
import {
  useApiIssueDestroy,
  useApiIssueShow,
  useApiIssueUpdate,
  useApiIssueStore,
  useApiIssueIndex,
  useApiRoundIndex,
  useApiBootstrapIndex,
} from "server/apiGeneratorHooks";
import { useCommonComponentCrud, useTableQuery } from "utils/helpers/hooks";
import { useGlobalTranslate } from "utils/translation/hooks";
import { deviceTypes } from "utils/helpers/constants";
import StructureForm from "components/atoms/structure/StructureForm";
import StructureTableFilter from "components/atoms/structure/StructureTableFilter";
import StructureTable from "components/atoms/structure/StructureTable";
import {
  CommonStructureDestroy,
  CommonStructureIndex,
  CommonStructureUpset,
} from "components/atoms/common";
import { useIsAdminAllow } from "services/rolesRestrictions/hooks";

/**
 *
 * @param onShow
 * @param onEdit
 * @param onDelete
 */
const useTable = ({ onShow, onEdit, onDelete }) => {
  const { _t } = useGlobalTranslate();
  const roundIndex = useApiRoundIndex();
  const structureTableFilter = new StructureTableFilter({ _t: _t });
  const structureTable = new StructureTable({ _t: _t });

  structureTableFilter
    .id()
    .slug()
    .round_id(roundIndex)
    // .round_id()
    .__input_like("title", { label: _t("title") })
    // .text()
    .__input_like("answer_title", { label: _t("answer_title") })
    // .answer_text()
    // .time()
    // .position()
    // .level()
    .active()
    // .created_at()
    // .updated_at()
    .btns_submit_reset();

  structureTable
    .id()
    .slug()
    .round_id(roundIndex)
    .x_custom("title", _t("title"), false)
    // .text()
    // .images()
    // .videos()
    .x_custom("answer_title", _t("answer_title"), false)
    // .answer_text()
    // .answer_images()
    // .answer_videos()
    .x_custom("time", _t("time"), false)
    .position()
    .x_custom("level", _t("level"), false)
    .flags()
    .active()
    .created_at()
    .updated_at()
    .action({ onShow, onEdit, onDelete });

  return { structureTableFilter, structureTable };
};

/**
 *
 * @param item
 */
const useForm = ({ item = null, cdn = "" }) => {
  const { _t } = useGlobalTranslate();
  const roundIndex = useApiRoundIndex();
  const structureForm = new StructureForm({ _t: _t });
  structureForm
    // .id()
    .slug()
    // .round_id()
    .round_id(roundIndex)
    .__input("title", { label: _t("title") })
    .__input_editor("text", { label: _t("text") })

    .image({}, { field: "images", props: { multiple: true, cdn: cdn } }) // TODO
    .image({}, { field: "videos", props: { multiple: true, cdn: cdn } }) // TODO

    .__input("answer_title", { label: _t("answer_title") })
    .__input_editor("answer_text", { label: _t("answer_text") })

    .image({}, { field: "answer_images", props: { multiple: true, cdn: cdn } }) // TODO
    .image({}, { field: "answer_videos", props: { multiple: true, cdn: cdn } }) // TODO

    .time()
    .position()
    .__input_number("level", { label: _t("level") })
    .flags()
    .active()
    .btns_submit_reset();

  return structureForm;
};

/**
 *
 * @param drawer
 * @constructor
 */
const Store = ({ drawer }) => {
  const structureForm = useForm({});
  const apiStore = useApiIssueStore();

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiStore}
      structureForm={structureForm}
      data={{}}
      loading={apiStore.isLoading}
    />
  );
};

/**
 *
 * @param drawer
 * @param item
 * @constructor
 */
const Update = ({ drawer, item }) => {
  const bootstrapIndex = useApiBootstrapIndex();
  const structureForm = useForm({ item, cdn: bootstrapIndex?.data?.api?.cdn });
  const apiUpdate = useApiIssueUpdate(item.id);
  const apiShow = useApiIssueShow(item.id, { enabled: apiUpdate.isIdle });

  return (
    <CommonStructureUpset
      drawer={drawer}
      mutate={apiUpdate}
      structureForm={structureForm}
      data={apiShow.data}
      loading={apiShow.isFetching || apiUpdate.isLoading}
    />
  );
};

/**
 *
 * @param modal
 * @param item
 * @constructor
 */
const Destroy = ({ modal, item }) => {
  const apiDestroy = useApiIssueDestroy(item.id);

  return (
    <CommonStructureDestroy
      modal={modal}
      mutate={apiDestroy}
      structure={{}}
      data={item}
      loading={apiDestroy.isLoading}
    />
  );
};

/**
 *
 * @constructor
 */
const CommonNotaIssue = () => {
  const { _t } = useGlobalTranslate();
  const tableQuery = useTableQuery({});
  const isAllow = useIsAdminAllow();
  const prefix = "nota:issue";

  const crud = useCommonComponentCrud({
    props: {},
    // componentShow: isAllow(prefix + '@show') && Show,
    componentStore: isAllow(prefix + "@store") && Store,
    componentUpdate: isAllow(prefix + "@update") && Update,
    componentDestroy: isAllow(prefix + "@destroy") && Destroy,
    // componentUpload: isAllow(prefix + '@upload') && Upload,
    // componentDownload: isAllow(prefix + '@download') && {},
  });

  const apiIndex = useApiIssueIndex({ query: tableQuery.query });
  const { structureTableFilter, structureTable } = useTable({
    onShow: null,
    onEdit: crud.onEdit,
    onDelete: crud.onDelete,
  });

  return (
    <CommonStructureIndex
      title={_t("menu.nota.issue")}
      source={apiIndex}
      tableQuery={tableQuery}
      onCreate={crud.onCreate}
      onUpload={crud.onUpload}
      onDownload={crud.onDownload}
      loading={apiIndex.isFetching}
      structureTable={structureTable}
      structureTableFilter={structureTableFilter}
    />
  );
};

export default CommonNotaIssue;
