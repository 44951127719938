import React, { useEffect } from "react";
import { Form } from "antd";
import {
  useGetFormErrors,
  usePrepareForm,
  useRenderFormItems,
  useResErrors,
} from "services/customForm/hooks";
import RowWrap from "services/customForm/RowWrap";
import { useGlobalTranslate } from "utils/translation/hooks";
import { cleanEmptyValFromObj } from "utils/helpers/helperFunctions";
import dotProp from "dot-prop";
import { usePrevious } from "utils/helpers/hooks";
import isEqual from "lodash.isequal";

export const CustomStructureForm = ({
  formName = "form",
  structureForm,
  onSubmit,
  error = null,
  formRef,
  submitOnReset = false,
  data = {},
  ...formProps
}: any) => {
  const { _t } = useGlobalTranslate();
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
    setErrors([]);
    if (submitOnReset) {
      form.submit();
    }
  };
  const { initialValues, map, node, tabs } = usePrepareForm(structureForm, data, {
    onReset: onReset,
  });
  const prevInitialData = usePrevious(data);
  const { setErrors, tabError, hasErrors } = useGetFormErrors(tabs);
  useEffect(() => {
    if (!isEqual(prevInitialData, data)) {
      form.setFieldsValue(initialValues);
    }
  }, [prevInitialData]);
  useResErrors(error, form, setErrors);
  const onFieldsChange = (values: any, errorFields: any) => {
    const errors = errorFields.map(({ name, errors }: any) => ({
      name,
      errors,
    }));
    setErrors(errors);
  };

  const onFinish = async (values: any) => {
    console.log("-----------------", values);
    let data = {};

    for (let key in map) {
      let item = map[key];
      let val = values[item.path];
      if (item.encode) {
        val = item.encode(data[item.path]);
        //dotProp.set(data, key, val);
      }
      dotProp.set(data, key, val);
    }
    data = cleanEmptyValFromObj(data);
    console.log("data", data);
    await onSubmit(data);
  };
  const renderFormItems = useRenderFormItems(formProps, tabError);

  return (
    <Form
      ref={formRef}
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ ...initialValues }}
      onFieldsChange={onFieldsChange}
      {...formProps}
    >
      <RowWrap isRow={true} gutter={[12, 8]}>
        {node?.map((item: any, idx: number) => {
          return renderFormItems(item);
        })}
      </RowWrap>

      {!!hasErrors && (
        <div
          role="alert"
          style={{ color: "#f5222d" }}
          className="ant-form-item-explain-error"
        >
          {_t("form_has_errors")}
        </div>
      )}
    </Form>
  );
};

export default CustomStructureForm;
