import { useCurrentAdmin } from "fragmentedStore/index";
import { checkStringType } from "utils/helpers/helperFunctions";

export const useIsAdminAllow = (): any => {
  const [currentAdmin] = useCurrentAdmin();
  const resources = currentAdmin?.role?.resources || [];
  const checkAllow = (isAllowResource) => {
    let isAllow = false;
    if (resources.length === 1 && resources[0] === "*") {
      isAllow = true;
      return isAllow;
    }
    const patternToRegex = (pattern) => "^" + pattern.split("*").join("(.+)");
    const checkRegexMatch = (pattern, testItem) =>
      new RegExp(patternToRegex(pattern)).test(testItem);
    resources.forEach((item) => {
      let isRegexMatch = false;
      if (checkStringType(isAllowResource)) {
        isRegexMatch = checkRegexMatch(isAllowResource, item);
      } else if (Array.isArray(isAllowResource)) {
        isAllowResource.forEach((resource) => {
          const intermediaryRegexMatch = checkRegexMatch(resource, item);
          if (intermediaryRegexMatch) {
            isRegexMatch = intermediaryRegexMatch;
          }
        });
      }
      if (isRegexMatch) {
        isAllow = true;
      }
    });

    return isAllow;
  };

  return checkAllow;
};
