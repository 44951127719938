import { unpackObjFromStr } from "utils/helpers/helperFunctions";

export const getFormErrors = (errors = {}, tabs: string[]): any => {
  if (!errors) {
    return {};
  }
  let tabHasError: any = {};
  let hasErrors: any = false;
  const parsErrors = (errors: any, tab: any) => {
    errors.forEach((error: any) => {
      const errName = error.name.join("");
      if (
        error.errors &&
        !!error.errors.length &&
        errName.includes(`.${tab}`)
      ) {
        tabHasError[tab] = true;
      } else if (error.errors && !!error.errors.length) {
        hasErrors = true;
      }
    });
  };
  tabs?.forEach((tab) => parsErrors(errors, tab));
  if (!tabs.length) {
    parsErrors(errors, null);
  }

  return { tabHasError, hasErrors };
};

export const divideOnTabs = (schema: any, tabs: string[]): any[] => {
  const modifiedSchema = [...schema];
  tabs.forEach((tabName: any) => {
    schema.forEach((item: any, idx: any) => {
      const keys = item.name.split(".");
      if (keys.includes(tabName)) {
        modifiedSchema[idx] = {
          ...item,
          tab: tabName,
        };
      }
    });
  });

  return modifiedSchema;
};

export const getFormInitialValues = (schema: any) => {
  const initialValues: any = {};
  schema.forEach((item: any) => {
    const initialVal = !!item?.initialValue ? item?.initialValue : undefined;
    initialValues[item?.name] = unpackObjFromStr(item?.name, ".", initialVal);
  });

  return initialValues;
};
/*
export const formSetResErrors = () => {
    setTimeout(() => {
        const qq: any = [
            {
                name: "firstName",
                errors: ["Please input your Password!"],
            },
        ];
        form.setFields(qq);
    }, 3000);
}
*/
