import React from "react";
import Logo from "components/atoms/logo";
import { useThemeType } from "fragmentedStore";

const LogoContainer = () => {
  const [themeType] = useThemeType();
  console.log("themeType564", themeType);
  return <Logo type={themeType} />;
};

export default LogoContainer;
