import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner: React.FC<{ spinning?: boolean }> = ({
  children,
  spinning = true,
}) => {
  return (
    <Spin spinning={spinning} indicator={antIcon}>
      {children}
    </Spin>
  );
};

export default Spinner;
