import React from "react";
import { Row } from "antd";

const RowWrap = ({ isRow, children, ...rest }: any) => {
  if (!isRow) {
    return children;
  }
  return (
    <Row {...rest} style={{ flexDirection: "row" }}>
      {children}
    </Row>
  );
};

export default RowWrap;
