import { useGlobalTranslate } from "utils/translation/hooks";
import { routesConfig } from "routes/config";
import { generatePath } from "react-router-dom";
import React, { useMemo } from "react";
import { BsCardList } from "react-icons/bs";
import {
  AiOutlineSafetyCertificate,
  BiGame, BiImageAlt, BiNews, BiUserCircle, FaLevelUpAlt, FaRegFilePdf, GiGamepad,
  GiMarshmallows, GiMoneyStack,
  GrSystem,
  HiOutlineCash, HiOutlineUsers,
  HiTranslate, RiAdminLine, RiAdvertisementLine, RiUserSearchLine,
  SiBrandfolder, SiPagekit, SiReadthedocs, SiRiotgames, SiRoundcube, SiRss, VscFeedback
} from "react-icons/all";
//https://react-icons.github.io/react-icons/icons

export const useMenuItems = (params) => {
  const { _t } = useGlobalTranslate();
  const urlParams = {};
  const items = useMemo(
    () => [
      {
        key: "menu.system",
        title: _t("menu.system"),
        icon: <GrSystem />,
        items: [
          {
            key: "menu.system.admin",
            title: _t("menu.system.admin"),
            icon: <RiAdminLine />,
            url: generatePath(routesConfig.system_admin.path, urlParams),
            isAllowResource: routesConfig.system_admin.isAllowResource,
          },
          {
            key: "menu.system.roles",
            title: _t("menu.system.roles"),
            icon: <GiMarshmallows />,
            url: generatePath(routesConfig.system_roles.path, urlParams),
            isAllowResource: routesConfig.system_roles.isAllowResource,
          },
          {
            key: "menu.system.translate_global",
            title: _t("menu.system.translate_global"),
            icon: <HiTranslate />,
            url: generatePath(routesConfig.system_translate_global.path, urlParams),
            isAllowResource: routesConfig.system_translate_global.isAllowResource,
          },
        ],
      },
      {
        key: "menu.translate",
        title: _t("menu.translate"),
        icon: <BsCardList />,
        items: [
          {
            key: "menu.translate",
            title: _t("menu.translate"),
            icon: <HiTranslate />,
            url: generatePath(routesConfig.translate.path, urlParams),
            isAllowResource: routesConfig.translate.isAllowResource,
          },
        ],
      },
      {
        key: "menu.user",
        title: _t("menu.user"),
        icon: <BsCardList />,
        items: [
          {
            key: "menu.user.search",
            title: _t("menu.user.search"),
            icon: <RiUserSearchLine />,
            url: generatePath(routesConfig.user_search.path, urlParams),
            isAllowResource: routesConfig.user_search.isAllowResource,
          },
        ],
      },
      {
        key: "menu.nota",
        title: _t("menu.nota"),
        icon: <BsCardList />,
        items: [
          {
            key: "menu.nota.issue",
            title: _t("menu.nota.issue"),
            icon: <SiPagekit />,
            url: generatePath(routesConfig.nota_issue.path, urlParams),
            isAllowResource: routesConfig.nota_issue.isAllowResource,
          },
          {
            key: "menu.nota.round",
            title: _t("menu.nota.round"),
            icon: <SiRoundcube />,
            url: generatePath(routesConfig.nota_round.path, urlParams),
            isAllowResource: routesConfig.nota_round.isAllowResource,
          },
          {
            key: "menu.nota.author",
            title: _t("menu.nota.author"),
            icon: <BiUserCircle />,
            url: generatePath(routesConfig.nota_author.path, urlParams),
            isAllowResource: routesConfig.nota_author.isAllowResource,
          },
          {
            key: "menu.nota.feedback",
            title: _t("menu.nota.feedback"),
            icon: <VscFeedback />,
            url: generatePath(routesConfig.nota_feedback.path, urlParams),
            isAllowResource: routesConfig.nota_feedback.isAllowResource,
          },
          {
            key: "menu.nota.gamefile",
            title: _t("menu.nota.gamefile"),
            icon: <AiOutlineSafetyCertificate />,
            url: generatePath(routesConfig.nota_gamefile.path, urlParams),
            isAllowResource: routesConfig.nota_gamefile.isAllowResource,
          },
          {
            key: "menu.nota.certificate",
            title: _t("menu.nota.certificate"),
            icon: <SiReadthedocs />,
            url: generatePath(routesConfig.nota_certificate.path, urlParams),
            isAllowResource: routesConfig.nota_certificate.isAllowResource,
          },
        ],
      },


      {
        key: "menu.content",
        title: _t("menu.content"),
        icon: <BsCardList />,
        items: [
          {
            key: "menu.content.page",
            title: _t("menu.content.page"),
            icon: <SiPagekit />,
            url: generatePath(routesConfig.content_page.path, urlParams),
            isAllowResource: routesConfig.content_page.isAllowResource,
          },
          {
            key: "menu.content.banner",
            title: _t("menu.content.banner"),
            icon: <RiAdvertisementLine />,
            url: generatePath(routesConfig.content_banner.path,urlParams),
            isAllowResource: routesConfig.content_banner.isAllowResource,
          },
          {
            key: "menu.content.image",
            title: _t("menu.content.image"),
            icon: <BiImageAlt />,
            url: generatePath(routesConfig.content_image.path, urlParams),
            isAllowResource: routesConfig.content_image.isAllowResource,
          },
          {
            key: "menu.content.news",
            title: _t("menu.content.news"),
            icon: <BiNews />,
            url: generatePath(routesConfig.content_news.path, urlParams),
            isAllowResource: routesConfig.content_news.isAllowResource,
          },
          {
            key: "menu.content.file",
            title: _t("menu.content.file"),
            icon: <FaRegFilePdf />,
            url: generatePath(routesConfig.content_file.path, urlParams),
            isAllowResource: routesConfig.content_file.isAllowResource,
          },
        ],
      },
    ],
    [urlParams]
  );

  return items;
};
